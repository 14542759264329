import {Link} from 'react-router-dom'

export const ListUiHeader = (props: any) => {
  const {isList} = props
  return (
    <div>
    <div className='card card-custom gutter-b bg-secondary rounded '>
      <div className='card-header flex-wrap border-1rounded'>
        <div className='card-title'>
          <span className='d-block text-info pt-2 font-size-sm'>{props.title}</span>
        </div>
        {isList && (
          <div className='card-toolbar rounded'>
            <Link to={props.link} className='btn btn-sm btn-primary font-weight-bolder'>
              <span className='svg-icon svg-icon-md'>
                <i className='fas fa-plus'></i>
              </span>
              Add
            </Link>
          </div>
        )}
      </div>
    </div>
    </div>
  )
}
export const AddUiHeader = (props: any) => {
  const {isList} = props
  return (
    <div className='card card-custom gutter-b bg-secondary rounded'>
      <div className='card-header flex-wrap border-0 pt-6 pb-0 rounded'>
        <div className='card-title'>
          <span className='d-block text-info pt-2 font-size-sm'>{props.title}</span>
        </div>
        {isList && (
          <div className='card-toolbar rounded'>
            <Link to={props.link} className='btn btn-primary font-weight-bolder'>
              <span className='svg-icon svg-icon-md'>
                {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24px'
                  height='24px'
                  viewBox='0 0 24 24'
                  version='1.1'
                >
                  <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                    <polygon points='0 0 24 0 24 24 0 24' />
                    <path
                      d='M4,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,7 C2,5.8954305 2.8954305,5 4,5 Z M4,7 L20,7 L20,19 L4,19 L4,7 Z'
                      fill='#000000'
                    />
                    <rect fill='#000000' opacity='0.3' x={11} y={13} width={2} height={8} rx={1} />
                    <rect fill='#000000' opacity='0.3' x={7} y={9} width={2} height={12} rx={1} />
                    <rect fill='#000000' opacity='0.3' x={15} y={17} width={2} height={4} rx={1} />
                  </g>
                </svg>
                {/*end::Svg Icon*/}
              </span>
              Add
            </Link>
            {/*end::Button*/}
          </div>
        )}
      </div>
    </div>
  )
}
