import {Link} from 'react-router-dom'
import {deleteCountry} from '../../../../services/CommonService'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../../services/NotifyService'

export function CountryCard(props: any) {
  // only Name and Currency
  const {countries, onRefresh} = props
  const toggleActiveStatus = (id: any) => {
    const tId = toast.loading('Please wait...')
    deleteCountry(id)
      .then((res) => {
        toastNotify(tId, res)
        if (res.data.status) {
          onRefresh()
        }
      })
      .catch(() => {
        const res = {
          data: {status: false, message: 'Something went wrong'},
        }
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }
  const randomColors = ['#CBF0F4']
  return (
    <div className='row pt-2'>
      {countries.map((country: any) => (
        <div className='col-lg-4 pt-2' key={country.id}>
          <div
            className='card card-custom gutter-b'
            style={{
              backgroundColor: 'gainsboro',
            }}
          >
            <div className='d-flex justify-content-between align-items-center px-3 py-2'>
              <h3 className='card-title m-0'>{country.name}</h3>
              <div className='btn-group'>
                <Link
                  to={`/places/country/${country.id}`}
                  className='btn btn-light btn-hover-primary btn-sm'
                >
                  <i className='fas fa-eye'></i>
                  {" "}View
                </Link>
                <button
                  className='btn btn-primary btn-sm'
                  onClick={() => toggleActiveStatus(country.id)}
                >
                  {country.isActive ? 'Deactivate' : 'Activate'}
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
