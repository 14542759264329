import { useState } from "react";
import { toast } from "react-toastify";
import { deleteEntity } from "../../../../services/CommonService";
import { toastNotify } from "../../../../services/NotifyService";
import { saveJourney, updateAction } from "../../../../services/TourServices/JourneyService";
import { KTSVG } from "../../../../_metronic/helpers";
export function TourRejectModel(props: any) {
    const { confirmModel, afterConfirm } = props;
    const onCancel = () => {
        afterConfirm(false);
    }
    const onOk = () => {
        console.log(confirmModel);
        const id = toast.loading("Please wait...");
        confirmModel.isAccept = false;
        confirmModel.notes = notes;
        updateAction(confirmModel)
            .then((res: any) => {
                if (res.data.status) {
                    toastNotify(id, res);
                    afterConfirm(true);
                }
                else {
                    toastNotify(id, res);
                }
            })
            .catch(() => {
                const res = { data: { message: 'Something went wrong', status: false } };
                toastNotify(id, res);
            }).finally(() => {
                toast.dismiss(id);
            });
    }
    const [notes, setNotes] = useState(confirmModel.notes || '');
    // set validation for notes
    const [notesError, setNotesError] = useState('');
    const onNotesChange = (e: any) => {
        if (e.target.value.length >= 10) {
            setNotesError('');
            setNotes(e.target.value);
        }
        else {
            setNotesError('Notes is required and must be at least 10 characters long');
            setNotes(e.target.value);
        }
    }
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_confiem_box'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-md'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>Reject Tour</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => afterConfirm(false)}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className="lh-lg">
                                <p>
                                    This tour will be rejected
                                    and will be sent back to the <b>{ }</b> for further action.
                                </p>
                            </div>
                            <div className="form-group">
                                <label htmlFor="notes">Notes</label>
                                <textarea
                                    className="form-control"
                                    id="notes"
                                    rows={3}
                                    value={notes}
                                    onChange={(e) => onNotesChange(e)}
                                ></textarea>
                                <span className="text-danger">{notesError}</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => onCancel()}
                                type="button"

                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={() => onOk()}
                                disabled={notes.length <= 10}
                                type="button" className="btn btn-danger">
                                Reject
                            </button>
                        </div>
                    </div>
                </div>

            </div>
            <div className='modal-backdrop fade show'></div>
        </>

    )
}