import axiosInstance from "./AxiosInstance";

export const postPlaceImage = (tourLocationId = 0, formData) => {
    return axiosInstance.post(`/fileUpload/UploadImage/${tourLocationId}`, formData, {
        headers: {
        "Content-Type": "multipart/form-data",
        },
    });
    }

    export const postTourImage = (tourId,coverImageId, formData) => {
        return axiosInstance.put(`/FileUpload/update-tourimage/${tourId}/${coverImageId}`, formData, {
            headers: {
            "Content-Type": "multipart/form-data",
            },
        });
        }