import React, {useEffect} from 'react'
import {addState, getAllCountries} from '../../../services/CommonService'
import {useFormik} from 'formik'
import {StateSchema} from './SettingsSchema'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'

export default function AddStates() {
  const [countries, setCountries] = React.useState([])
  const initialValues = {
    name: '',
    countryId: 0,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: StateSchema,
    onSubmit: (values) => saveState(values),
  })

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res.data.status) setCountries(res.data.data)
      else console.log(res.data.message)
    })
  }, [])
  const saveState = (values: any) => {
    const tId = toast.loading('Please wait...')
    addState(values)
      .then((res) => toastNotify(tId, res))
      .catch(() => {
        const res = {data: {status: false, message: 'Something went wrong'}}
        toastNotify(tId, res)
      })
      .finally(() => {
        formik.setSubmitting(false)
        toast.dismiss(tId)
      })
  }

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className='card border-0 cursor-pointer'>
          <div
            className='card-header'
            role='button'
            data-bs-toggle='collapse'
            data-bs-target='#kt_settings_addState_method'
          >
            <h3 className='card-title'>Add State</h3>
          </div>
          <div className='collapse-show' id='kt_settings_addState_method'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label>State Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter State Name'
                      name='name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className='text-danger'>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Country</label>
                    <select
                      className='form-control'
                      name='countryId'
                      value={formik.values.countryId}
                      onChange={formik.handleChange}
                    >
                      <option value='0'>Select Country</option>
                      {countries.map((country: any) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.countryId && formik.touched.countryId ? (
                      <div className='text-danger'>{formik.errors.countryId}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer'>
              <button type='submit' className='btn btn-sm btn-primary'>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
