import React, { useEffect, useRef } from 'react'

import { PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
  TablesWidget9,
} from '../../../_metronic/partials/widgets'

//import { OnboardingStepsPage } from './OnboardingSteps';
import { getCounters } from "../../../services/CommonService"
import { ShortCuts } from './partials/Shortcuts';
import { TourWidget } from '../../../_shared/components/ToursWidget';
import { getUsersCount } from '../../../services/DashBoardService';
import { DashCounterWidget } from '../../../_shared/components/DashCounterWidget';

const DashboardPage = (props: any) => (
  <>
    <ShortCuts />
    <div className='row gy-5 g-xl-10'>
      {/*begin::Col*/}
      <div className='row justify-content-between'>
        <DashCounterWidget
          className='col-lg-4 col-xxl-4 p-1'
          backGroundColor='#F7D9E3'
          chartHeight='100px'
          counters={props.counters}
          type=''
        />
      </div>
      {/*end::Col*/}

      {/*begin::Col*/}
      {/* <div className='col-xl-6'>
        <TourWidget
          className='card-xl-stretch mb-xl-10'
          backGroundColor='#CBF0F4'
          chartHeight='100px'
          counters={props.counters}
          type='users'
        />
      </div> */}
      {/*end::Col*/}

      {/*begin::Col*/}
      {/* <div className='col-xl-4'>
        <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' />
      </div> */}
      {/*end::Col*/}
    </div>
    {/*end::Row*/}
    {/* <MixedWidget15 className='card-xl-stretch mb-xl-10' backGroundColor='#CBD4F4' /> */}


    {/* <TablesWidget9 className='mb-5 mb-xl-10' memberCount={props.counters?.customerCount} /> */}

    <div style={{ display: 'none' }}>
      {/*begin::Row*/}
      <div className='row gy-5 g-xl-10'>
        {/*begin::Col*/}
        <div className='col-xxl-6'>
          {/* <ListsWidget5 className='card-xl-stretch mb-xl-10' /> */}
        </div>
        {/*end::Col*/}

        {/*begin::Col*/}
        <div className='col-xxl-6'>
          {/* <ListsWidget4 className='card-xl-stretch mb-5 mb-xl-10' items={5} /> */}
        </div>
        {/*end::Col*/}
      </div>
    </div>

  </>
)

const DashboardWrapper = () => {
  const [counters, setCounters] = React.useState<any>({});

  const bindCounters = () => {
    getUsersCount().then((res: any) => {
      const data = res.data.data;
      setCounters(data);
      console.log(data);
    }).catch((err: any) => {
      console.log(err.message);
    })
  }

  useEffect(() => {
    setTimeout(() => {
      bindCounters();
    }, 600)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    // <EnableSidebar>
    <>

      <PageTitle description='Welcome to Audio Tours' breadcrumbs={[]}>
      </PageTitle>
      <DashboardPage counters={counters} />
    </>
    // </EnableSidebar>
  )
}

export { DashboardWrapper }
