import React,{useState} from 'react';
import './Loader.css'


export default function MultiSelect(props: any) {

    // const [selected, setSelected] = React.useState<any>([]);
    // const handleChange = (e: any) => {
    //     var options = e.target.options;
    //     var value = [];
    //     for (var i = 0, l = options.length; i < l; i++) {
    //         if (options[i].selected) {
    //             value.push(options[i].value);
    //         }
    //     }
    // }

    const [selectedFlavors, setSelectedFlavors] = useState<any>([]);

    const handleSelect = function(selectedItems:any) {
        const flavors:any = [];
        for (let i=0; i<selectedItems.length; i++) {
            flavors.push(selectedItems[i].value);
        }
        setSelectedFlavors(flavors);
    }

    return (
        <form>
            <select multiple={true} value={selectedFlavors} onChange={(e)=> {handleSelect(e.target.selectedOptions)}}>
               {props.options.map((option:any) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </form>
    );

}

export const MultiCheckBox =(props:any)=>{
    const {options, name, label, ...rest} = props;  
    return (
        <select 
        className='form-select form-select-solid fw-bolder'
        data-kt-select2='true'
        data-placeholder='Select option'
        data-allow-clear='true'
        data-kt-user-table-filter='status'
        data-hide-search='true'
        
        multiple={true} value={props.value} onChange={props.handleChange}>
            {options.map((option:any) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))}
        </select>
    )
}

export const Loader =()=>{
    return (
        <div className="loader">
          <span></span>
        </div>
    )
}
export const convertAmountToWords = (amount: any) => {
    var words = new Array();
    words[0] = '';
    words[1] = 'One';
    words[2] = 'Two';
    words[3] = 'Three';
    words[4] = 'Four';
    words[5] = 'Five';
    words[6] = 'Six';
    words[7] = 'Seven';
    words[8] = 'Eight';
    words[9] = 'Nine';
    words[10] = 'Ten';
    words[11] = 'Eleven';
    words[12] = 'Twelve';
    words[13] = 'Thirteen';
    words[14] = 'Fourteen';
    words[15] = 'Fifteen';
    words[16] = 'Sixteen';
    words[17] = 'Seventeen';
    words[18] = 'Eighteen';
    words[19] = 'Nineteen';
    words[20] = 'Twenty';
    words[30] = 'Thirty';
    words[40] = 'Forty';
    words[50] = 'Fifty';
    words[60] = 'Sixty';
    words[70] = 'Seventy';
    words[80] = 'Eighty';
    words[90] = 'Ninety';
    amount = amount.toString();
    var atemp = amount.split(".");
    var number = atemp[0].split(",").join("");
    var n_length = number.length;
    var words_string = "";
    if (n_length <= 9) {
        var n_array:any = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        var received_n_array:any = new Array();
        for (var i = 0; i < n_length; i++) {
            received_n_array[i] = number.substr(i, 1);
        }
        for (var i = 9 - n_length, j = 0; i < 9; i++, j++) {
            n_array[i] = received_n_array[j];
        }
        for (var i = 0, j = 1; i < 9; i++, j++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                if (
                    n_array[i] == 1
                ) {
                    n_array[j] = 10 + parseInt(n_array[j]);
                    n_array[i] = 0;
                } else if (n_array[i] == 0) {
                    n_array[j] = parseInt(n_array[j]);
                } else {
                    n_array[j] = parseInt(n_array[j]) + 10;
                
                } 
            }
        }
        let value:any;
        for (var i = 0; i < 9; i++) {
            if (i == 0 || i == 2 || i == 4 || i == 7) {
                value = n_array[i] * 10;
            } else {
                value = n_array[i];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if ((i == 1 && value != 0) || (i == 0 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Crores ";
            }
            if ((i == 3 && value != 0) || (i == 2 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Lakhs ";
            }
            if ((i == 5 && value != 0) || (i == 4 && value != 0 && n_array[i + 1] == 0)) {
                words_string += "Thousand ";
            }
            if (i == 6 && value != 0 && (n_array[i + 1] != 0 && n_array[i + 2] != 0)) {
                words_string += "Hundred and ";
            } else if (i == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string;

}
export class N2WIndian {
    private static readonly zeroTo99: string[] = [];
    private static readonly place: string[] = 'Thousand|Lakh|Crore|Arab|Kharab|Nil'.split('|');
  
    static {
      const ones: string[] =
        '|One|Two|Three|Four|Five|Six|Seven|Eight|Nine|Ten|Eleven|Twelve|Thirteen|Fourteen|Fifteen|Sixteen|Seventeen|Eighteen|Nineteen'.split(
          '|'
        );
  
      const tens: string[] = '||Twenty|Thirty|Forty|Fifty|Sixty|Seventy|Eighty|Ninety'.split('|');
  
      for (let i = 0; i < 100; i++) {
        const t: number = Math.floor(i / 10);
        const o: number = i % 10;
        N2WIndian.zeroTo99.push(t < 2 ? ones[i] : tens[t] + (o ? ' ' + ones[o] : ''));
      }
    }
  
    public static convert(x: string): string {
      let n: number = x.length;
      x = n === 0 ? '00' : n === 1 || n % 2 === 0 ? '0' + x : x;
      n = x.length;
      let r = N2WIndian.zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
      if (n >= 1) {
        const v: string = N2WIndian.zeroTo99[x.charCodeAt((n -= 1)) - 48];
        if (v) r = v + ' Hundred' + (r ? ' ' + r : '');
      }
      for (let i = 0; n > 0; i++) {
        const v: string = N2WIndian.zeroTo99[x.charCodeAt((n -= 2)) * 10 + x.charCodeAt(n + 1) - 528];
        if (v) r = v + ' ' + N2WIndian.place[i] + (r ? ' ' + r : '');
      }
      return r || 'Zero';
    }
  }
  export const wordify = (num:any) => {
    const single = ["Zero", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const double = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    const formatTenth = (digit:any, prev:any) => {
       return 0 == digit ? "" : " " + (1 == digit ? double[prev] : tens[digit])
    };
    const formatOther = (digit:any, next:any, denom:any) => {
       return (0 != digit && 1 != next ? " " + single[digit] : "") + (0 != next || digit > 0 ? " " + denom : "")
    };
    let res = "";
    let index = 0;
    let digit = 0;
    let next = 0;
    let words = [];
    if (num += "", isNaN(parseInt(num))){
       res = "";
    }
    else if (parseInt(num) > 0 && num.length <= 10) {
       for (index = num.length - 1; index >= 0; index--) switch (digit = num[index] - 0, next = index > 0 ? num[index - 1] - 0 : 0, num.length - index - 1) {
          case 0:
             words.push(formatOther(digit, next, ""));
          break;
          case 1:
             words.push(formatTenth(digit, num[index + 1]));
             break;
          case 2:
             words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] && 0 != num[index + 2] ? " and" : "") : "");
             break;
          case 3:
             words.push(formatOther(digit, next, "Thousand"));
             break;
          case 4:
             words.push(formatTenth(digit, num[index + 1]));
             break;
          case 5:
             words.push(formatOther(digit, next, "Lakh"));
             break;
          case 6:
             words.push(formatTenth(digit, num[index + 1]));
             break;
          case 7:
             words.push(formatOther(digit, next, "Crore"));
             break;
          case 8:
             words.push(formatTenth(digit, num[index + 1]));
             break;
          case 9:
             words.push(0 != digit ? " " + single[digit] + " Hundred" + (0 != num[index + 1] || 0 != num[index + 2] ? " and" : " Crore") : "")
       };
       res = words.reverse().join("")
    } else res = "";
    return res
 };