import React, { useEffect, useState } from "react";
import { KTSVG, toAbsoluteUrl } from "../../../../_metronic/helpers";
import { Card3 } from "../../../../_metronic/partials/content/cards/Card3";
import {MySvg1} from  "../../../../_shared/components/mysvgs/mysvg1";
import { StatisticsWidget1, StatisticsWidget2, StatisticsWidget3, StatisticsWidget4, StatisticsWidget5, StatisticsWidget6 } from "../../../../_metronic/partials/widgets";
import { getCardsData } from "../../../../services/DashBoardService";
import { Link } from "react-router-dom";
interface ICard {
  places : number;
  tours : number;
  countries : number;
};
export function ShortCuts() {
  const cls = 'btn btn-flex h-70px bg-info btn-color-white btn-active-color-gray-300 shadow-sm px-20 py-20 bgi-no-repeat';
  const [cards, setCards] = useState<ICard>({places: 0, tours: 0, countries: 0});
  const bindCards = () => {
    getCardsData().then((res) => {
      setCards(res.data.data);
    }).catch((err) => {
      console.log(err);
    });
  }
  useEffect(() => {
    bindCards();
  }, []);

  return (
    <>

      <div className="row mb-lg-12">
        {/* 1 */}
        <div className="col-md-4 py-5">
          <Link to="/locations" className={cls}
            style={{
              backgroundPosition: 'right top',
              backgroundSize: '20% auto',
              // backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + MySvg1)})`,
              zIndex: 1
            }}>
            <span className="d-flex flex-column align-items-start ms-2">
              <span className="fs-5">{cards.places} Active Places</span>
            </span>
          </Link>
        </div>

        {/* 2 */}
        {/* <div className="col-md-3 py-5">
          <a href="/tourist-add" className={cls}
            style={{
              backgroundPosition: 'right top',
              backgroundSize: '20% auto',
              // backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image2)})`,
              zIndex: 1
            }}>

            <span className="d-flex flex-column align-items-start ms-2">
              <span className="fw-bolder fs-3">Add Tourist</span>
              <span className="fs-5">{5} Total Tourists</span>
            </span>
          </a>
        </div> */}

        {/* 3 */}
        <div className="col-md-4 py-5">
          <Link to="/tours" className={cls}
            style={{
              backgroundPosition: 'right top',
              backgroundSize: '20% auto',
              zIndex: 1
            }}>
            <span className="d-flex flex-column align-items-start ms-2">
              <span className="fs-5">{cards.tours} Active Tours</span>
            </span>
          </Link>
        </div>
        {/* 4 */}
        <div className="col-md-4 py-5 mb-10">
          <Link to='/places/countries' className={cls}
            style={{
              backgroundPosition: 'right top',
              backgroundSize: '20% auto',
              // backgroundImage: `url(${toAbsoluteUrl('/media/svg/shapes/' + image4)})`,
              zIndex: 1
            }}>
            <span className="d-flex flex-column align-items-start ms-2">
              <span className="fs-5">{cards.countries} Active Countries</span>
            </span>
          </Link>
        </div>
      </div>
    </>
  )
}