import axios from 'axios'
import { debug } from 'console'
import { AUTH_LOCAL_STORAGE_KEY } from './AuthHelpers'
import { AuthModel, UserModel } from './_models'
import { baseURL,localBaseURL } from '../../../../services/BaseUrl'
import axiosInstance from '../../../../services/AxiosInstance'

const API_URL = process.env.NODE_ENV === 'development' ? localBaseURL : baseURL;

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/account/verify_token`
export const LOGIN_URL = `${API_URL}/auth/admin/login`
export const REGISTER_URL = `${API_URL}/users/register/admin`
export const REQUEST_PASSWORD_URL = `${API_URL}/auth/forgot-password`
export const GOOGLE_REGISTER_URL = `${API_URL?.replace("/api", "")}/extauth/google-register`

// Server should return AuthModel
export function login(email: string, password: string) {
const deviceId = "1234567890"
const deviceToken = "1234567890"
const username = email
  return axios.post<any>(LOGIN_URL, {
    email,
    password,
    username,
    deviceId,
    deviceToken

  })
}

// Server should return AuthModel
export function register(

  firstName: string,
  lastName: string,
  password: string,
  email: string,
  roles : string[],
  phone: string,

  stateId:number,
  cityId:number,
  countryId:number,
  // password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    firstName,
    lastName,
    password,
    email,
    roles,
    phone,
    stateId,
    cityId,
    countryId,
   
  })
}
export const googleRegister = (
  roles: string[],
  phone: string,
  stateId: number,
  cityId: number,
  countryId: number,
  userId :string,
) => {
  var data = {
    roles,
    phone,
    stateId,
    cityId,
    countryId,
    userId
  }
  debugger
  return axios.post(GOOGLE_REGISTER_URL,data)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post(`${REQUEST_PASSWORD_URL+`?email=${email}`}`
  )
}

export function getUserByToken(token: string) {
  const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
  if (aware_user) {
    // const username = axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    //   accessToken: token,
    // }).then((response) => {
    //   console.log(response);
    // })
    // const user: any = {
    //   username: username,
    // };
    return aware_user;
  }
  return null;

  //const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
  // if (aware_user) {
  //   var _token = aware_user?.token;
  //   if (_token === token) {
  //     var user: UserModel = {
  //       id: 0,
  //       username: 'string',
  //       password: 'string',
  //       email: 'string',
  //       first_name: 'string',
  //       last_name: 'string',
  //       fullname: 'string',
  //       occupation: 'string',
  //       companyName: 'string',
  //       phone: 'string',
  //       roles: [],
  //       pic: 'string',
  //       timeZone: 'string',
  //       website: 'https://keenthemes.com'
  //     };

  //     return user;
  //   }
  //   return null;
  // }
}
