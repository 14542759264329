export interface ICompanyDetails {
    id: number;
    companyName: string;
    addressLine1: string;
    addressLine2: string;
    city: string;
    postalCode: string;
    alternateNo: string;
    email: string;
    website: string;
    gstin: string;
    businessType: string;
    organizationType: number | null;
    industryType: number | null;
    financialYear: number | null;
    stateId: number | null;
    mobile: string;
    portalUrl: string;
    isBomEnabled: boolean;
    appId: number;
    pictureId: number;
    parentId: number | null;
    code: string;
    pAN: string;
    pass: string;
    stateName: string;
    logo: string;
}

//ICompanyDetails
export const companyInitValues: ICompanyDetails = {
    id: 0,
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    alternateNo: '',
    email: '',
    website: '',
    gstin: '',
    businessType: '',
    organizationType: null,
    industryType: null,
    financialYear: null,
    stateId: null,
    mobile: '',
    portalUrl: '',
    isBomEnabled: false,
    appId: 0,
    pictureId: 0,
    parentId: null,
    code: '',
    pAN: '',
    pass: '',
    stateName: '',
    logo: '',
  }
  