import React, {useEffect} from 'react'
import {useFormik} from 'formik'
import {CountrySchema} from './SettingsSchema'
import {addCountry} from '../../../services/CommonService'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'

export default function AddCountry() {
  const initialValues = {
    name: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: CountrySchema,
    enableReinitialize: true,
    onSubmit: (values) => saveCountry(values),
  })
  const saveCountry = (values: any) => {
    const tId = toast.loading('Please wait...')
    addCountry(values)
      .then((res) => toastNotify(tId, res))
      .catch(() => {
        const res = {data: {status: false, message: 'Something went wrong'}}
        toastNotify(tId, res)
      })
      .finally(() => {
        formik.setSubmitting(false)
        toast.dismiss(tId)
      })
  }

  return (
    <div >
      <form onSubmit={formik.handleSubmit}>
        <div className='card'>
          <div className='card-header' role='button' data-bs-toggle='collapse' data-bs-target='#kt_settings_addCountry_method'>
            <h3 className='card-title'>Add Country</h3>
          </div>
          <div className='collapse-show' id='kt_settings_addCountry_method'>
            <div className='card-body'>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label>Country Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter Country Name'
                      name='name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.name ? (
                      <div className='text-danger'>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className='offset-md-4 col-md-2 mt-6'>
                  <button
                    type='submit'
                    className='btn btn-sm btn-primary'
                    disabled={formik.isSubmitting && !formik.isValid}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
