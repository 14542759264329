import React, { useState } from 'react'
import { PageLink, PageTitle } from '../../../_metronic/layout/core';
import { LOADINGSTAGES, ServerMessage } from '../../../_shared/components/_laodSetups/LoadingSteps';
import { Loader } from '../components/components';
import { ListCard } from '../components/Widgets/ListCard';
import { UserTable } from './UsersCard';
import { useTUsersHooks, useUsersHooks } from './UsersHooks/ApiUsers';
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tourist Users',
    path: '/tourist-users',
    isSeparator: true,
    isActive: false,
  },
]
export default function TouristUsers() {
  const initialValues = {
    cityId: 0, stateId: 0, countryId: 0, pageSize: 10, pageNumber: 1, filter: ''
  }
  const [values, setValues] = useState<any>({ ...initialValues });
  const { users, isLoading, loadingStages } = useTUsersHooks({ values });
  const filterSearch = (e: any) => {
    if (e.target.value.length > 2) {
      setValues({ ...values, filter: e.target.value });
    }
    else if (e.target.value.length == 0) {
      setValues({ ...values, filter: e.target.value });
    }
  }
  const array: any = [];
  users.map((item: any) => {
    array.push({
      id: item.id,
      name: item.firstName + " " + item.lastName,
      email: item.email,
      phoneNumber: item.phoneNumber,
      roleNames: item.roleNames,
      countryName: item.countryName,
      stateName: item.stateName,
      cityName: item.cityName,
      userId: item.userId,
    })
  });
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Users</PageTitle>
      <div className="card card-custom gutter-b bg-secondary rounded">
        <ListCard title="Tourist Users" />
        <div className="card-body rounded">
          <div className="mb-7">
            <div className="row align-items-center">
              <div className="col-lg-8 col-xl-8">
                <div className="row align-items-center">
                  <div className="col-md-4 my-2 my-md-0 d-flex align-items-center">
                    <div className="input-icon">
                      <input type="text" className="form-control"
                        onChange={(e: any) => filterSearch(e)}
                        placeholder="Search..." id="kt_datatable_search_query" />
                      <span><i className="flaticon2-search-1 text-muted"></i></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-xl-4 mt-5 mt-lg-0">
                <a href="#" className="btn btn-light-primary px-6 font-weight-bold">Search</a>
              </div>
            </div>
          </div>
          <div className="datatable datatable-bordered datatable-head-custom" id="kt_datatable"></div>
        </div>
      </div>
      {loadingStages === LOADINGSTAGES.POSITIVE && <UserTable users={array} />}
      {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Something went wrong" />}
      {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No data found" />}
    </>
  )
}
