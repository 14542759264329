import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
// import { DisableSidebar } from '../../_metronic/layout/core'
import { WithChildren } from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AccountPage from '../pages/account/AccountPage'

import ListPlaces from '../pages/places/ListPlaces'
import { AddPlace } from '../pages/places/AddPlace'
import FeaturedPlacesList from '../pages/places/FeaturedPlacesList'
import { ListCountries } from '../pages/places/countriesplaces/ListCountries'
import { ListStates } from '../pages/places/statecityplaces/ListStates'
import { ListCities } from '../pages/places/statecityplaces/ListCities'
import { Tables } from '../modules/widgets/components/Tables'
import { Lists } from '../modules/widgets/components/Lists'
import ListTours from '../pages/tours/ListTours'
import AddTour from '../pages/tours/AddTour'
import { WebUsers } from '../pages/Users/WebUsers'
import { UserView } from '../pages/Users/UserView'
import TouristUsers from '../pages/Users/TouristUsers'
import JourneyCard from '../pages/tours/journey/JourneyCard'
import { Card1 } from '../../_metronic/partials/content/cards/Card1'
import { Item2 } from '../../_metronic/partials/content/activity/Item2'
import { Item3 } from '../../_metronic/partials/content/activity/Item3'
import { Item4 } from '../../_metronic/partials/content/activity/Item4'
import { Item5 } from '../../_metronic/partials/content/activity/Item5'
import { Item6 } from '../../_metronic/partials/content/activity/Item6'
import { Item7 } from '../../_metronic/partials/content/activity/Item7'
import { Item8 } from '../../_metronic/partials/content/activity/Item8'
import { ActivityDrawer, DemosDrawer } from '../../_metronic/partials'
import ListToursByUser from '../pages/tours/ListTours'
import ListByUserTours from '../pages/tours/ListByUserTours'
import TourAudioList from '../pages/tours/TourAudio/TourAudioList'
import { TourAudioAdd } from '../pages/tours/TourAudio/TourAudioAdd'
import Settings from '../pages/settings/Settings'
import { FeatureTypeList } from '../pages/tours/FeaturesTypes'
import { ListToursForPlace } from '../pages/places/ListToursForPlace'
import TestFile from '../pages/TestFile'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  //const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))


  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='locations' element={<ListPlaces />} />
        <Route path='places/add' element={<AddPlace />} />
        <Route path='places/edit/:id' element={<AddPlace />} />
        <Route path='featured-places' element={<FeaturedPlacesList />} />
        <Route path='places/countries' element={<ListCountries />} />
        <Route path='places/country/:countryId' element={<ListPlaces />} />
        <Route path='places/states' element={<ListStates />} />
        <Route path='places/state/:stateId' element={<ListPlaces />} />
        <Route path='places/cities' element={<ListCities />} />
        <Route path='places/city/:cityId' element={<ListPlaces />} />
        <Route path='tours' element={<ListTours />} />
        <Route path='my-tours' element={<ListByUserTours />} />
        <Route path='featured-tours' element={<ListTours />} />
        <Route path='tours/add' element={<AddTour />} />
        <Route path='tours/edit/:id' element={<AddTour />} />
        <Route path='web-users' element={<WebUsers />} />
        <Route path='tourist-users' element={<TouristUsers />} />
        <Route path='tabels' element={<DemosDrawer />} />
        <Route path='user-view' element={<UserView />} />
        <Route path='users/edit/:id' element={<UserView />} />
        <Route path='tour/journey/:id' element={<JourneyCard />} />
        <Route path='tour/audio/:id' element={<TourAudioList />} />
        <Route path='tour/audio/add/:id' element={<TourAudioAdd />} />
        <Route path='settings' element={<Settings />} />
        <Route path='feature-types' element={<FeatureTypeList />} />
        <Route path='places/tour/:id/:countryId/:stateId' element={<ListToursForPlace />} />
        <Route path='test' element={<TestFile />} />

        {/* Lazy Modules */}
        {/* <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        /> */}
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return (
    <Suspense fallback={<TopBarProgress />}>
      {/* <DisableSidebar> */}
      {children}
      {/* </DisableSidebar> */}
    </Suspense>
  )
}

export { PrivateRoutes }
