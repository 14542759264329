import { useEffect, useState } from 'react'
import { getAllCountries } from '../../../../services/CommonService'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {
  LoadData,
  LOADINGSTAGES,
  ServerMessage,
} from '../../../../_shared/components/_laodSetups/LoadingSteps'
import { CountryCard } from './CountryCard'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Countries',
    path: '/places/countries',
    isSeparator: true,
    isActive: false,
  },
]
export function ListCountries(props: any) {
  // create class into Dto's

  const [countries, setCountries] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE)
  const [filterValues, setFilterValues] = useState<any>({
    filter: '',
    status: 0,
  })

  const bindCountries = (filter: string, status: number) => {
    status = parseInt(status.toString())
    setIsLoading(true)
    setLoadingStages(LOADINGSTAGES.LOADING)
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          setLoadingStages(LOADINGSTAGES.POSITIVE)
          let filteredData = res.data.data
          if (filter.length > 2) {
            filteredData = filteredData.filter((item: any) => {
              return item.name.toLowerCase().includes(filter.toLowerCase())
            })
          }

          if (status === 1) {
            filteredData = filteredData.filter((item: any) => {
              return item.isActive === true
            })
          } else if (status === 2) {
            filteredData = filteredData.filter((item: any) => {
              return item.isActive === false
            })
          }
          setCountries(filteredData)
          if (filteredData.length === 0) {
            setLoadingStages(LOADINGSTAGES.NEGATIVE)
          }
        } else {
          setLoadingStages(LOADINGSTAGES.NEGATIVE)
          setCountries([])
        }
      })
      .catch((err: any) => {
        setLoadingStages(LOADINGSTAGES.ERROR)
        setCountries([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    bindCountries('', 0)
  }, [])

  const handleSearch = () => {
    if (filterValues.filter.length < 3 && filterValues.status === 0) return
    bindCountries(filterValues.filter, filterValues.status)
  }

  const refresh = () => {
    bindCountries('', 0)
  }

  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Countries</PageTitle>
      <div className='card card-custom gutter-b bg-secondary rounded'>
        <div className="card-header flex-wrap border-0 pt-6 pb-0 rounded">
          <div className="card-title">
            Countries
          </div>
          <div className="card-toolbar">
          </div>
        </div>
        <div className='card-body rounded'>
          <div className='mb-7'>
            <div className='row align-items-center'>
              <div className='col-lg-8 col-xl-8'>
                <div className='row align-items-center'>
                  <div className='col-md-5 my-2 my-md-0'>
                    <div className='input-icon'>
                      <input
                        type='text'
                        className='form-control'
                        placeholder='Search...'
                        id='kt_datatable_search_query'
                        value={filterValues.filter}
                        onChange={(e) => setFilterValues({ ...filterValues, filter: e.target.value })}
                      />
                    </div>
                  </div>
                  <div className='col-md-4 my-2 my-md-0'>
                    <div className='d-flex align-items-center'>
                      <label className='mr-3 mb-0 d-none d-md-block'>Status:</label>
                      &nbsp;
                      <select
                        className='form-control'
                        id='kt_datatable_search_type'
                        onChange={(e) => setFilterValues({ ...filterValues, status: e.target.value })}
                        value={filterValues.status}
                      >
                        <option value={0}>All</option>
                        <option value={1}>Active</option>
                        <option value={2}>InActive</option>
                      </select>
                    </div>
                  </div>
                  <div className='col-md-3 my-2 my-md-0'>
                    <button
                      className='btn btn-light-primary px-6 font-weight-bold'
                      onClick={handleSearch}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingStages === LOADINGSTAGES.POSITIVE && (
        <CountryCard countries={countries} onRefresh={refresh} />
      )}
      {loadingStages === LOADINGSTAGES.LOADING && <LoadData isLoading={isLoading} />}
      {loadingStages === LOADINGSTAGES.NEGATIVE && (
        <ServerMessage message='No Data Found' />
      )}

      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something Went Wrong' />}
    </>
  )
}
