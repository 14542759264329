/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import { useLayout } from '../../core'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { AsideMenu } from './AsideMenu'
import { AUTH_LOCAL_STORAGE_KEY } from '../../../../app/modules/auth'
// import { getLoginUser } from '../../../../services/UserService'

const AsideDefault: FC = () => {
  const { config, classes } = useLayout()
  const asideRef = useRef<HTMLDivElement | null>(null)
  const { aside } = config

  const minimize = () => {
    asideRef.current?.classList.add('animating')
    setTimeout(() => {
      asideRef.current?.classList.remove('animating')
    }, 300)
  }

  const [showMenu, setShowMenu] = useState<any>('block');
  // useEffect(() => {
  //   // wrap your async call here
  //   const checkCompleteness = async () => {
  //     const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '');
  //     if (aware_user) {
  //       await getLoginUser(aware_user.userKey).then((response: any) => {
  //         setShowMenu(response.data.data.isProfileComplete ? 'block' : 'none')
  //       })
  //     }
  //   };

  //   // then call it here
  //   checkCompleteness();
  // }, []);

  return (
    <div
      id='kt_aside'
      className={clsx('aside', classes.aside.join(' '))}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
      ref={asideRef}
      style={{ backgroundColor: "#ffd580",
      // borderColor: "#ffd580",
      borderRadius : "2px",
      // borderTopRightRadius: "0px", 
    }}
    >
      {/* begin::Brand */}
      <div className='aside-logo flex-column-auto' id='kt_aside_logo'>
        {/* begin::Logo */}
        {aside.theme === 'dark' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-35px logo'
              src={toAbsoluteUrl('/media/logos/atlogo1.png')}
            />
          </Link>
        )}
        {aside.theme === 'light' && (
          <Link to='/dashboard'>
            <img
              alt='Logo'
              className='h-35px logo'
              src={toAbsoluteUrl('/media/logos/atlogo1.png')}
            />
          </Link>
        )}
        {/* end::Logo */}

        {/* begin::Aside toggler */}
        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-toggle'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
            onClick={minimize}
          >
            <KTSVG
              path={'/media/icons/duotune/arrows/arr080.svg'}
              className={'svg-icon-1 rotate-180'}
            />
          </div>
        )}
        {/* end::Aside toggler */}
      </div>
      {/* end::Brand */}
      {/* begin::Aside menu */}
      <div className='aside-menu flex-column-fluid' style={{ display: showMenu }}>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      {/* end::Aside menu */}

      {/* begin::Footer */}
      <div className='aside-footer flex-column-auto pt-5 pb-7 px-5' id='kt_aside_footer'>
        {/* <a
          target='_blank'
          className='btn btn-custom btn-primary w-100'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL}
          data-bs-toggle='tooltip'
          data-bs-trigger='hover'
          data-bs-dismiss-='click'
          title='Check out the complete documentation with over 100 components'
        >
          <span className='btn-label'>Docs & Components</span>
          <span className='svg-icon btn-icon svg-icon-2'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' />
          </span>
        </a> */}
      </div>
      {/* end::Footer */}
    </div>
  )
}

export { AsideDefault }
