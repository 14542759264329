import React, {useEffect, useRef, useState} from 'react'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import * as Yup from 'yup'
import {useFormik} from 'formik'

import {
  getAllCountries,
  getCitiesByState,
  getStatesByCountry as getStates,
} from '../../../services/CommonService'
import {toastNotify} from '../../../services/NotifyService'
import {useNavigate, useParams} from 'react-router-dom'
import {ILocationModel} from '../../../_models/ILoctionModel'
import {
  addTourLocation,
  getTourLocationById,
  updateLocationFeature,
  updateTourLocation,
} from '../../../services/TourLocationService'
import {LOADINGSTAGES, ServerMessage} from '../../../_shared/components/_laodSetups/LoadingSteps'
import {Loader} from '../components/components'
import {PlaceHeader} from './PlaceHeader'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {updateFeatureType} from '../../../services/TourServices/FeatureTypeService'
// import { DisableSidebar, PageTitle } from '../../../_metronic/layout/core';
const initialValues = {
  id: 0,
  name: '',
  locationTag: '',
  cityId: 0,
  stateId: 0,
  countryId: 0,
  coverImageId: 0,
  imageId: 0,
  shortDescription: '',
  longDescription: '',
  addressLine1: '',
  addressLine2: '',
  zipCode: '',
  landmark: '',
  lat: 0,
  lng: 0,
  locationTypeId: 0,
  isActive: true,
  isDeleted: false,
  price: 0,
  isFeatured: false,
}
const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  locationTag: Yup.string().required('Tag Line is required'),
  cityId: Yup.number().required('City is required').notOneOf([0], 'Select City'),
  stateId: Yup.number().required('State is required').notOneOf([0], 'Select State'),
  countryId: Yup.number().required('Country is required').notOneOf([0], 'Select Country'),
  shortDescription: Yup.string().required('Short Description is required'),
  longDescription: Yup.string().required('Long Description is required'),
  addressLine1: Yup.string().required('Address Line 1 is required'),
  addressLine2: Yup.string().required('Address Line 2 is required'),
  zipCode: Yup.string().required('Zip Code is required'),
  landmark: Yup.string().required('Landmark is required'),
  lat: Yup.number().required('Latitude is required'),
  lng: Yup.number().required('Longitude is required'),
  locationTypeId: Yup.number().required('Location Type is required'),
  price: Yup.number().required('Price is Required'),
})

export function AddPlace(props: any) {
  const navigate = useNavigate()

  const [data, setData] = useState<any>(initialValues)
  const {id} = useParams()
  const [placeId, setPlaceId] = useState<any>(id)
  const [title, setTitle] = useState<any>(id == null ? 'Add' : 'Edit')
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE)
  const [loading, setLoading] = useState(false)
  const formik = useFormik<ILocationModel>({
    enableReinitialize: true,
    initialValues: data,
    validationSchema: profileDetailsSchema,
    onSubmit: (values: ILocationModel, {resetForm}) => {
      const id = toast.loading('Please wait...')
      setLoading(true)
      setTimeout(() => {
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        if (placeId == null || placeId == 0 || placeId == undefined) {
          addTourLocation(updatedData)
            .then((res: any) => {
              if (res.data.status) {
                resetForm()
                toastNotify(id, res)
                // redirect(); // redirect
              } else {
                toastNotify(id, res)
              }
            })
            .catch((err: any) => {
              const res = {data: {message: 'Something went wrong', success: false}}
              toastNotify(id, res)
            })
            .finally(() => {
              setLoading(false)
              resetForm()
            })
        } else {
          console.log('update')
          // update
          interface PlaceId {
            id: number
          }
          const placeIdObj: PlaceId = {id: placeId}
          updatedData.id = placeId

          updateTourLocation(updatedData)
            .then((res: any) => {
              if (res.data.status) {
                resetForm()
                toastNotify(id, res)
                // redirect(); // redirect
              } else {
                toastNotify(id, res)
              }
            })
            .catch((err: any) => {
              const res = {data: {message: 'Something went wrong', success: false}}
              toastNotify(id, res)
            })
            .finally(() => {
              setLoading(false)
              resetForm()
            })
        }
      }, 1000)
    },
  })
  const [countries, setCountries] = useState<any>([])
  const bindCountries = () => {
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          let data = res.data.data
          // set default value
          data.unshift({id: 0, name: 'Select Country'})
          setCountries(data)
        } else {
          setCountries([])
        }
      })
      .catch(() => {
        setCountries([])
      })
  }
  const [states, setStates] = useState<any>([])
  const bindStates = (countryId: any) => {
    getStates(countryId)
      .then((res: any) => {
        const array = res.data.data
        setStates(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
  const [cities, setCities] = useState<any>([])
  const bindCities = (stateId: any) => {
    getCitiesByState(stateId)
      .then((res: any) => {
        const array = res.data.data
        setCities(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  useEffect(() => {
    setStates([])
    setCities([])
    if (placeId != null && placeId != 0 && placeId != undefined) {
      formik.setFieldValue('stateId', 0)
      formik.setFieldValue('cityId', 0)
    }
    if (formik.values.countryId != 0) {
      bindStates(formik.values.countryId)
    }
  }, [formik.values.countryId])
  useEffect(() => {
    setCities([])
    // if page is edit page then dont set cityId to 0
    if (placeId == null || placeId == 0 || placeId == undefined) {
      formik.setFieldValue('cityId', 0)
    }
    if (formik.values.stateId != 0) {
      bindCities(formik.values.stateId)
    }
  }, [formik.values.stateId])

  const redirect = () => {
    setTimeout(() => {
      navigate('/places')
    }, 500)
  }
  const [imagePath, setImagePath] = useState('')
  const bindDetails = (custId: any) => {
    setLoading(true)
    setLoadingStages(LOADINGSTAGES.LOADING)
    setTimeout(() => {
      getTourLocationById(custId)
        .then((res: any) => {
          if (res.data.status) {
            let _data = res.data.data
            bindStates(_data.countryId)
            bindCities(_data.stateId)
            formik.setFieldValue('stateId', _data.stateId)
            formik.setFieldValue('cityId', _data.cityId)
            setData(_data)
            setImagePath(_data.imageSearch.imagePath)
            setLoadingStages(LOADINGSTAGES.POSITIVE)
          } else {
            setLoadingStages(LOADINGSTAGES.NEGATIVE)
          }
        })
        .catch(() => {
          setLoadingStages(LOADINGSTAGES.ERROR)
        })
        .finally(() => {
          setLoading(false)
        })
    }, 500)
  }

  useEffect(() => {
    bindCountries()
    if (id) {
      bindDetails(id)
    } else {
      setLoadingStages(LOADINGSTAGES.POSITIVE)
    }
  }, [id])
  const changeFeaturedStatus = (id: any) => {
    updateLocationFeature(id)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(id, res)
          bindDetails(id)
        } else {
          toastNotify(id, res)
        }
      })
      .catch((err: any) => {
        const res = {data: {message: 'Something went wrong', success: false}}
        toastNotify(id, res)
      })
  }

  return (
    <>
      {/* <PageTitle> {title} Place </PageTitle> */}
      {/* <DisableSidebar>  */}

      {id != null ? (
        loadingStages === LOADINGSTAGES.POSITIVE && (
          <PlaceHeader
            imagePath={imagePath}
            placeName={data.name ?? ''}
            id={id}
            onSuccess={() => {
              bindDetails(id)
            }}
          />
        )
      ) : (
        <span style={{visibility: 'hidden'}}></span>
      )}
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0'
          // role='button'
          // data-bs-toggle='collapse'
          // data-bs-target='#kt_kitty_group_details'
          // aria-expanded='true'
          // aria-controls='kt_kitty_group_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bolder m-0'>{title} Place</h3>
          </div>
          <div className='card-toolbar'>
            {/* <span className='btn btn-sm btn-primary'
              onClick={() => { navigate("/places") }}
            >Assign Tour</span> */}
            {loadingStages === LOADINGSTAGES.POSITIVE ? (
              data.isFeatured ? (
                <span
                  className='btn btn-sm btn-danger'
                  onClick={() => {
                    changeFeaturedStatus(id)
                  }}
                >
                  Remove Featured
                </span>
              ) : (
                <span
                  className='btn btn-sm btn-primary'
                  onClick={() => {
                    changeFeaturedStatus(id)
                  }}
                >
                  Mark as Featured
                </span>
              )
            ) : (
              <span style={{visibility: 'hidden'}}></span>
            )}
          </div>
        </div>
        {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No data found' />}
        {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
        {loadingStages == LOADINGSTAGES.LOADING && <Loader />}
        {loadingStages == LOADINGSTAGES.POSITIVE && (
          <div className='collapse show'>
            <form onSubmit={formik.handleSubmit} noValidate className='form'>
              <div className='card-body border-top p-9'>
                {/* 1 */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Name</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control  mb-3 mb-lg-0'
                          placeholder='Location Name'
                          {...formik.getFieldProps('name')}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.name}</div>
                          </div>
                        )}
                      </div>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control  mb-3 mb-lg-0'
                          placeholder='Tag Line'
                          {...formik.getFieldProps('locationTag')}
                        />
                        {formik.touched.locationTag && formik.errors.locationTag && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.locationTag}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2 */}

                {/* 3 */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Short Description
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <textarea
                          className='form-control '
                          placeholder='Short Description'
                          {...formik.getFieldProps('shortDescription')}
                        />
                        {formik.touched.shortDescription && formik.errors.shortDescription && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.shortDescription}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 4 */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                    Long Description
                  </label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-12 fv-row'>
                        <textarea
                          rows={5}
                          className='form-control '
                          placeholder='Long Description'
                          {...formik.getFieldProps('longDescription')}
                        />
                        {formik.touched.longDescription && formik.errors.longDescription && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.longDescription}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 5 */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <textarea
                          className='form-control mb-3 mb-lg-0'
                          placeholder='Address Line 1'
                          {...formik.getFieldProps('addressLine1')}
                        />
                        {formik.touched.addressLine1 && formik.errors.addressLine1 && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.addressLine1}</div>
                          </div>
                        )}
                      </div>
                      {/* 3 */}
                      <div className='col-lg-6 fv-row'>
                        <textarea
                          className='form-control mb-3 mb-lg-0'
                          placeholder='Address Line 2'
                          {...formik.getFieldProps('addressLine2')}
                        />
                        {formik.touched.addressLine2 && formik.errors.addressLine2 && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.addressLine2}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* 6 */}
                {/* Country State */}
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>Country</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <select
                          className='form-select form-select-solid'
                          {...formik.getFieldProps('countryId')}
                        >
                          {countries.map((country: any) => (
                            <option key={country.id} value={country.id}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.countryId && formik.errors.countryId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.countryId}</div>
                          </div>
                        )}
                      </div>
                      {/* 3 */}
                      <div className='col-lg-6 fv-row'>
                        <select
                          disabled={formik.values.countryId == 0}
                          className='form-select form-select-solid mb-3 mb-lg-0'
                          {...formik.getFieldProps('stateId')}
                        >
                          <option value=''>Select State</option>
                          {states.map((state: any) => (
                            <option key={state.id} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.stateId && formik.errors.stateId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.stateId}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label required fw-bold fs-6'>City</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <select
                          disabled={formik.values.stateId == 0}
                          className='form-select form-select-solid'
                          {...formik.getFieldProps('cityId')}
                        >
                          <option value={0}>Select City</option>
                          {cities.map((city: any) => (
                            <option key={city.id} value={city.id}>
                              {city.name}
                            </option>
                          ))}
                        </select>
                        {formik.touched.cityId && formik.errors.cityId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.cityId}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Zip Code</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control '
                          placeholder='Zip Code'
                          {...formik.getFieldProps('zipCode')}
                        />
                        {formik.touched.zipCode && formik.errors.zipCode && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.zipCode}</div>
                          </div>
                        )}
                      </div>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control  mb-3 mb-lg-0'
                          placeholder='Landmark'
                          {...formik.getFieldProps('landmark')}
                        />
                        {formik.touched.landmark && formik.errors.landmark && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.landmark}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row mb-6'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Price</label>
                  <div className='col-lg-8'>
                    <div className='row'>
                      <div className='col-lg-6 fv-row'>
                        <input
                          type='text'
                          className='form-control '
                          placeholder='Price'
                          {...formik.getFieldProps('price')}
                        />
                        {formik.touched.price && formik.errors.price && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.price}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                  <button type='submit' className='btn btn-primary' disabled={loading}>
                    {!loading && 'Save Changes'}
                    {loading && (
                      <span className='indicator-progress' style={{display: 'block'}}>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </>
  )
}
