import { toast } from "react-toastify";

export const toastNotify = (id: any, res: any) => {
    
    toast.update(id, {
        render: res.data.message,
        type: res.data.status ? "success" : "error",
        isLoading: false,
        autoClose: 3000,
        position: "top-center" 
    });
}