import {useState} from 'react'
import {KTSVG} from '../../../../../_metronic/helpers'
import {useAuth} from '../../core/Auth'
import {baseURL, localBaseURL} from '../../../../../services/BaseUrl'
import {ExternalAuth} from './Ext_Auth'
import {googleLogin} from '../../../../../services/AuthServics'
import {GoogleLogin} from '@react-oauth/google'
import {toastNotify} from '../../../../../services/NotifyService'
import {toast} from 'react-toastify'
import {IUserAuthenticationModel, LoginStatus} from '../../../../interfaces/IUserAuth'
import {getUserByToken} from '../../core/_requests'
type Props = {
  onGoogleClick: any
  onResponse: any
  onPositive: any
  onNotApproved: any
}
export const GoogleSignInButton = (props: Props) => {
  const {onGoogleClick, onResponse, onPositive, onNotApproved} = props
  const {saveAuth, setCurrentUser} = useAuth()
  const LOADING_STATE = {
    IDLE: 'idle',
    PENDING: 'pending',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
  }
  const handleSuccess = async (response: any) => {
    const tId = toast.loading('Authenticating....')
    const dto = {
      accessToken: response.credential,
      clientId: response.clientId,
    }
    try {
      const res = await googleLogin(dto)
      if (res.data.status) {
        console.log(res.data.data)
        const data = res.data.data as IUserAuthenticationModel
        const currentCase = data.currentCase
        switch (currentCase) {
          case LoginStatus.NotApproved:
            onResponse(res.data.data)
            toastNotify(tId, res)
            onNotApproved(data)
            break
          case LoginStatus.Positive:
            toastNotify(tId, res)
            if (data.userDetails.isProfileCompleted) {
              try {
                saveAuth(res.data.data)
                setTimeout(() => {
                  window.location.reload()
                }, 1000)
                setTimeout(async () => {
                  const user = await getUserByToken(res.data.data.accessToken)
                  setCurrentUser(user)
                }, 2000)
              } catch (e) {
                console.log(e)
              }
              setTimeout(async () => {
                const user = await getUserByToken(data.accessToken)
                setCurrentUser(user)
              }, 500)
            } else {
              onGoogleClick(true)
            }
            break
          case LoginStatus.Unregistered:
            toastNotify(tId, res)
            onGoogleClick(true)
            onResponse(res.data.data)
            break
          case LoginStatus.Incomplete:
            toastNotify(tId, res)
            onResponse(res.data.data)
            break
          case LoginStatus.Invalid:
            toastNotify(tId, res)
            break
          default:
            toastNotify(tId, res)
            break
        }
      } else {
        toastNotify(tId, res)
      }
    } catch (err) {
      const res = {data: {status: false, message: 'something went wrong'}}
      toastNotify(tId, res)
    } finally {
      toast.dismiss(tId)
    }
  }

  return (
    <>
      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
        }}
      >
        <>
          <GoogleLogin
            theme='filled_blue'
            onSuccess={(credentialResponse) => {
              handleSuccess(credentialResponse)
            }}
            onError={() => {
              const res = {data: {status: false, message: 'something went wrong'}}
              toastNotify(0, res)
            }}
            // useOneTap
          />
        </>
      </div>
    </>
  )
}
