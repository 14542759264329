import {Fragment, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import {dataIndexOf} from 'react-widgets/cjs/Accessors'
import {
  getAllCountries,
  getCitiesByState,
  getStatesByCountry,
} from '../../../services/CommonService'
import {toastNotify} from '../../../services/NotifyService'
import {activeLocation, getAllLocations} from '../../../services/TourLocationService'
import {updateAction} from '../../../services/TourServices/JourneyService'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {
  LoadData,
  LOADINGSTAGES,
  NoDataFound,
  ServerMessage,
} from '../../../_shared/components/_laodSetups/LoadingSteps'
import {Loader} from '../components/components'
import {PlaceTabel} from './PlaceTabel'
import {DatePicker} from 'react-widgets'
import Pagination from '../../../lib/Pagination/Pagination'
import {PAGE_SIZE} from '../../../_shared'
import {IPagedDataList} from './TourListPaggination'
import {ILocationModel} from '../../../_models/ILoctionModel'
// import list.css from previous file
const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Places',
    path: '/places',
    isSeparator: true,
    isActive: false,
  },
]
export default function ListPlaces(props: any) {
  // var result = await _service.GetLocations(cityId, stateId, countryId, isActive,
  //   languageId, pageSize, pageNumber,filter);
  const initialValues = {
    cityId: 0,
    stateId: 0,
    countryId: 0,
    isActive: true,
    languageId: 1,
    pageSize: 20,
    pageNumber: 1,
    filter: '',
    fromDate: '',
    toDate: '',
    featureType: 0,
  }
  // create class into Dto's
  const {countryId, stateId, cityId} = useParams()
  const [pagenation, setPagenation] = useState<IPagedDataList<ILocationModel>>({
    data: [],
    page: {pageNumber: 0, size: 0, totalElements: 0, totalPages: 0},
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE)
  const bindPlaces = (values: any) => {
    setIsLoading(true)
    setLoadingStages(LOADINGSTAGES.LOADING)
    getAllLocations(values)
      .then((res: any) => {
        if (res.data.status) {
          setLoadingStages(LOADINGSTAGES.POSITIVE)
          setPagenation(res.data.data)
        } else {
          setLoadingStages(LOADINGSTAGES.NEGATIVE)
          setPagenation({
            data: [],
            page: {pageNumber: 0, size: 0, totalElements: 0, totalPages: 0},
          })
        }
      })
      .catch((err: any) => {
        setLoadingStages(LOADINGSTAGES.ERROR)
        setPagenation({
          data: [],
          page: {pageNumber: 0, size: 0, totalElements: 0, totalPages: 0},
        })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [countries, setCountries] = useState<[]>([])
  const [currentPage, setCurrentPage] = useState(1)
  const bindCountries = () => {
    setIsLoading(true)
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          let data = res.data.data
          data.unshift({id: 0, name: 'Select Country'})
          setCountries(data)
        } else {
          setCountries([])
        }
      })
      .catch((err: any) => {
        setCountries([])
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  useEffect(() => {
    bindCountries()
    initialValues.pageNumber = currentPage
    if (countryId) {
      bindPlaces({...initialValues, countryId: countryId})
    } else if (stateId) {
      bindPlaces({...initialValues, stateId: stateId})
    } else if (cityId) {
      bindPlaces({...initialValues, cityId: cityId})
    } else {
      bindPlaces(initialValues)
    }
  }, [countryId, stateId, cityId, currentPage])
  const [filterValues, setFilterValues] = useState<any>(initialValues)
  const handleFilter = (e: any) => {
    debugger
    e.preventDefault()
    bindPlaces(filterValues)
  }
  const [states, setStates] = useState<[]>([])
  useEffect(() => {
    if (filterValues.countryId) {
      getStatesByCountry(filterValues.countryId)
        .then((res: any) => {
          if (res.data.status) {
            let data = res.data.data
            data.unshift({id: 0, name: 'Select State'})
            setStates(data)
          } else {
            setStates([])
          }
        })
        .catch((err: any) => {
          setStates([])
          console.log(err)
        })
    }
  }, [filterValues.countryId])
  const [cities, setCities] = useState<[]>([])
  useEffect(() => {
    if (filterValues.stateId) {
      getCitiesByState(filterValues.stateId)
        .then((res: any) => {
          if (res.data.status) {
            let data = res.data.data
            data.unshift({id: 0, name: 'Select City'})
            setCities(data)
          } else {
            setCities([])
          }
        })
        .catch((err: any) => {
          setCities([])
          console.log(err)
        })
    }
  }, [filterValues.stateId])
  const handleRefresh = () => {
    if (countryId) {
      bindPlaces({...initialValues, countryId: countryId})
    } else if (stateId) {
      bindPlaces({...initialValues, stateId: stateId})
    } else if (cityId) {
      bindPlaces({...initialValues, cityId: cityId})
    } else {
      bindPlaces(initialValues)
    }
  }

  const Status = [
    "Active",
    "InActive",
  ]
  const Featured = [
    {
      id: 0,
      name: "All",
    },
    {
      id: 1,
      name: "Featured",
    },
    {
      id: 2,
      name: "Not Featured",
    }
  ]
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Places</PageTitle>
      <div className='card card-custom gutter-b bg-secondary rounded'>
        <div className='card-header flex-wrap border-0 pt-6 pb-0 rounded'>
          <div className='card-title'>Places</div>
          <div className='card-toolbar rounded'>
            <Link to='/places/add' className='btn btn-primary font-weight-bolder'>
              <span className='svg-icon svg-icon-md'>
                {/*begin::Svg Icon | path:assets/media/svg/icons/Design/Flatten.svg*/}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  xmlnsXlink='http://www.w3.org/1999/xlink'
                  width='24px'
                  height='24px'
                  viewBox='0 0 24 24'
                  version='1.1'
                >
                  <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                    <polygon points='0 0 24 0 24 24 0 24' />
                    <path
                      d='M4,5 L20,5 C21.1045695,5 22,5.8954305 22,7 L22,19 C22,20.1045695 21.1045695,21 20,21 L4,21 C2.8954305,21 2,20.1045695 2,19 L2,7 C2,5.8954305 2.8954305,5 4,5 Z M4,7 L20,7 L20,19 L4,19 L4,7 Z'
                      fill='#000000'
                    />
                    <rect fill='#000000' opacity='0.3' x={11} y={13} width={2} height={8} rx={1} />
                    <rect fill='#000000' opacity='0.3' x={7} y={9} width={2} height={12} rx={1} />
                    <rect fill='#000000' opacity='0.3' x={15} y={17} width={2} height={4} rx={1} />
                  </g>
                </svg>
                {/*end::Svg Icon*/}
              </span>
              Add
            </Link>
            {/*end::Button*/}
          </div>
        </div>
        <div className='card-body rounded'>
          <div className='row justify-content-between'>
            <div className='col-md-3 my-2 my-md-0'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='countryId'
                  onChange={(e) => setFilterValues({...filterValues, countryId: e.target.value})}
                >
                  {countries.map((country: any) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-3 my-2 my-md-0'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='stateId'
                  onChange={(e) => setFilterValues({...filterValues, stateId: e.target.value})}
                >
                  {states.length === 0 && <option value={0}>Select State</option>}
                  {states.map((state: any) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-3 my-2 my-md-0'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='cityId'
                  onChange={(e) => setFilterValues({...filterValues, cityId: e.target.value})}
                >
                  {cities.length === 0 && <option value={0}>Select City</option>}
                  {cities.map((city: any) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='row justify-content-between'>
            <div className='col-md-3 my-2'>
              <div>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search...'
                  id='kt_datatable_search_query'
                  onChange={(e) => setFilterValues({...filterValues, filter: e.target.value})}
                />
                <span>
                  <i className='flat  icon2-search-1 text-muted' />
                </span>
              </div>
            </div>
            <div className='col-md-3 my-2'>
              <div className='row'>
                <DatePicker
                  placeholder='From Date'
                  includeTime={true}
                  max={new Date()}
                  onChange={(e) => setFilterValues({...filterValues, fromDate: e?.toISOString()})}
                />
              </div>
            </div>
            <div className='col-md-3 my-2'>
              <div className='row'>
                <DatePicker
                  placeholder='To Date'
                  includeTime={true}
                  max={new Date()}
                  onChange={(e) => setFilterValues({...filterValues, toDate: e?.toISOString()})}
                />
              </div>
            </div>
          </div>
          <div className='row justify-content-between'>
            <div className='col-md-3 my-2'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='isActive'
                  onChange={(e) => setFilterValues({...filterValues, isActive: e.target.value === "Active" ? true : false})}
                >
                  {Status.map((key: any) => {
                    return (
                      <option key={key} value={key}>
                        {key}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-3 my-2'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='isFeatured'
                  onChange={(e) => setFilterValues({...filterValues, featureType: e.target.value})}
                >
                  {Featured.map((key: any) => {
                    return (
                      <option key={key.id} value={key.id}>
                        {key.name}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
            <div className='col-md-3 my-2'>
              <div className='d-flex align-items-end justify-content-end'>
                <button
                  type='button'
                  className='btn  btn-sm btn-light-primary px-6 font-weight-bold'
                  onClick={handleFilter}
                >
                  Search &nbsp;
                  <i className='fas fa-search align-baseline' onClick={handleFilter} />
                </button>{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
      {loadingStages === LOADINGSTAGES.NEGATIVE && (
        <div className='p-2'>
          <ServerMessage message='No Data Found' />
        </div>
      )}
      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
      {loadingStages === LOADINGSTAGES.POSITIVE && (
        <Fragment>
          <PlaceTabel placePage={pagenation} className='mb-5 mb-xl-8' onRefresh={handleRefresh} />
          <div className='d-flex justify-content-end'>
            <Pagination
              className='pagination-bar'
              currentPage={pagenation.page.pageNumber}
              totalCount={pagenation.page.totalPages}
              pageSize={pagenation.page.size}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          </div>
        </Fragment>
      )}
    </>
  )
}
