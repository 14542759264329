import { useEffect, useState } from "react"
import { getTouristUsers, getWebUserById, getWebUsers, updateRoles } from "../../../../services/UsersService/UsersService"
import { LOADINGSTAGES } from "../../../../_shared/components/_laodSetups/LoadingSteps"

export const useUsersHooks = (params: any) => {
    const {values} = params;
    const [users, setUsers] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);

    const bindUsers = (values:any) => {
        setIsLoading(true);
        setLoadingStages(LOADINGSTAGES.LOADING);
        getWebUsers(values).then((res: any) => {
            if (res.data.status) {
                setLoadingStages(LOADINGSTAGES.POSITIVE);
                setUsers(res.data.data);
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
                setUsers([]);
            }
        }).catch((err: any) => {
            setLoadingStages(LOADINGSTAGES.ERROR);
            setUsers([]);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        bindUsers(values);
    }, [values]);

    return { users, isLoading, loadingStages }
}
export const useSingleUserHook = (params: any) => {
    const {id} = params
    const [user, setUser] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);
    const bindUser = (id:any) => {
        setIsLoading(true);
        setLoadingStages(LOADINGSTAGES.LOADING);
        getWebUserById(id).then((res: any) => {
            if (res.data.status) {
                setLoadingStages(LOADINGSTAGES.POSITIVE);
                setUser(res.data.data);
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
                setUser([]);
            }
        }).catch((err: any) => {
            setLoadingStages(LOADINGSTAGES.ERROR);
            setUser([]);
        }).finally(() => {
            setIsLoading(false);
        })
    }
    useEffect(() => {
        bindUser(id);
    }, [id]);

    return { user, isLoading, loadingStages }
}

export function useTUsersHooks (props:any){
    const {values} = props
    const [users, setUsers] = useState<any>([])
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);

    const bindUsers = (values:any) => {
        setIsLoading(true);
        setLoadingStages(LOADINGSTAGES.LOADING);
        getTouristUsers(values).then((res: any) => {
            if (res.data.status) {
                setLoadingStages(LOADINGSTAGES.POSITIVE);
                setUsers(res.data.data);
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
                setUsers([]);
            }
        }).catch((err: any) => {
            setLoadingStages(LOADINGSTAGES.ERROR);
            setUsers([]);
        }).finally(() => {
            setIsLoading(false);
        })
    }

    useEffect(() => {
        bindUsers(values);
    }, [values]);

    return { users, isLoading, loadingStages }}

export function updateRequestedRoles (userId:string,roles:string[]) {
    const returndata = {
        status: false,
        message: ""
    }
    return new Promise((resolve, reject) => {
        updateRoles(userId, roles).then((res: any) => {
            if (res.data.status) {
                returndata.status = true;
                returndata.message = res.data.message;
                resolve(returndata);
            }
            else {
                returndata.status = false;
                returndata.message = res.data.message;
                reject(returndata);
            }
        }).catch((err: any) => {
            returndata.status = false;
            returndata.message = err;
            reject(returndata);
        })
    })

}