import axios from 'axios'
import React, {useEffect, useState} from 'react'

export default function TestFile() {
  // hooks  study
  const [countries, setCountries] = React.useState([])
  const [states, setStates] = React.useState([])
  const [cities, setCities] = React.useState([])
  const [loading, setLoading] = React.useState(true)
  const [error, setError] = React.useState(null)
  const [countryId, setCountryId] = useState(0)
  const [stateId, setStateId] = useState(0)
  const [cityId, setCityId] = useState(0)

  const getAllCountries = () => {
    axios
      .get('http://developer.theaudiotours.com/api/common/GetAllCountries')
      .then((response) => {
        setCountries(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getStatesByCountryId = (countryId: number) => {
    axios
      .get(`http://developer.theaudiotours.com/api/common/GetAllStatesByCountryId/${countryId}`)
      .then((response) => {
        setCities([])
        setStates(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  const getCitiesByStateId = (stateId: number) => {
    axios
      .get('http://developer.theaudiotours.com/api/common/GetAllCitiesByStateId/' + stateId)
      .then((response) => {
        setCities(response.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  useEffect(() => {
    getAllCountries()
  }, [])
  useEffect(() => {
   if(countryId>0) getStatesByCountryId(countryId);
  }, [countryId]);
  useEffect(() => {
    if(stateId>0) getCitiesByStateId(stateId);
  }, [stateId]);

  const [show, setShow] = useState(false)
  return (
    <div>
      <h1>Test File</h1>
      <div className='form-group'>
        <label htmlFor='country'>Country</label>
        <select
          className='form-control'
          id='country'
          value={countryId}
          onChange={(e) => {
            setCountryId(parseInt(e.target.value))
          }}
        >
          <option value='0'>Select Country</option>
          {countries.map((country: any) => {
            return (
              <option key={country.id} value={country.id}>
                {country.name}
              </option>
            )
          })}
        </select>
      </div>
      <div className='form-group'>
        <label htmlFor='state'>State</label>
        <select
          className='form-control'
          id='state'
          onChange={(e) => {
            setStateId(parseInt(e.target.value))
            getCitiesByStateId(parseInt(e.target.value))
          }}
        >
          <option value='0'>Select State</option>
          {states.map((state: any) => {
            return (
              <option key={state.id} value={state.id}>
                {state.name}
              </option>
            )
          })}
        </select>
      </div>
      <div className='form-group'>
        <label htmlFor='city'>City</label>
        <select
          className='form-control'
          id='city'
          onChange={(e) => {
            setCityId(parseInt(e.target.value))
          }}
        >
          <option value='0'>Select City</option>
          {cities.map((city: any) => {
            return (
              <option key={city.id} value={city.id}>
                {city.name}
              </option>
            )
          })}
        </select>
      </div>
      <div className='container'>{show && <h1>hello</h1>}</div>
    </div>
  )
}
