import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import {useAuth} from './core/Auth'
import { getAuth, removeAuth } from './core/AuthHelpers'

export function Logout() {
  const {logout} = useAuth()
  useEffect(() => {
    logout()
    document.location.reload()
  }, [logout])

  return (
    <Routes>
      <Navigate to='/auth/login' />
    </Routes>
  )
}

export function logout() {
  return <Logout />
}
export function autoLogout() {
  const auth = getAuth();
  if (auth) {
    const expiresAt = auth.expireTime;
    if (expiresAt && Date.now() > expiresAt) {
      removeAuth();
      logout();
    }
  }
}