export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}

export interface IKittyModel {
  id: number;
  groupName: string;
  maxMembers: number;
  startDate: string;
  tenureInMonths: number;
  drawDate: string | null;
  monthlyInstalment: number | null;
}

export interface ICustomerModel {
  id: number;
  customerCode: string;
  fullName: string;
  gstin: string;
  address: string;
  city: string;
  stateId: number;
  postalCode: string;
  email: string;
  contactNo: string;
  alternateNo: string;
}

export interface IRecurringDepositModel {
  id: number;
  startPaymentDate: string;
  amount: number | null;
  monthlyInstallment: number | null;
  maturityAmount: number | null;
  productId: number | null;
  isActive: boolean;
  interestRate: number | null;
  tenureInMonths: number | null;
  nominee: string;
}

export interface IFixedDepositModel {
  id: number;
  startDate: string;
  amount: number | null;
  tenureInMonths: number | null;
  productID: number | null;
  isActive: boolean;
  maturityAmount: number | null;
  memberId: number | null;
  interestRate: number | null;
  nominee: string;
}

export interface ICommissionModel {
  id: number;
  minAmount: string;
  maxAmount: string;
  percentage: number | null;
  commissionAmount: number | null;
}

export interface IUpdateEmail {
  newEmail: string
  confirmPassword: string
}

export interface IUpdatePassword {
  currentPassword: string
  newPassword: string
  passwordConfirmation: string
}

export interface IConnectedAccounts {
  google: boolean
  github: boolean
  stack: boolean
}

export interface IEmailPreferences {
  successfulPayments: boolean
  payouts: boolean
  freeCollections: boolean
  customerPaymentDispute: boolean
  refundAlert: boolean
  invoicePayments: boolean
  webhookAPIEndpoints: boolean
}

export interface INotifications {
  notifications: {
    email: boolean
    phone: boolean
  }
  billingUpdates: {
    email: boolean
    phone: boolean
  }
  newTeamMembers: {
    email: boolean
    phone: boolean
  }
  completeProjects: {
    email: boolean
    phone: boolean
  }
  newsletters: {
    email: boolean
    phone: boolean
  }
}

export interface IDeactivateAccount {
  confirm: boolean
}

export interface IMemberAssignModel {
  kittyId: number;
  memberIds: number[];
}

export interface ICounterModel {
  memberCount: number;
  kittyCount: number;
  agentCount: number;
  rDMemberCount: number;
  fDMemberCount: number;
}

export const kittyModelInitValues: IKittyModel = {
  id: 0,
  groupName: '',
  maxMembers: 0,
  startDate: '',
  tenureInMonths: 0,
  drawDate: null,
  monthlyInstalment: 0
}

export const customerInitValues: ICustomerModel = {
  id: 0,
  customerCode: '',
  fullName: '',
  gstin: '',
  address: '',
  city: '',
  stateId: 92,
  postalCode: '',
  email: '',
  contactNo: '',
  alternateNo: ''
}

//IRecurringDepositModel
export const rdModelInitValues: IRecurringDepositModel = {
  id: 0,
  startPaymentDate: '',
  amount: null,
  monthlyInstallment: null,
  maturityAmount: null,
  productId: 0,
  isActive: true,
  interestRate: null,
  tenureInMonths: null,
  nominee: ''
}
//IFixedDepositModel
export const fdModelInitValues: IFixedDepositModel = {
  id: 0,
  startDate: '',
  amount: null,
  tenureInMonths: null,
  productID: 0,
  isActive: true,
  maturityAmount: null,
  memberId: null,
  interestRate: null,
  nominee: ''
}

//ICommissionModel
export const commModelInitValues: ICommissionModel = {
  id: 0,
  minAmount: '',
  maxAmount: '',
  percentage: null,
  commissionAmount: null
}


export const profileDetailsInitValues: IProfileDetails = {
  avatar: '/media/avatars/300-1.jpg',
  fName: 'Max',
  lName: 'Smith',
  company: 'The Audio Tours',
  contactPhone: '',
  companySite: 'theaudiotours.com',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}

export const updateEmail: IUpdateEmail = {
  newEmail: 'support@theaudiotours.com',
  confirmPassword: '',
}

export const updatePassword: IUpdatePassword = {
  currentPassword: '',
  newPassword: '',
  passwordConfirmation: '',
}

export const connectedAccounts: IConnectedAccounts = {
  google: true,
  github: true,
  stack: false,
}

export const emailPreferences: IEmailPreferences = {
  successfulPayments: false,
  payouts: true,
  freeCollections: false,
  customerPaymentDispute: true,
  refundAlert: false,
  invoicePayments: true,
  webhookAPIEndpoints: false,
}

export const notifications: INotifications = {
  notifications: {
    email: true,
    phone: true,
  },
  billingUpdates: {
    email: true,
    phone: true,
  },
  newTeamMembers: {
    email: true,
    phone: false,
  },
  completeProjects: {
    email: false,
    phone: true,
  },
  newsletters: {
    email: false,
    phone: false,
  },
}

export const deactivateAccount: IDeactivateAccount = {
  confirm: false,
}
