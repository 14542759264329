import { PageLink, PageTitle } from "../../../_metronic/layout/core"

export const breadCrumb = (props: any) => {
    const { title, links } = props;
    const crumb: Array<PageLink> = [
        {
            title: title,
            path: links,
            isSeparator: true,
            isActive: false,
        },
    ]
    return crumb;
}