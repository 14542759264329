import { addTourDescription, getDescriptionsByTourIdLangId, getTourdescriptionExist } from '../../../../services/TourServices/TourAudioService'
import { LOADINGSTAGES, ServerMessage } from '../../../../_shared/components/_laodSetups/LoadingSteps'
import { Loader } from '../../components/components'
import { FieldSelect, FieldTextAreaDescription } from '../../components/fields/TextField'
import { useEffect, useState } from 'react'
interface Props {
  audios: any
  formik: any
  isPlaying: boolean
  setIsPlaying: any
  updateDes: any
  isEdit: boolean
  handleClick: any
  tourId: any
  tourLanguages: any
  stage: number
  addDescription: any
  uploadSuccess: any
}

export const TourDescriptionPage = (props: Props) => {
  const {
    audios,
    formik,
    handleClick,
    isEdit,
    isPlaying,
    setIsPlaying,
    updateDes,
    addDescription,
    tourId,
    tourLanguages,
    stage,
    uploadSuccess,
  } = props
  const [descriptionExists, setDescriptionExists] = useState([]);
  const bindDescriptionExists = (tourId: any) => {
    getTourdescriptionExist(tourId).then((res: any) => {
      if (res.data.status) {
        setDescriptionExists(res.data.data);
      }
      else {
        // set default language as english
        formik.setFieldValue('tourLanguageId', 3);
        setDescriptionExists([]);
      }
    }
    ).catch((err: any) => {
      console.log(err);
    }
    )
  }
  useEffect(() => {
    bindDescriptionExists(tourId);
  }, [tourId, formik.values.tourLanguageId]);
  const isDisabled = (id: any) => {
    if (descriptionExists.length > 0) {
      // dontt disable any language if length is greater than 0
      return false;
    }
    else {
      // disable all languages except english
      if (id != 3) {
        return true;
      }
      else {
        return false;
      }
    }
  }
  return (
    <>
      <div className='row mb-7'>
        <div className='row mb-7'>
          <div className='card'>
            <FieldSelect
              className='col-lg-6 card-body'
              isRequired
              label='Choose Language'
              name='tourLanguageId'
              formik={formik}
            >
              <option value={0}> Select Language </option>
              {tourLanguages.map((item: any) => {
                return (
                  <option key={item.id} value={item.id} disabled={isDisabled(item.id)}>
                    {' '}
                    {item.name}{' '}
                  </option>
                )
              })}
            </FieldSelect>
          </div>
        </div>
      </div>
      {stage === LOADINGSTAGES.LOADING && <Loader />}
      {stage === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
      {stage === LOADINGSTAGES.NEGATIVE && (
        <div className='d-flex align-items-center justify-content-center my-3'>
          <div className='badge badge-white pb-3'>
            <ServerMessage message='No data found' />
          </div>
        </div>
      )}
      {stage === LOADINGSTAGES.POSITIVE && (
        <div className='row my-3'>
          <div className='row mb-7'>
            <div className='card'>
              <FieldTextAreaDescription
                className='col-lg-12 card-body'
                isRequired
                rows={4}
                tourId={tourId}
                audio={audios.find((a: any) => a.audioType === 'short')?.audioUrl}
                audioId={audios.find((a: any) => a.audioType === 'short')?.id}
                descriptionId={audios.find((a: any) => a.audioType === 'short')?.tourDescriptionId}
                label='Short Description'
                name='shortDescription'
                formik={formik}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                onClick={() => setIsPlaying(!isPlaying)}
                audioType='short'
                uploadSuccess={uploadSuccess}
              />
              <div className='d-flex p-1 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  disabled={
                    formik.errors.shortDescription || formik.errors.shortDescription ? true : false
                  }
                  onClick={() => {
                    updateDes(
                      tourId as any,
                      formik.values.tourLanguageId,
                      formik.values.shortDescription,
                      'short'
                    )
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-7'>
            <div className='card'>
              <FieldTextAreaDescription
                className='col-lg-12 card-body'
                isRequired
                rows={4}
                label='Medium Description'
                descriptionId={audios.find((a: any) => a.audioType === 'medium')?.tourDescriptionId}
                name='mediumDescription'
                audioId={audios.find((a: any) => a.audioType === 'medium')?.id}
                formik={formik}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                onClick={() => setIsPlaying(!isPlaying)}
                audio={audios.find((a: any) => a.audioType === 'medium')?.audioUrl}
                audioType='medium'
                tourId={tourId}
                uploadSuccess={uploadSuccess}
              />
              <div className='d-flex p-1 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={() => {
                    updateDes(
                      tourId as any,
                      formik.values.tourLanguageId,
                      formik.values.mediumDescription,
                      'medium'
                    )
                  }}
                  disabled={
                    formik.errors.mediumDescription || formik.errors.mediumDescription ? true : false
                  }
                >
                  Update
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-7'>
            <div className='card'>
              <FieldTextAreaDescription
                className='col-lg-12 card-body'
                isRequired
                rows={6}
                label='Long Description'
                name='longDescription'
                audioId={audios.find((a: any) => a.audioType === 'long')?.id}
                descriptionId={audios.find((a: any) => a.audioType === 'long')?.tourDescriptionId}
                formik={formik}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                onClick={() => setIsPlaying(!isPlaying)}
                audio={audios.find((a: any) => a.audioType === 'long')?.audioUrl}
                audioType='long'
                tourId={tourId}
                uploadSuccess={uploadSuccess}
              />
              <div className='d-flex p-1 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  disabled={
                    formik.errors.longDescription || formik.errors.longDescription ? true : false
                  }
                  onClick={() => {
                    updateDes(
                      tourId as any,
                      formik.values.tourLanguageId,
                      formik.values.longDescription,
                      'long'
                    )
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {stage === LOADINGSTAGES.NEGATIVE && (
        <div className='row my-3'>
          <div className='row mb-7'>
            <div className='card'>
              <FieldTextAreaDescription
                className='col-lg-12 card-body'
                isRequired
                rows={4}
                audio={audios.find((a: any) => a.audioType === 'short')?.audioUrl}
                audioId={audios.find((a: any) => a.audioType === 'short')?.id}
                descriptionId={audios.find((a: any) => a.audioType === 'short')?.tourDescriptionId}
                label='Short Description'
                name='shortDescription'
                formik={formik}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                onClick={() => handleClick('short')}
                audioType='short'
                tourId={tourId}
                uploadSuccess={uploadSuccess}
              />
              <div className='d-flex p-1 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  disabled={
                    formik.errors.shortDescription || formik.errors.shortDescription ? true : false
                  }
                  onClick={() => {
                    addDescription(
                      tourId as any,
                      formik.values.tourLanguageId,
                      formik.values.shortDescription,
                      'short'
                    )
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-7'>
            <div className='card'>
              <FieldTextAreaDescription
                className='col-lg-12 card-body'
                isRequired
                rows={4}
                label='Medium Description'
                descriptionId={audios.find((a: any) => a.audioType === 'medium')?.tourDescriptionId}
                name='mediumDescription'
                audioId={audios.find((a: any) => a.audioType === 'medium')?.id}
                formik={formik}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                onClick={() => handleClick('medium')}
                audio={audios.find((a: any) => a.audioType === 'medium')?.audioUrl}
                audioType='medium'
                tourId={tourId}
                uploadSuccess={uploadSuccess}
              />
              <div className='d-flex p-1 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={() => {
                    addDescription(
                      tourId as any,
                      formik.values.tourLanguageId,
                      formik.values.shortDescription,
                      'medium'
                    )
                  }}
                  disabled={
                    formik.errors.mediumDescription || formik.errors.mediumDescription
                      ? true
                      : false
                  }
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <div className='row mb-7'>
            <div className='card'>
              <FieldTextAreaDescription
                className='col-lg-12 card-body'
                isRequired
                rows={6}
                label='Long Description'
                name='longDescription'
                audioId={audios.find((a: any) => a.audioType === 'long')?.id}
                descriptionId={audios.find((a: any) => a.audioType === 'long')?.tourDescriptionId}
                formik={formik}
                isPlaying={isPlaying}
                setIsPlaying={setIsPlaying}
                onClick={() => handleClick('long')}
                audio={audios.find((a: any) => a.audioType === 'long')?.audioUrl}
                audioType='long'
                tourId={tourId}
                uploadSuccess={uploadSuccess}
              />
              <div className='d-flex p-1 justify-content-end'>
                <button
                  type='button'
                  className='btn btn-secondary btn-sm'
                  onClick={() => {
                    addDescription(
                      tourId as any,
                      formik.values.tourLanguageId,
                      formik.values.shortDescription,
                      'long'
                    )
                  }}
                  disabled={
                    formik.errors.mediumDescription || formik.errors.mediumDescription
                      ? true
                      : false
                  }
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
