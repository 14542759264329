import axiosInstance from '../services/AxiosInstance'
import {AUTH_LOCAL_STORAGE_KEY} from '../../src/app/modules/auth/core/AuthHelpers'

export function getPaymentModes() {
  return axiosInstance.get(`common/paymentMode`)
}

export function getCounters() {
  return axiosInstance.get(`shared/counters`)
}

export function getProducts() {
  return axiosInstance.get(`products`)
}

export function getCompanies() {
  return axiosInstance.get(`companies/all`)
}

export function getOrderStages() {
  return axiosInstance.get(`shared/orderStages`)
}
export function getStatesByCountry(countryId) {
  return axiosInstance.get(`common/GetAllStatesByCountryId/${countryId}`)
}
export function getAllCountries() {
  return axiosInstance.get(`common/GetAllCountries`)
}
export function getCitiesByState(stateId) {
  return axiosInstance.get(`common/GetAllCitiesByStateId/${stateId}`)
}
export function getAllStates(countryId, stateId, filter) {
  return axiosInstance.get(
    `common/GetAllStates?countryId=${countryId}&stateId=${stateId}&filter=${filter}`
  )
}
export function getAllCities(countryId, stateId, cityId, filter) {
  return axiosInstance.get(
    `common/GetAllCities?countryId=${countryId}&stateId=${stateId}&cityId=${cityId}&filter=${filter}`
  )
}
export function getAllFeatureTypes() {
  return axiosInstance.get(`common/feature-types`)
}
export function getTaxes(isAdditional = false) {
  return axiosInstance.get(`shared/taxes?isAdditional=${isAdditional}`)
}

export function getUnits() {
  return axiosInstance.get(`shared/units`)
}

export function deleteEntity(url) {
  if (url.includes('invoices/cancel')) {
    return axiosInstance.put(url)
  }
  return axiosInstance.delete(url)
}

export function getTerms() {
  return axiosInstance.get(`shared/terms`)
}

export function getNotes() {
  return axiosInstance.get(`shared/notes`)
}

export function changePassword(data) {
  return axiosInstance.post(`account/change-password`, data)
}
export function getAllTourStatus() {
  return axiosInstance.get(`common/tour-status`)
}

export function getCompanyCode() {
  let code = ''
  const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY))
  if (aware_user) {
    code = aware_user?.companyCode
  }
  return code
}

export function addCountry(data) {
  return axiosInstance.post(`common/countries`, data)
}
export function addState(data) {
  return axiosInstance.post(`common/states`, data)
}
export function addCity(data) {
  return axiosInstance.post(`common/cities`, data)
}
export function deleteCountry(id) {
  return axiosInstance.delete(`common/country/${id}`)
}
export function deleteState(id) {
  return axiosInstance.delete(`common/state/${id}`)
}
export function deleteCity(id) {
  return axiosInstance.delete(`common/city/${id}`)
}
