import * as Yup from 'yup'

export const CountrySchema = Yup.object().shape({
  name: Yup.string().required().min(2),
})
export const StateSchema = Yup.object().shape({
  countryId: Yup.number().integer().positive().required(),
  name: Yup.string().required().min(2),
})
export const CitySchema = Yup.object().shape({
  stateId: Yup.number().integer().positive().required(),
  name: Yup.string().required().min(2),
  countryId: Yup.number().integer().positive().required(),
})
