import { useEffect, useState } from 'react'
import { getAllCities, getAllCountries, getStatesByCountry } from '../../../../services/CommonService'
import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import {
  LoadData,
  LOADINGSTAGES,
  NoDataFound,
  ServerMessage,
} from '../../../../_shared/components/_laodSetups/LoadingSteps'
import CityCard from './CityCard'
import StateCard from './StateCard'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Cities',
    path: '/places/cities',
    isSeparator: true,
    isActive: false,
  },
]
export function ListCities(props: any) {
  const initialValues = {
    countryId: 0,
    stateId: 0,
    cityId: 0,
    filter: '',
  }
  // create class into Dto's
  // filer requests by stateId, countryId, cityId via props

  const [cities, setCities] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE)

  const bindCities = (values: any) => {
    setIsLoading(true)
    setLoadingStages(LOADINGSTAGES.LOADING)
    getAllCities(values.countryId, values.stateId, values.cityId, values.filter)
      .then((res: any) => {
        if (res.data.status) {
          setLoadingStages(LOADINGSTAGES.POSITIVE)
          setCities(res.data.data)
        } else {
          setLoadingStages(LOADINGSTAGES.NEGATIVE)
          setCities([])
        }
      })
      .catch((err: any) => {
        setLoadingStages(LOADINGSTAGES.ERROR)
        setCities([])
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  useEffect(() => {
    bindCities(initialValues)
  }, [])
  const [filterValues, setFilterValues] = useState<any>(initialValues)
  const [countries, setCountries] = useState<any>([])

  const bindCountries = () => {
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          res.data.data.unshift({ id: 0, name: 'All Countries' })
          setCountries(res.data.data)
        } else {
          setCountries([])
        }
      })
      .catch((err: any) => {
        setCountries([])
      })
  }
  const [states, setStates] = useState<any>([])
  const bindStates = (countryId: number) => {
    getStatesByCountry(countryId)
      .then((res: any) => {
        if (res.data.status) {
          res.data.data.unshift({ id: 0, name: 'All States' })
          setStates(res.data.data)
        } else {
          setStates([])
        }
      })
      .catch((err: any) => {
        setStates([])
      })
  }
  useEffect(() => {
    bindCountries()
  }, [])
  useEffect(() => {
    if (filterValues.countryId > 0) {
      bindStates(filterValues.countryId)
    }
  }, [filterValues.countryId])

  const handleSearch = () => {
    bindCities(filterValues)
  }
  const onRefresh = () => {
    bindCities(initialValues)
    setFilterValues(initialValues)
  }
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>Cities</PageTitle>
      <div className='card card-custom gutter-b bg-secondary rounded'>
      <div className="card-header flex-wrap border-0 pt-6 pb-0 rounded">
          <div className="card-title">
            Cities
          </div>
          <div className="card-toolbar">
          </div>
        </div>
        <div className='card-body rounded'>
          <div className='mb-7'>
            <div className='row align-items-center'>
              <div className='row justify-content-between'>
                <div className='col-md-3 my-2 my-md-0'>
                  <div className='input-icon'>
                    <input
                      type='text'
                      className='form-control'
                      name='filter'
                      onChange={(e) => setFilterValues({ ...filterValues, filter: e.target.value })}
                      placeholder='Search...'
                      id='kt_datatable_search_query'
                    />
                  </div>
                </div>
                <div className='col-md-3 my-2 my-md-0'>
                  <div className='d-flex align-items-center'>
                    <label className='mr-3 mb-0 d-none d-md-block'>Country:</label>
                    <select
                      className='form-control'
                      name='countryId'
                      placeholder='Select Country'
                      value={filterValues.countryId ?? ''}
                      onChange={(e) =>
                        setFilterValues({ ...filterValues, countryId: e.target.value })
                      }
                    >
                      <option value='' disabled hidden>
                        Select Country...
                      </option>
                      {countries.map((country: any) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-3 my-2 my-md-0'>
                  <div className='d-flex align-items-center'>
                    <label className='mr-3 mb-0 d-none d-md-block'>State:</label>
                    <select
                      className='form-control'
                      name='stateId'
                      placeholder='Select State'
                      value={filterValues.stateId ?? ''}
                      onChange={(e) => setFilterValues({ ...filterValues, stateId: e.target.value })}
                    >
                      <option value='' disabled hidden>
                        Select State...
                      </option>
                      {states.length === 0 && (<option value={0}>All States</option>)}
                      {states.map((state: any) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-2 my-2 my-md-0'>
                  <button
                    type='button'
                    className='btn btn-light-primary px-6 font-weight-bold'
                    onClick={handleSearch}
                  >
                    Search &nbsp;
                    <i className='fas fa-search align-baseline' onClick={handleSearch} />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadingStages === LOADINGSTAGES.POSITIVE && <CityCard cities={cities}
        onRefresh={onRefresh}
      />}
      {loadingStages === LOADINGSTAGES.LOADING && <LoadData isLoading={isLoading} />}
      {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No Data Found' />}
      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something Went Wrong' />}
    </>
  )
}
