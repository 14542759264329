import React from 'react'
import {Link} from 'react-router-dom'
import {toast} from 'react-toastify'
import {deleteCity} from '../../../../services/CommonService'
import {toastNotify} from '../../../../services/NotifyService'

export default function CityCard(props: any) {
  interface ICity {
    id: number
    name: string
    stateId: number
    stateName: string
    countryId: number
    countryName: string
    isActive: boolean
  }
  const {cities, onRefresh} = props

  const randomColors = ['#CBF0F4']
  const toggleActiveStatus = (id: any) => {
    const tId = toast.loading('Please wait...')
    deleteCity(id)
      .then((res) => {
        toastNotify(tId, res)
        if (res.data.status) {
          onRefresh()
        }
      })
      .catch(() => {
        const res = {
          data: {status: false, message: 'Something went wrong'},
        }
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }
  return (
    <div className='row pt-2'>
      {cities.map((city: ICity) => {
        return (
          <div className='col-lg-6 p-2' key={city.id}>
            <div
              className='card card-custom gutter-b'
              style={{
                backgroundColor: 'gainsboro',
              }}
            >
              <div className='d-flex justify-content-between align-items-center px-3 py-2'>
                <div>
                  <h3 className='card-title m-'>{city.name}</h3>
                  <div className='d-flex p-2'>
                    <span className='font-weight-bolder'>State: </span>
                    &nbsp;
                    <Link
                      to={`/places/state/${city.stateId}`}
                      className='text-dark-75 text-hover-primary font-size-lg font-weight-bolder mr-3'
                    >
                      {city.stateName}
                    </Link>
                    &nbsp;
                    <span className='font-weight-bolder'>Country: </span>
                    &nbsp;
                    <Link
                      to={`/places/country/${city.countryId}`}
                      className='text-dark-75 text-hover-primary font-size-lg font-weight-bolder'
                    >
                      {city.countryName}
                    </Link>
                  </div>
                </div>
                <div className='btn-group'>
                  <Link
                    to={`/places/city/${city.id}`}
                    className='btn btn-light btn-hover-primary btn-sm'
                  >
                    <i className='fas fa-eye'></i>
                    {" "}View
                  </Link>
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={() => toggleActiveStatus(city.id)}
                  >
                    {city.isActive ? 'Deactivate' : 'Activate'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
