/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import { FileUploadModal } from '../components/file_upload'
import FileUploadComponent from '../../../lib/FileUploadComponent'
import { ErrorMessage, Field } from 'formik'
import { UploadPlaceImage } from '../components/UploadPlaceImage'
type Props = {
    id: number,
    imagePath: string,
    placeName: string,
    onSuccess: any,
}

const PlaceHeader: React.FC<any> = (props: Props) => {
    const { id, imagePath, placeName, onSuccess } = props;
    const location = useLocation()
    const [isUpload, setIsUpload] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [pictureId, setPictureId] = useState(0);
    return (
        <div className='card mb-5 mb-xl-10'>
            <div className='card-body pt-9 pb-0'>
                <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                    <div className='me-7 mb-4'>
                        <div className='symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
                            <img src={imagePath} alt='placeImage' />
                            <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'></div>
                        </div>
                    </div>
                    <div className='flex-grow-1'>
                        <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
                            <div className='d-flex flex-column'>
                                <div className='d-flex align-items-center mb-2'>
                                    <a href='#' className='text-gray-800 text-hover-primary fs-2 fw-bolder me-1'>
                                        {placeName}
                                    </a>
                                </div>
                            </div>
                            <div className='d-flex my-4'>
                                <div className='d-flex align-items-center me-10'>
                                    {!isUpload ? (
                                        <button
                                            className='btn btn-sm btn-secondary'
                                            type="button"
                                            onClick={(e) => { e.preventDefault(); setIsUpload(true) }}
                                        >
                                            <KTSVG
                                                path='/media/icons/duotune/general/gen024.svg'
                                                className='svg-icon-3 svg-icon-primary me-1'
                                            />
                                            Update Logo
                                        </button>
                                    ) : (
                                        <div className='row'>

                                            <UploadPlaceImage
                                                forName='Upload Image'
                                                name='file'
                                                id={id}
                                                setShow={setIsUpload}
                                                onSuccessfullUpload={() => { setIsUpload(false); onSuccess() }}
                                                show={isUpload}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { PlaceHeader }
