import {FC, useState} from 'react'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {checkIsActive, KTSVG, WithChildren} from '../../../helpers'
import {useLayout} from '../../core'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasBullet?: boolean
}

const AsideMenuItem: FC<Props & WithChildren> = ({
  children,
  to,
  title,
  icon,
  fontIcon,
  hasBullet = false,
}) => {
  const {pathname} = useLocation()
  const isActive = checkIsActive(pathname, to)
  const {config} = useLayout()
  const {aside} = config
  const [isHover, setIsHover] = useState(false);
  return (
    <div className='menu-item'
    style={{
      // display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0.5rem 1rem',
      cursor: 'pointer',
    }}
    >
      <Link className={clsx('menu-link without-sub', {active: isActive})} to={to}
      style = {{
        // display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        // width: '100%',
        borderRadius: '0.5rem',
        // padding: '0.5rem 1rem',
      
      }}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}
        {icon && aside.menuIcon === 'svg' && (
          <span className='menu-icon'>
            <KTSVG path={icon} className='svg-icon-2' />
          </span>
        )}
        {fontIcon && aside.menuIcon === 'font' && <i className={clsx('bi fs-3', fontIcon)}></i>}
        {/* {// use text info on hover} */}
        <span className={clsx('menu-title', {active: isActive})} style={{color: isHover||isActive ? 'white' : 'black'}}
        >
          {title}
        </span>
      </Link>
      {children}
    </div>
  )
}

export {AsideMenuItem}
