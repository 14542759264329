import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toast} from 'react-toastify'
import * as Yup from 'yup'
import {getRequestRoles} from '../../../../../services/AuthServics'
import {
  getAllCountries,
  getCitiesByState,
  getStatesByCountry,
} from '../../../../../services/CommonService'
import {toastNotify} from '../../../../../services/NotifyService'
import {IRoleModel} from '../../../../../_models/IUserModel'
import {FieldSelect} from '../../../../pages/components/fields/TextField'
import {googleRegister, register} from '../../core/_requests'
export default function GoogleRegisteration() {
  const {userId} = useParams();
  const navigate = useNavigate()
  
  const initialValues = {
    stateId: 0,
    cityId: 0,
    countryId: 0,
    phone: '',
    acceptTerms: false,
    roles: [],
    userId: '',
  }
  const registrationSchema = Yup.object().shape({
    cityId: Yup.number().required('City is required').notOneOf([0], 'Select City'),
    stateId: Yup.number().required('State is required').notOneOf([0], 'Select State'),
    countryId: Yup.number().required('Country is required').notOneOf([0], 'Select Country'),
    phone: Yup.string()
      .matches(/^[0-9]+$/, 'Must be only digits')
      .min(10, 'Minimum 10 symbols')
      .max(10, 'Maximum 10 symbols'),
      // .required('Phone is required'),
    roles: Yup.array()
      .required('You must select at least one role')
      .min(1, 'You must select at least one role'),
  })
  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [roles, setRoles] = useState<IRoleModel[]>([])
  const bindRolesRequest = () => {
    getRequestRoles()
      .then((res) => {
        setInitialLoading(true)
        setRoles(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setInitialLoading(false)
      })
  }

  const [countries, setCountries] = useState<any>([])
  const bindCountries = () => {
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          let data = res.data.data
          // set default value
          data.unshift({id: 0, name: 'Select Country'})
          setCountries(data)
          console.log(data)
        } else {
          setCountries([])
        }
      })
      .catch(() => {
        setCountries([])
      })
  }
  const [states, setStates] = useState<any>([])
  const bindStates = (countryId: any) => {
    getStatesByCountry(countryId)
      .then((res: any) => {
        const array = res.data.data
        array.unshift({id: 0, name: 'Select State'})

        setStates(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
  const [cities, setCities] = useState<any>([])
  const bindCities = (stateId: any) => {
    getCitiesByState(stateId)
      .then((res: any) => {
        const array = res.data.data
        array.unshift({id: 0, name: 'Select City'})

        setCities(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      const id = toast.loading('Please wait...')
      if(!userId){
        return;
      }
      try {
        await googleRegister(
          values.roles,
          values.phone,
          values.stateId,
          values.cityId,
          values.countryId,
          values.userId = userId
        )
          .then((res: any) => {
            if(res.data.status){
           
            let _data = res.data.data
            toastNotify(id, res)
            if (_data) {
              setFormSubmitted(true)
            }
            navigate('/auth/login')
          }
          })
          .catch((err: any) => {
            const res = {data: {message: 'Something went wrong', success: false}}
            toastNotify(id, res)
            console.error(err)
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
            toast.dismiss(id)
          })
      } catch (error) {
        setStatus('Something went wrong. Please retry or contact admin.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    setStates([])
    setCities([])
    if (formik.values.countryId != 0) {
      bindStates(formik.values.countryId)
    }
  }, [formik.values.countryId])
  useEffect(() => {
    setCities([])
    // if page is edit page then dont set cityId to 0

    if (formik.values.stateId != 0) {
      bindCities(formik.values.stateId)
    }
  }, [formik.values.stateId])

  useEffect(() => {
    bindCountries()
    bindRolesRequest()
  }, [])
  return (
    <div>
      {' '}
      <form
        // change size of form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_signup_form'
        onSubmit={formik.handleSubmit}
      >
        {/* begin::Heading */}
        <div className='mb-10 text-center'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Create an Account</h1>
          {/* end::Title */}

          {/* begin::Link */}
          <div className='text-gray-400  fs-4'>
            <Link to='/auth/forgot-password' className='link-primary ' style={{marginLeft: '5px'}}>
              Forgot Password ?
            </Link>
          </div>
          {/* end::Link */}
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        )}
        <div className='row fv-row mb-7'>
          {/* begin::Form group Email */}
          <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
          <input
            placeholder='Phone Number'
            type='numeric'
            autoComplete='off'
            {...formik.getFieldProps('phone')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.phone && formik.errors.phone},
              {
                'is-valid': formik.touched.phone && !formik.errors.phone,
              }
            )}
          />
          {formik.touched.phone && formik.errors.phone && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.phone}</span>
              </div>
            </div>
          )}
          {/* end::Form group */}
        </div>
        <div className='row mb-7'>
          <FieldSelect
            className=''
            isRequired
            value
            label='Country Name'
            name='countryId'
            formik={formik}
          >
            {countries.map((item: any) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              )
            })}
          </FieldSelect>
        </div>

        <div className='row mb-7'>
          <FieldSelect
            className=''
            isRequired
            value
            label='State Name'
            name='stateId'
            formik={formik}
          >
            {states.map((item: any) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              )
            })}
          </FieldSelect>
        </div>
        <div className='row mb-7'>
          <FieldSelect
            className=''
            isRequired
            value
            label='City Name'
            name='cityId'
            formik={formik}
          >
            {cities.map((item: any) => {
              return (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              )
            })}
          </FieldSelect>
        </div>
        {!initialLoading && (
          <div className='row fv-row mb-10'>
            <label className='form-label fw-bolder text-dark fs-6'>Choose Role :</label>
            {roles.map((role, index: number) => (
              <div
                className='col-lg-4 col-md-4 form-check form-check-custom form-check-solid p-1'
                key={index}
              >
                <div>
                  <input
                    className='form-check-input bg-primary'
                    type='checkbox'
                    id='roles'
                    {...formik.getFieldProps('roles')}
                    value={role.id}
                  />
                  <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='role'>
                    {role.name}
                  </label>
                </div>
              </div>
            ))}
            {formik.touched.roles && formik.errors.roles && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.roles}</span>
                </div>
              </div>
            )}
          </div>
        )}
        {/* end::Form group */}
        {/* begin::Form group */}
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_up_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Submit</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_signup_form_cancel_button'
              className='btn btn-lg btn-light-primary w-100 mb-5'
            >
              Cancel
            </button>
          </Link>
        </div>
        {/* end::Form group */}
      </form>
    </div>
  )
}
