/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../services/NotifyService'
import { changePassword } from '../../../../services/CommonService'

const initialValues = {
    oldPassword: '',
    newPassword: '',
    changepassword: '',
}

const registrationSchema = Yup.object().shape({
    oldPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Enter old password'),
    newPassword: Yup.string()
        .min(3, 'Minimum 3 symbols')
        .max(50, 'Maximum 50 symbols')
        .required('Enter new password'),
    changepassword: Yup.string()
        .required('Password confirmation is required')
        .when('newPassword', {
            is: (val: string) => (val && val.length > 0 ? true : false),
            then: Yup.string().oneOf([Yup.ref('newPassword')], "Password and Confirm Password didn't match"),
        }),
})

export function PasswordChange() {


    const [loading, setLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues,
        validationSchema: registrationSchema,
        onSubmit: async (values, { setStatus, setSubmitting }) => {
            setLoading(true);
            //const id = toast.loading("Please wait...");

            try {
                await changePassword(
                    values
                ).then((res: any) => {
                    let _data = res.data;
                    if (_data.succcess) {
                        setFormSubmitted(true);
                    } else {
                        setStatus(_data.message)
                    }
                    //toastNotify(id, res);
                })
                    .catch((err: any) => {
                        const res = { data: { message: 'Something went wrong', success: false } };
                        //toastNotify(id, res);
                        console.error(err);
                        setLoading(false);
                    }).finally(() => {
                        setLoading(false);
                    });
            } catch (error) {
                setStatus('Something went wrong. Please retry or contact admin.')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap();
    }, [])

    return (
        <><div className='card mb-5 mb-xl-10'>
            <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
            >
                <div className='card-title m-0'>
                    <h3 className='fw-bolder m-0'>Change Password</h3>
                </div>
            </div>
            <div id='kt_account_profile_details' className='collapse show'>
                <form
                    className='form'
                    noValidate
                    id='kt_login_changepass_form'
                    onSubmit={formik.handleSubmit}
                >



                    <div className='card-body border-top p-9'>
                        {formik.status && (
                            <div className={`mb-lg-15 alert alert-${formik.status.includes('success') ? 'success' : 'danger'}`}>
                                <div className='alert-text font-weight-bold'>{formik.status}</div>
                            </div>
                        )}
                        {/* begin::Form group Old Password */}
                        <div className='row mb-6' data-kt-password-meter='true'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Old Password</label>
                            <div className='col-lg-6'>
                                <input
                                    type='password'
                                    placeholder='Old Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('oldPassword')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.oldPassword && formik.errors.oldPassword,
                                        },
                                        {
                                            'is-valid': formik.touched.oldPassword && !formik.errors.oldPassword,
                                        }
                                    )}
                                />
                                {formik.touched.oldPassword && formik.errors.oldPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.oldPassword}</span>
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group New Password */}
                        <div className='row mb-6' data-kt-password-meter='true'>

                            <label className='col-lg-4 col-form-label fw-bold fs-6'>New Password</label>
                            <div className='col-lg-6'>
                                <input
                                    type='password'
                                    placeholder='Password'
                                    autoComplete='off'
                                    {...formik.getFieldProps('newPassword')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.newPassword && formik.errors.newPassword,
                                        },
                                        {
                                            'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
                                        }
                                    )}
                                />
                                {formik.touched.newPassword && formik.errors.newPassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.newPassword}</span>
                                        </div>
                                    </div>
                                )}
                                {/* begin::Meter */}
                                <div
                                    className='d-flex align-items-center mb-3'
                                    data-kt-password-meter-control='highlight'
                                >
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                                    <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                                </div>
                                {/* end::Meter */}

                                <div className='text-muted'>
                                    Use 8 or more characters with a mix of letters, numbers &amp; symbols.
                                </div>
                            </div>

                        </div>
                        {/* end::Form group */}

                        {/* begin::Form group Confirm password */}
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>Confirm Password</label>
                            <div className='col-lg-6'>
                                <input
                                    type='password'
                                    placeholder='Password confirmation'
                                    autoComplete='off'
                                    {...formik.getFieldProps('changepassword')}
                                    className={clsx(
                                        'form-control form-control-lg form-control-solid',
                                        {
                                            'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                                        },
                                        {
                                            'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                                        }
                                    )}
                                />
                                {formik.touched.changepassword && formik.errors.changepassword && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>
                                            <span role='alert'>{formik.errors.changepassword}</span>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        {/* end::Form group */}
                    </div>
                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>
                            {!loading && 'Save Changes'}
                            {loading && (
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>


                </form>
            </div>
        </div>
        </>

    )
}
