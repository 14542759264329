import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { TableBody, TableHead } from "../components/Widgets/TableHead";

export const UserTable= (props: any) => {
    const { users } = props;
    const COLUMNS = [
        {Header: 'Name',accessor: 'name',}, {Header: 'Email',accessor: 'email',},
        {Header: 'Phone',accessor: 'phoneNumber',}, {Header: 'Country',accessor: 'countryName',},
        {Header: 'State',accessor: 'stateName',}, {Header: 'City',accessor: 'cityName',},
        {Header: 'Role',accessor: 'roleNames',}, {Header: 'Status',accessor: 'isApproved',},
        {Header: 'Action',accessor: 'action',},
    ];
    return (
        <div className="card-body p-2">
            <div className='table-responsive'>
                <Table>
                    <TableHead columns={COLUMNS} />
                    <TableBody columns={COLUMNS} data={users} />
                </Table>
            </div>
        </div>
    )
}