/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {toastNotify} from '../../../../services/NotifyService'
import MyToast from '../../../../lib/toast/mytoast'
import {GoogleSignInButton} from './helpers/google_sign_in'
import SignUpComponent from './helpers/SignUpComponent'
import {IUserAuthenticationModel} from '../../../interfaces/IUserAuth'
import {AuthModel} from '../core/_models'
import { WaitingCompontent } from './helpers/WaitingComp'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const LOADING_STATE = {
    IDLE: 'idle',
    PENDING: 'pending',
    RESOLVED: 'resolved',
    REJECTED: 'rejected',
  }
  const [loadingState, setLoadingState] = useState(LOADING_STATE.IDLE)
  const [res, setRes] = useState<any>(null)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      const id = toast.loading('Please wait...')
      setLoading(true)
      setLoadingState(LOADING_STATE.PENDING)
      try {
        await login(values.email, values.password)
          .then(async (res: any) => {
            if (res.data.status) {
              setLoadingState(LOADING_STATE.RESOLVED)
              toastNotify(id, res)
              saveAuth(res.data.data)
              setTimeout(() => {
                window.location.reload()
              }, 1000)
              setTimeout(async () => {
                const user = await getUserByToken(res.data.data.accessToken)
                setCurrentUser(user)
              }, 2000)
            } else {
              setRes(res.data)
              toastNotify(id, res)
              setLoadingState(LOADING_STATE.REJECTED)
            }
          })
          .catch((err: any) => {
            setLoadingState(LOADING_STATE.REJECTED)
            setRes(err)
            const res = {data: {status: false, message: 'Something went wrong !'}}
            toastNotify(id, res)
            console.error(err)
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
          })
      } catch (error) {
        console.error(error)

        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })
  const [showSignUp, setShowSignUp] = useState(false)
  const onGoogleClick = (bool: any) => {
    setShowSignUp(bool)
  }
  type IResponse = 'Invalid' | 'NotApproved' | 'Positive' | 'Unregistered' | 'Incomplete' | '';

  const [response, setResponse] = useState<IResponse>('')
  const [userId, setUserId] = useState<string>('')
  const [currentCase, setCurrentCase] = useState<IResponse>('')
  const onResponse = (data: any) => {
    if (data.currentCase === 'Positive') {
      setCurrentCase('Positive')
    }
    else if (data.currentCase === 'NotApproved') {
      setCurrentCase('NotApproved')
    }
    else if (data.currentCase === 'Incomplete') {
      setCurrentCase('Incomplete')
      setUserId(data.userId)
    }
    else if (data.currentCase === 'Unregistered') {
      setCurrentCase('Unregistered')
      setUserId(data.userId)
    }
  }
  const [isApproved, setIsApproved] = useState<boolean>(false)
  const onPositive = (data: IUserAuthenticationModel) => {
    setCurrentCase(data.currentCase)   
  }
  const onNotApproved = (data: IUserAuthenticationModel) => {
    setCurrentCase(data.currentCase)
  }
  return (
    <>
      {currentCase === 'Unregistered' ? <SignUpComponent userId={userId} /> : null}
      {currentCase === 'Incomplete' ? <SignUpComponent userId={userId} /> : null}
      {currentCase === 'NotApproved' ? <WaitingCompontent/> : null}
      {currentCase === '' ? (
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
        style={{filter: 'drop-shadow(0px 0px 40px rgba(68, 81, 96, 0.08))'}}
      >
        {/* begin::Heading */}
        <div className='text-center mb-10'>
          <h1 className='text-dark mb-3'>Sign In</h1>
          <div className='text-gray-400 fs-5'>
            New Here?{' '}
            <Link to='/auth/registration' className='link-primary'>
              Create an Account
            </Link>
          </div>
        </div>
        {/* begin::Heading */}

        {formik.status ? (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{formik.status}</div>
          </div>
        ) : (
          <></>
          // <div className='mb-10 bg-light-info p-8 rounded'>
          //   <div className='text-info'>
          //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
          //     continue.
          //   </div>
          // </div>
        )}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
          <input
            placeholder='Email'
            {...formik.getFieldProps('email')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.email && formik.errors.email},
              {'is-valid': formik.touched.email && !formik.errors.email}
            )}
            type='text'
            name='email'
            autoComplete='off'
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
             <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
        </div>
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='fv-row mb-10'>
          <div className='d-flex justify-content-between mt-n5'>
            <div className='d-flex flex-stack mb-2'>
              {/* begin::Label */}
              <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
              {/* end::Label */}
              {/* begin::Link */}
              <Link
                to='/auth/forgot-password'
                className='link-primary fs-6 fw-bolder'
                style={{marginLeft: '5px'}}
              >
                Forgot Password ?
              </Link>
              {/* end::Link */}
            </div>
          </div>
          <input
            type='password'
            placeholder='Password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
            className={clsx(
              'form-control form-control-lg form-control-solid',
              {'is-invalid': formik.touched.password && formik.errors.password},
              {'is-valid': formik.touched.password && !formik.errors.password}
            )}
          />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
        </div>
        <div className='text-center'>
          <button
            type='submit'
            id='kt_sign_in_submit'
            className='btn btn-lg btn-primary w-100 mb-5'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {!loading && <span className='indicator-label'>Continue</span>}
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Please wait...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        <div className='row mb-10 text-center'>
          {/* <i className='fab fa-google text-primary'></i> */}
          <GoogleSignInButton
            onGoogleClick={onGoogleClick}
            onResponse={onResponse}
            onNotApproved={onNotApproved}
            onPositive={onPositive}
          />
        </div>
        {/* end::Action */}
      </form>
      ): null}
    </>
  )
}
