import { useEffect, useState } from "react";
import { getAudiosByTourId } from "../../../../services/TourServices/TourAudioService";
import { LOADINGSTAGES } from "../../../../_shared/components/_laodSetups/LoadingSteps";

export const useTourAudio = (id: string) => {
    const [tourAudio, setTourAudio] = useState<any[]>([]);
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.LOADING);
    useEffect(() => {
        parseInt(id);
        setLoadingStages(LOADINGSTAGES.LOADING);
        getAudiosByTourId(id)
            .then((res: any) => {
                if (res.data.status) {
                    setLoadingStages(LOADINGSTAGES.POSITIVE);
                    setTourAudio(res.data.data);
                }
                else {
                    setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    setTourAudio([]);
                }
            }).catch((err: any) => {
                setLoadingStages(LOADINGSTAGES.ERROR);
                setTourAudio([]);
            })
    }, [id]);
    return { tourAudio, loadingStages };
}

