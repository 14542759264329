import React from 'react'
import {Link} from 'react-router-dom'
import {deleteState} from '../../../../services/CommonService'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../../services/NotifyService'

export default function StateCard(props: any) {
  interface IState {
    id: number
    name: string
    countryId: number
    countryName: string
    isActive: boolean
  }
  const {states, onRefresh} = props
  // filte all the countries and get the unique countries id and name
  const uniqueCountryName = states
    .map((state: IState) => {
      return {
        id: state.countryId,
        name: state.countryName,
      }
    })
    .filter(
      (value: any, index: any, self: any) => self.findIndex((t: any) => t.id === value.id) === index
    )

  const toggleActiveStatus = (id: any) => {
    const tId = toast.loading('Please wait...')
    deleteState(id)
      .then((res) => {
        toastNotify(tId, res)
        if (res.data.status) {
          onRefresh()
        }
      })
      .catch(() => {
        const res = {
          data: {status: false, message: 'Something went wrong'},
        }
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }

  return (
    <div>
      {uniqueCountryName.map((country: any) => {
        return (
          <div className='row p-2' key={country.id}>
            <div
              className='card card-custom gutter-b shadow'
              style={{
                backgroundColor: 'gainsboro',
              }}
            >
              <div className='card-header border-0 pt-5'>
                <h3 className='card-title align-items-start flex-column'>
                  <span className='card-label font-weight-bolder text-dark'>
                    {country.name}
                    &nbsp;
                    <i className='fa fa-globe'></i>
                  </span>
                </h3>
              </div>
              <div className='card-body'>
                <div className='row'>
                  {states
                    .filter((state: IState) => state.countryId === country.id)
                    .map((state: IState) => {
                      return (
                        <div className='col-lg-4 mb-3' key={state.id}>
                          <div className='card h-100 p-3 shadow-sm rounded'>
                            <div className='d-flex align-items-center justify-content-between'>
                              <div className='d-flex flex-column flex-grow-1'>
                                <Link
                                  to={`/places/state/${state.id}`}
                                  style={{fontSize: '1.2rem'}}
                                  className='text-dark-75 text-hover-info font-size-lg font-weight-bolder'
                                >
                                  {state.name}
                                </Link>
                              </div>
                              <button
                                className='btn btn-sm btn-primary'
                                onClick={() => toggleActiveStatus(state.id)}
                              >
                                {state.isActive ? 'Deactivate' : 'Activate'}
                              </button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </div>
  )
}
