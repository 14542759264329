import axiosInstance from '../AxiosInstance';

export const getJourneyByTourId = (tourId) => {
    return axiosInstance.get(`journey/tours/${tourId}`);
}
export const saveJourney = (data) => {
    return axiosInstance.post(`journey`, data);
}
export const updateJourney = (data) => {
    return axiosInstance.put(`journey`, data);
}
export const getJourneyById = (id) => {
    return axiosInstance.get(`journey/${id}`);
}
export const updateAction = (data) => {
    return axiosInstance.post(`journey/action`, data);
}