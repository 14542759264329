import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit, autoLogout } from './modules/auth'
import { ToastContainer, toast } from 'react-toastify';
import { GoogleOAuthProvider } from '@react-oauth/google'

const App = () => {
  useEffect(() => {
    autoLogout();
  }, [])
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <GoogleOAuthProvider clientId="597511507595-h3p0q0hen6bseounfqphp8hg15rif49k.apps.googleusercontent.com">
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </GoogleOAuthProvider>
        </LayoutProvider>
      </I18nProvider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
    </Suspense>
  )
}

export { App }
