import { useState } from "react";
import { toast } from "react-toastify";
import { toastNotify } from "../../../../services/NotifyService";
import { saveJourney } from "../../../../services/TourServices/JourneyService";
import { KTSVG } from "../../../../_metronic/helpers";

export const TourAcceptModal = (props: any) => {
    const { confirmModel, afterConfirm } = props;
    const { id, statusId, notes, assignerName } = confirmModel;
    const onCancel = () => {
        afterConfirm(false);
    }
    const onOk = () => {

        const tId = toast.loading("Please wait...");
        saveJourney(confirmModel)
            .then((res: any) => {
                if (res.data.status) {
                    debugger
                    toastNotify(tId, res);
                    afterConfirm(true);
                }
                else {
                    toastNotify(tId, res);
                }
            })
            .catch(() => {
                const res = { data: { message: 'Something went wrong', status: false } };
                toastNotify(tId, res);
            }).finally(() => {
                toast.dismiss(tId);
            });
    }
    return (
        <>
            <div
                className='modal fade show d-block'
                id='kt_modal_confiem_box'
                role='dialog'
                tabIndex={-1}
                aria-modal='true'
            >
                <div className='modal-dialog modal-md'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h2 className='fw-bolder'>Reject Tour</h2>
                            <div
                                className='btn btn-icon btn-sm btn-active-icon-primary'
                                data-kt-users-modal-action='close'
                                onClick={() => afterConfirm(false)}
                                style={{ cursor: 'pointer' }}
                            >
                                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                            </div>
                        </div>
                        <div className='modal-body'>
                            <div className="lh-lg">
                                <p>
                                    This tour will be marked as Accepted By PR
                                    and will be sent back to the <b>Admin</b> for further action.
                                </p>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button onClick={() => onCancel()}
                                type="button"
                                className="btn btn-light"
                                data-bs-dismiss="modal"
                            >
                                Cancel
                            </button>
                            <button onClick={() => onOk()} type="button" className="btn btn-info">
                                Accept
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className='modal-backdrop fade show'></div>
        </>

    )
}



// calculate the viewMessage using the function

export function viewTourMessage(statusId: number, authName: string, proofName: string, reviewName: string) {
    let viewMessage = ''
    // check if proofName is null 
    if (proofName === null) {
        proofName = 'Not Assigned';
    }
    if (statusId === 1) {
        viewMessage = `This tour is created by ${authName}`;
    }
    else if (statusId === 2) {
        viewMessage = `You have assigned this tour to ${proofName }, ${authName} is the writer of this tour`;
    }
    else if (statusId === 3) {
        viewMessage = `This tour is rejected by ${proofName}, ${authName} is the writer of this tour`;
    }
    else if (statusId === 4) {
        viewMessage = `You have assigned this tour to ${reviewName}, ${authName} is the writer of this tour`;
    }
    else if (statusId === 5) {
        viewMessage = `This tour is rejected by ${reviewName}, ${authName} is the writer of this tour`
    }
    else if (statusId === 6) {
        viewMessage = `This tour is approved by you, ${authName} is the writer of this tour`;
    }
    else if (statusId === 7) {
        viewMessage = `This tour is closed, ${authName} is the writer of this tour`
    }
    else if (statusId === 8) {
        viewMessage = `This tour is accepted by ${proofName}, ${authName} is the writer of this tour`
    }
    else if (statusId === 9) {
        viewMessage = `This tour is accepted by ${reviewName}, ${authName} is the writer of this tour`
    }
    else if (statusId === 10) {
        viewMessage = `This tour is rejected by you, ${authName} is the writer of this tour`
    }
    return viewMessage;
}   