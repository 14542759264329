import { Loader } from "../../../app/pages/components/components";

export const LOADINGSTAGES = {
    IDLE: 1,
    LOADING: 2,
    POSITIVE: 3,
    NEGATIVE: 4,
    ERROR: 5,
}

export function NoDataFound(props: any) {
    const { isLoading } = props;
    return (
        <div className='text-gray-600 fw-bold d-flex text-center w-100 align-content-center justify-content-center'>
            {isLoading ? "loading..." : "No matching records found"}
        </div>)
}
export function LoadData(props: any) {
    return (
        <div className='text-gray-600 fw-bold d-flex text-center w-100 align-content-center justify-content-center'>
            {props.isLoading ? <Loader /> : "No matching records found"}
        </div>
    )
}
export function ServerMessage(props: any) {
    return (
        <div className='text-danger fw-bold d-flex text-center w-100 align-content-center justify-content-center'>
            {props.message}
        </div>
    )
}
