import {useState} from 'react'
import {ElementStyleUtil} from '../../../_metronic/assets/ts/_utils'
const colors = ['#ddd', '#ffd580', '#fff']
interface Props {
  renderStep: any
  steps: any
  formik: any
  disabled: boolean
  isEdit: boolean
}
const Stepper = (props: Props) => {
  const {renderStep, steps, formik, disabled, isEdit} = props
  const [activeStep, setActiveStep] = useState(0)

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevStep) => prevStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevStep) => prevStep - 1)
    }
  }
  const handleClick = (index: number) => {
    switch (index) {
      case 0:
        setActiveStep(0)
        break
      case 1:
        if (isEdit) {
            setActiveStep(1)
        }
        break
      case 2:
        if (isEdit) {
          if (!disabled) {
          setActiveStep(2)
          }
        }
        break
      default:
        break
    }
  }

  return (
    <div style={{margin: '20px'}}>
      <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
        {steps.map((step: any, index: number) => (
          <div
            onClick={() => {
              handleClick(index)
            }}
            title={disabled && index != 0 ? 'Tour is not registered' : ''}
            key={index}
            style={{
              flex: 1,
              textAlign: 'center',
              padding: '10px',
              border: '1px solid #ddd',
              borderRadius: '5px',
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: index <= activeStep ? '100%' : '0%',
                height: '100%',
                background: `linear-gradient(to right, ${colors[1]}, ${colors[2]})`,
                transition: 'width 0.5s',
              }}
            ></div>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <i
                className={step.icon}
                style={{
                  fontSize: '24px',
                  color: index <= activeStep ? 'black' : 'gray',
                }}
              ></i>
              <span
                style={{
                  fontSize: '14px',
                  color: index <= activeStep ? 'black' : 'grey',
                }}
              >
                {step.label}
              </span>
            </div>
          </div>
        ))}
      </div>
      <div style={{margin: '20px 0'}}>
          <div className='row mx-5'>
            <div style={{margin: '20px 0'}}>{renderStep(activeStep)}</div>
        </div>
        <div className='row my-3'>
          <div className='card'>
            <div className='card-body'>
              <div style={{display: 'flex', justifyContent: 'center'}}>
                {activeStep > 0 && (
                  <button
                    type='button'
                    onClick={handleBack}
                    className='btn btn-sm btn-secondary ms-2'
                  >
                    Back
                  </button>
                )}
                {activeStep < steps.length - 1 && (
                  <button
                    type='button'
                    onClick={handleNext}
                    disabled={(activeStep === 1 && disabled) || (activeStep === 0 && !isEdit)}
                    className='btn-sm btn btn-light-primary ms-2'
                  >
                    Next
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Stepper
