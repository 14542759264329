import React, {useEffect, useState} from 'react'
import {
  addCity,
  getAllCountries,
  getAllStates,
  getStatesByCountry,
} from '../../../services/CommonService'
import {useFormik} from 'formik'
import {CitySchema} from './SettingsSchema'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'

export default function AddCities() {
  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const initialValues = {
    name: '',
    countryId: 0,
    stateId: 0,
  }

  const formik = useFormik({
    initialValues,
    validationSchema: CitySchema,
    onSubmit: (values) => saveCity(values),
  })

  useEffect(() => {
    getAllCountries().then((res) => {
      if (res.data.status) setCountries(res.data.data)
      else console.log(res.data.message)
    })
  }, [])

  const handleCountryChange = (event: any) => {
    formik.handleChange(event)
    const selectedCountryId = event.target.value
    getStatesByCountry(selectedCountryId).then((res) => {
      if (res.data.status) setStates(res.data.data)
      else console.log(res.data.message)
    })
  }
  const saveCity = (values: any) => {
    const tId = toast.loading('Please wait...')
    addCity(values)
      .then((res) => toastNotify(tId, res))
      .catch(() => {
        const res = {data: {status: false, message: 'Something went wrong'}}
        toastNotify(tId, res)
      })
      .finally(() => {
        formik.setSubmitting(false)
        toast.dismiss(tId)
      })
  }

  return (
    <div >
      <form onSubmit={formik.handleSubmit} noValidate>
        <div className='card'>
          <div className='card-header' role='button' data-bs-toggle='collapse' data-bs-target='#kt_settings_addCity_method'>
            <h3 className='card-title'>Add City</h3>
          </div>
          <div className='collapse-show' id='kt_settings_addCity_method'>
            <div className='card-body' id='kt_settings_addcity'>
              <div className='row'>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label>City Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter City Name'
                      name='name'
                      value={formik.values.name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className='text-danger'>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label>Country</label>
                    <select
                      className='form-control'
                      name='countryId'
                      value={formik.values.countryId}
                      onChange={handleCountryChange}
                    >
                      <option value='0'>Select Country</option>
                      {countries.map((country: any) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.countryId && formik.touched.countryId ? (
                      <div className='text-danger'>{formik.errors.countryId}</div>
                    ) : null}
                  </div>
                </div>
                <div className='col-md-4'>
                  <div className='form-group'>
                    <label>State</label>
                    <select
                      className='form-control'
                      name='stateId'
                      value={formik.values.stateId}
                      onChange={formik.handleChange}
                      disabled={states.length === 0}
                    >
                      <option value='0'>Select State</option>
                      {states.map((state: any) => (
                        <option key={state.id} value={state.id}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.stateId && formik.touched.stateId ? (
                      <div className='text-danger'>{formik.errors.stateId}</div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer' id='kt_settings_addcity'>
              <button type='submit' className='btn btn-sm btn-primary'>
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
