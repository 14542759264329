import axiosInstance, { axiosInstanceMultipart } from '../AxiosInstance';

export const uploadAudio = (data) => {
    return axiosInstanceMultipart.post(`touraudio/file`, data);
}
export const getAudiosByTourId = (tourId) => {
    return axiosInstance.get(`touraudio/tour/${tourId}`);
}
export const getAudioById = (id) => {
    return axiosInstance.get(`touraudio/${id}`);
}
export const updateAudio = (data) => {
    return axiosInstance.put(`touraudio`, data);
}
export const deleteAudio = (id) => {
    return axiosInstance.delete(`touraudio/delete/${id}`);
}   
export const getAudioByTourId = (tourId,languageId) => {
    return axiosInstance.get(`touraudio/tour/${tourId}/${languageId}`);
}
export const getDescriptionsByTourIdLangId = (tourId , languageId) => {
    return axiosInstance.get(`tourdescription/${tourId}/${languageId}`);
}
export const updateDescription = (descriptionId,tourId,languageId,description,type)=>{
    const data = { descriptionId,tourId,languageId,description,type };
    return axiosInstance.put(`tourdescription/description-update`, data);
}
export const addTourDescription = (tourId,languageId,description,type)=>{
    const data = { tourId,languageId,description,type };
    return axiosInstance.post(`tourdescription/description-add`, data);
}
export const getTourdescriptionById = (descriptionId) => {
    return axiosInstance.get(`tourdescription/${descriptionId}`);
}
export const getTourdescriptionByEnglish = (tourId) => {
    return axiosInstance.get(`tourdescription/tour/${tourId}`);
}

export const getTourdescriptionExist = (tourId) => {
    return axiosInstance.get(`tourdescription/tour/${tourId}/descriptions/languages`);
}