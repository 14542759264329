import axiosInstance from '../services/AxiosInstance'
import {AUTH_LOCAL_STORAGE_KEY} from '../../src/app/modules/auth/core/AuthHelpers'
import axios from 'axios'

export function addTourLocation(data) {
  return axiosInstance.post(`tourLocation`, data)
}
export function getAllLocations({
  cityId,
  stateId,
  countryId,
  isActive,
  languageId,
  pageSize,
  pageNumber,
  filter,
  fromDate,
  toDate,
  featureType
}) {
  return axiosInstance.get(`tourlocation?cityId=${cityId}&stateId=${stateId}&countryId=${countryId}&isActive=${isActive}
    &languageId=${languageId}&pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${filter}&fromDate=${fromDate}&toDate=${toDate}&featureType=${featureType}`)
}
export function getTourLocationById(id) {
  return axiosInstance.get(`tourlocation/${id}`)
}
export function updateTourLocation(data) {
  return axiosInstance.put(`tourlocation`, data)
}

export function getTourLocations() {
  return axiosInstance.get(`tourlocation/list`)
}
export function getLocationDetails(id) {
  return axiosInstance.get(`tourlocation/list-details/${id}`)
}
export function activeLocation(id) {
  return axiosInstance.put(`tourlocation/active/${id}`)
}

export function updateLocationFeature(id) {
  return axiosInstance.put(`tourlocation/update-feature/${id}`)
}