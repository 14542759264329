import * as map from '@react-google-maps/api'
import { addCordinates, getCordinates, getTourById } from '../../../../services/TourService'
import { useEffect, useRef, useState } from 'react'
import {
  FieldSelect,
  FieldTextArea,
  FieldTextAreaDescription,
  TextField,
} from '../../components/fields/TextField'
import { useFormik } from 'formik'
import * as yup from 'yup'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../services/NotifyService'
import { googleMapKey } from '../../../../services/BaseUrl'

export const TourMap = (props: any) => {
  const libraries: any = ['places']
  const { id, step, setIsTourCompleted, setTourName } = props
  const initialValues = {
    id: 0,
    name: '',
    notes: '',
    lat: 0,
    lng: 0,
    alertRadius: 0,
  }
  const Schema = yup.object().shape({
    name: yup.string().required('Name is Required'),
    lat: yup.number().required('Latitude is required').notOneOf([0], 'Enter valid Latitude')
      .test('lat', 'Enter valid Latitude', (value: any) => {
        if (value > 90 || value < -90) {
          return false
        }
        return true
      }),

    lng: yup.number().required('Longitude is required').notOneOf([0], 'Enter valid Longitude')
      .test('lng', 'Enter valid Longitude', (value: any) => {
        if (value > 180 || value < -180) {
          return false
        }
        return true
      }),
    alertRadius: yup.number().required('Alert Radius is required').notOneOf([0], 'Enter valid Alert Radius'),
  })
  const [data, setData] = useState(initialValues)
  const formik = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit(values: any, { setSubmitting }) {
      if (id) {
        values.id = id
      } else {
        values.id = 0
      }
      setSubmitting(true)
      savePrimaryInfo(values)
    },
  })
  const handleSubmit = (e: any) => {
    formik.handleSubmit()
  }
  const savePrimaryInfo = (values: any) => {
    const tLoading = toast.loading('Please wait...')
    addCordinates(values)
      .then((res) => {
        if (res.data.status) {
          const data = res.data.data
          if (data === 1) bindTour(id)
          else redirect(data)
          toastNotify(tLoading, res)
        } else {
          toastNotify(tLoading, res)
        }
      })
      .catch((err) => {
        const res = { data: { status: false, message: 'Something went wrong!' } }
        toastNotify(tLoading, res)
      })
      .finally(() => {
        toast.dismiss(tLoading)
      })
  }
  const redirect = (id: any) => {
    window.location.href = `/tours/edit/${id}`
  }

  const bindTour = (id: any) => {
    getCordinates(id)
      .then((res: any) => {
        if (res.data.status) {
          const isCompleted = res.data.data.isDetailCompleted
          setIsTourCompleted(isCompleted)
          setData(res.data.data)
          setTourName(res.data.data.name)
          const { lat, lng } = res.data.data
          if (lat && lng) setMarkerPosition({ lat, lng })
          setCenter({ lat, lng })
        } else {
          setIsTourCompleted(false)
          setData(initialValues)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }
  useEffect(() => {
    if (id) {
      bindTour(id)
    }
    setData(initialValues)
  }, [])

  const [search, setSearch] = useState('')
  const mapContainerStyle = {
    height: '400px',
    width: '100%',
  }
  const searchBoxRef = useRef<HTMLInputElement | null>(null)
  useEffect(() => {
    if (searchBoxRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(searchBoxRef.current)
      autocomplete.addListener('place_changed', onPlacesChanged)
    }
  }, [searchBoxRef])

  const API_KEY = googleMapKey as string;
  // const API_KEY = 'AIzaSyC7ORQ_Kd3YHOU-OotJK7YhLCr1s-9dMk4';
  const onLoad = (ref: null) => {
    searchBoxRef.current = ref
  }
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number
    lng: number
  }>({
    lat: formik.values.lat,
    lng: formik.values.lng,
  })
  const [center, setCenter] = useState({
    lat: -3.745,
    lng: -38.523,
  })
  const [showMap, setShowMap] = useState(false)
  const onPlacesChanged = () => {
    const autocomplete = new window.google.maps.places.Autocomplete(
      document.getElementById('search-box') as any
    )
    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace()
      if (place.geometry) {
        const lat = place.geometry.location?.lat() as number
        const lng = place.geometry.location?.lng() as number
        setCenter({ lat, lng })
        setMarkerPosition({ lat, lng })
        formik.setFieldValue('lat', lat)
        formik.setFieldValue('lng', lng)
      }
    })
  }
  return (
    <div key={step}>
      <div className='row mb-7'>
        <div className='card p-6'>
          <form method='post' id='map-form' noValidate>
            <div className='row mb-7'>
              <TextField className='col-lg-4' isRequired label='Name' name='name' formik={formik} />
              <FieldTextArea className='col-lg-8' name='notes' label='Notes' formik={formik} />
            </div>
            <div className='row mb-7'>
              <TextField className='col-lg-4' label='Lat' name='lat' formik={formik} />
              <TextField className='col-lg-4' label='Lng' name='lng' formik={formik} />
              <div className='col-lg-4'>
                <TextField className='row' label={<div>
                  Alert Radius <i className='fa fa-info-circle' data-bs-toggle='tooltip' data-bs-placement='top' title='Alert Radius in meters'></i>
                </div>}
                  name='alertRadius' formik={formik} />
              </div>
            </div>
            <div className='d-flex justify-content-end mb-5'>
              <button
                className='btn btn-sm btn-primary mx-5'
                type='button'
                onClick={() => setShowMap(!showMap)}
              >
                {showMap ? 'Hide Map' : 'Show Map'}
              </button>
              <button
                className='btn btn-sm btn-primary'
                disabled={!formik.isValid || !formik.isValid || formik.isSubmitting}
                onClick={(e) => {
                  handleSubmit(e)
                }}
                type='button'
              >
                Save
              </button>
            </div>
            {showMap && (
              <div className='row my-2'>
                <map.LoadScript
                  googleMapsApiKey={API_KEY}
                  libraries={libraries}
                >
                  <map.Autocomplete className='row' onPlaceChanged={onPlacesChanged}>
                    <div className='row justify-content-between my-5'>
                      <div className='col-lg-7'>
                        <input
                          name='search'
                          id='search-box'
                          className='form-control'
                          placeholder='Search Place'
                          value={search}
                          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setSearch(event.target.value)
                          }
                        />
                      </div>
                      <span className='badge badge-secondary me-5 col-lg-4 fs-6'>
                        {formik.values.lat}, {formik.values.lng}
                      </span>
                    </div>
                  </map.Autocomplete>
                  <map.GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    center={center}
                    zoom={14}
                    onLoad={(map: any) => {
                      onLoad(map)
                    }}
                  >
                    <map.Marker
                      position={{ lat: markerPosition.lat, lng: markerPosition.lng }}
                      draggable={true}
                      onDragEnd={(e: any) => {
                        const lat = e.latLng.lat()
                        const lng = e.latLng.lng()
                        setMarkerPosition({ lat, lng })
                        formik.setFieldValue('lat', lat)
                        formik.setFieldValue('lng', lng)
                      }}
                    />
                  </map.GoogleMap>
                </map.LoadScript>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  )
}
