import {Fragment, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {assignTourToLocation, getToursForLocation, removeTourFromLocation} from '../../../services/TourService'
import {
  getAllCountries,
  getCitiesByState,
  getStatesByCountry,
} from '../../../services/CommonService'
import {Loader} from '../components/components'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'
import {PageLink, PageTitle} from '../../../_metronic/layout/core'
import {LOADINGSTAGES, ServerMessage} from '../../../_shared/components/_laodSetups/LoadingSteps'
import './list.css'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Tours',
    path: '/places/tour/:id',
    isSeparator: true,
    isActive: false,
  },
]
export function ListToursForPlace() {
  const {id, countryId, stateId} = useParams() as any

  const initialValues = {
    cityId: 0,
    stateId: parseInt(stateId),
    countryId: parseInt(countryId),
    filter: '',
    pageNumber: 1,
    pageSize: 100,
    isFeatured: false,
    locationId: parseInt(id),
  }
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE)

  const [tours, setTours] = useState<any>([])
  const bindTourForLocation = (values: any) => {
    setLoadingStages(LOADINGSTAGES.LOADING)
    getToursForLocation(values)
      .then((res: any) => {
        if (res.data.status) {
          setTours(res.data.data)
          setLoadingStages(LOADINGSTAGES.POSITIVE)
        } else {
          setTours([])
          setLoadingStages(LOADINGSTAGES.NEGATIVE)
        }
      })
      .catch((err: any) => {
        console.log(err)
        setTours([])
        setLoadingStages(LOADINGSTAGES.ERROR)
      })
  }
  useEffect(() => {
    bindCountries()
    bindTourForLocation(initialValues)
  }, [])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [countries, setCountries] = useState<[]>([])
  const bindCountries = () => {
    setIsLoading(true)
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          let data = res.data.data
          data.unshift({id: 0, name: 'Select Country'})
          setCountries(data)
        } else {
          setCountries([])
        }
      })
      .catch((err: any) => {
        setCountries([])
        console.log(err)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  const [filterValues, setFilterValues] = useState<any>(initialValues)
  const handleFilter = (e: any) => {
    e.preventDefault()
    filterValues.countryId = parseInt(filterValues.countryId)
    filterValues.stateId = parseInt(filterValues.stateId)
    filterValues.cityId = parseInt(filterValues.cityId)
    setFilterValues(filterValues)
    bindTourForLocation(filterValues)
  }
  const [states, setStates] = useState<[]>([])
  useEffect(() => {
    if (filterValues.countryId) {
      getStatesByCountry(filterValues.countryId)
        .then((res: any) => {
          if (res.data.status) {
            let data = res.data.data
            data.unshift({id: 0, name: 'Select State'})
            setStates(data)
          } else {
            setStates([])
          }
        })
        .catch((err: any) => {
          setStates([])
          console.log(err)
        })
    }
  }, [filterValues.countryId])
  const [cities, setCities] = useState<[]>([])
  useEffect(() => {
    if (filterValues.stateId) {
      getCitiesByState(filterValues.stateId)
        .then((res: any) => {
          if (res.data.status) {
            let data = res.data.data
            data.unshift({id: 0, name: 'Select City'})
            setCities(data)
          } else {
            setCities([])
          }
        })
        .catch((err: any) => {
          setCities([])
          console.log(err)
        })
    }
  }, [filterValues.stateId])
  const handleRefresh = () => {
    bindTourForLocation(initialValues)
  }
  const handleAssignTour = (tourId: number) => {
    const tId = toast.loading('Assigning tour to location...')
    let data = {
      tourId: tourId,
      locationId: id,
      index : 0,
    }
    assignTourToLocation(data)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(tId, res)
          handleRefresh()
        } else {
          toastNotify(tId, res)
        }
      })
      .catch((err: any) => {
        const res = {status: false, message: 'Something went wrong'}
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }
  const unAssignTour = (tourId: number) => {
    const tId = toast.loading('Removing tour from location...')
    let data = {
      tourId: tourId,
      locationId: id,
    }
    removeTourFromLocation(data)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(tId, res)
          handleRefresh()
        } else {
          toastNotify(tId, res)
        }
      })
      .catch((err: any) => {
        const res = {status: false, message: 'Something went wrong'}
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }
  const [isEditing, setIsEditing] = useState(false)
  const [indexObj, setIndexObj] = useState<any>({
    tourId: 0,
    index: 0,
  })

  const handleEditClick = (tourId: number) => {
    setIsEditing(true)
    tours.forEach((item: any) => {
      if (item.id === tourId) {
        setIndexObj({tourId: tourId, index: item.index})
      }
    })
  }

  const handleSaveEdit = (tourId: number, index: number) => {
    // check if index is already assigned to any other tour
    let isAlreadyAssigned = false
    tours.forEach((item: any) => {
      if (item.id !== tourId && item.index === index) {
        isAlreadyAssigned = true
      }
    })
    if (isAlreadyAssigned) {
      const tId = toast.loading('Index is already assigned to another tour')
      const res = {status: false, data : {message: 'Index is already assigned to another tour'} }
      toastNotify(tId, res)
      setTimeout(() => {
        toast.dismiss(tId)
      }, 3000)
      return
    }
    const tId = toast.loading('Updating tour index...')
    let data = {
      tourId: tourId,
      index: index,
      locationId: id,
    }
    assignTourToLocation(data)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(tId, res)
          handleRefresh()
        } else {
          toastNotify(tId, res)
        }
      })
      .catch((err: any) => {
        const res = {status: false, message: 'Something went wrong'}
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
    setIsEditing(false)
  }
  const onIndexChange = (newIndex: number, tourId: any) => {
    let newTours = [...tours]
    newTours.forEach((item: any) => {
      if (item.id === tourId) {
        item.index = newIndex
      }
    })
    setTours(newTours)
  }
  const onCancelEdit = (tourId: number) => {
    setIsEditing(false)
    tours.forEach((item: any) => {
      if (item.id === tourId) {
        item.index = indexObj.index
      }
    })
  }
  return (
    <>
      {/* <PageTitle breadcrumbs={profileBreadCrumbs}>Tours</PageTitle> */}
      <div className='card card-custom gutter-b bg-secondary rounded'>
        <div className='card-body rounded'>
          <div className='row justify-content-between'>
            <div className='col-md-3 my-2 my-md-0'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  value={filterValues.countryId}
                  name='countryId'
                  onChange={(e) => setFilterValues({...filterValues, countryId: e.target.value})}
                >
                  {countries.map((country: any) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-3 my-2 my-md-0'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='stateId'
                  value={filterValues.stateId}
                  onChange={(e) => setFilterValues({...filterValues, stateId: e.target.value})}
                >
                  {states.map((state: any) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col-md-3 my-2 my-md-0'>
              <div className='d-flex align-items-center'>
                <select
                  className='form-control'
                  name='cityId'
                  value={filterValues.cityId}
                  onChange={(e) => setFilterValues({...filterValues, cityId: e.target.value})}
                >
                  {cities.map((city: any) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className='row justify-content-between'>
            <div className='col-md-4 my-2'>
              <div>
                <input
                  type='text'
                  className='form-control'
                  placeholder='Search...'
                  id='kt_datatable_search_query'
                  onChange={(e) => setFilterValues({...filterValues, filter: e.target.value})}
                />
                <span>
                  <i className='flaticon2-search-1 text-muted' />
                </span>
              </div>
            </div>
            <div className='col-md-2 my-2 justify-content-end'>
              <button
                type='button'
                className='btn  btn-sm btn-light-primary px-6 font-weight-bold'
                onClick={handleFilter}
              >
                Search &nbsp;
                <i className='fas fa-search align-baseline' onClick={handleFilter} />
              </button>
            </div>
          </div>
        </div>
        {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
        {loadingStages === LOADINGSTAGES.NEGATIVE && (
          <div className='p-2'>
            <ServerMessage message='No Data Found' />
          </div>
        )}
        {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
        {loadingStages === LOADINGSTAGES.POSITIVE && (
          <div className='card mb-5 mb-xl-8'>
            <div className='card-body py-3'>
              <table className='table table-fixed table-row-dashed table-row-gray-300 align-middle'>
                <thead>
                  <tr className='fixed-column table-info border-1'>
                    <th className='sticky-header table-info text-left ps-3'>Name</th>
                    <th className='text-left min-w-125px'>Author</th>
                    <th className='text-left min-w-125px'>Status</th>
                    <th className='text-left min-w-125px'>City</th>
                    <th className='text-left min-w-125px'>State</th>
                    <th className='text-left min-w-125px'>Country</th>
                    <th className='text-left min-w-125px'>Index </th>
                    <th className='text-left min-w-125px'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {tours?.map((item: any) => {
                    return (
                      <tr key={item.id}>
                        <td className='sticky-header text-left ps-3 text-bg-secondary text-info font-weight-bolder'>
                          <Link to={`/tours/edit/${item.id}`}>{item.tourName.toUpperCase()}</Link>
                        </td>
                        <td className='text-left'>
                          <div>
                            <span className='text-dark fw-semibold text-dark d-block fs-7'>
                              {item.author}
                            </span>
                          </div>
                        </td>
                        <td className='text-left'>
                          <div>
                            <span className='text-dark fw-semibold text-dark d-block fs-7'>
                              {item.statusName ?? 'N/A'}
                            </span>
                          </div>
                        </td>
                        <td className='text-left'>
                          <div>
                            <span className='text-dark fw-semibold text-dark d-block fs-7'>
                              {item.cityName}
                            </span>
                          </div>
                        </td>
                        <td className='text-left'>
                          <div>
                            <span className='text-dark fw-semibold text-dark d-block fs-7'>
                              {item.stateName}
                            </span>
                          </div>
                        </td>
                        <td className='text-left'>
                          <div>
                            <span className='text-dark fw-semibold text-dark d-block fs-7'>
                              {item.countryName}
                            </span>
                          </div>
                        </td>
                        <td className='text-left'>
                          {item.isAssigned && ( 
                          <div>
                            {isEditing && indexObj.tourId === item.id ? (
                              <>
                                <input
                                  type='number'
                                  value={item.index}
                                  min={1}
                                  onChange={(e) => onIndexChange(parseInt(e.target.value), item.id)}
                                  className='form-control mb-2'
                                />
                                <button
                                  type='button'
                                  className='btn btn-xs btn-success me-2'
                                  onClick={() => handleSaveEdit(item.id, item.index)}
                                >
                                  Save
                                </button>
                                <button
                                  type='button'
                                  className='btn btn-xs btn-secondary'
                                  onClick={() => onCancelEdit(item.id)}
                                >
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <>
                                <span className='text-dark fw-semibold text-dark d-block fs-7 mb-2'>
                                  {item.index}
                                </span>
                                <button
                                  type='button'
                                  className='btn btn-xs btn-light-primary font-weight-bold'
                                  onClick={() => handleEditClick(item.id)}
                                >
                                  Change Index
                                </button>
                              </>
                            )}
                          </div>
                          )}
                        </td>
                        <td className='text-left'>
                          <div>
                              <span className='text-dark fw-semibold text-dark d-block fs-7'>
                              <button
                                type='button'
                                className={
                                  item.isAssigned
                                    ? 'btn btn-sm btn-light-info font-weight-bold fs-5'
                                    : 'btn btn-sm btn-light-primary font-weight-bold'
                                }
                                onClick={() =>
                                  item.isAssigned
                                    ? unAssignTour(item.id)
                                    : handleAssignTour(item.id)
                                }
                              >
                                {item.isAssigned ? 'Remove' : 'Assign'}
                              </button>
                            </span>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </>
  )
}
