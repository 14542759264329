
type Props = {
    title : string
}
export const ListCard = (props: Props) => {
    const {title} = props
    return (
        <div className="card-header flex-wrap border-0 pb-0 rounded">
            <div className="card-title">
                <span className="d-block text-info pt-2 font-size-sm">{title}</span>
            </div>
        </div>
    )
}