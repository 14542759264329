import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PageTitle } from '../../../../_metronic/layout/core';
import { breadCrumb } from '../../components/BreadCrumb';
import { currentStage, useAssignees as prList, useAssignees as rVlist } from '../tourspartials/LIstComps';
import * as Yup from 'yup';
import { FieldSelect, FieldTextArea } from '../../components/fields/TextField';
import { LOADINGSTAGES, ServerMessage } from '../../../../_shared/components/_laodSetups/LoadingSteps';
import moment from 'moment';
import { Loader } from '../../components/components';
import { getJourneyByTourId, saveJourney, updateAction } from '../../../../services/TourServices/JourneyService';
import { KTSVG } from '../../../../_metronic/helpers';
import { currentUserId, currentUserRoles } from '../../../../services/AuthServics';
import { toast } from 'react-toastify';
import { TourRejectModel } from './TourRejectModel';
import { IConfirmModel, IRejectModel } from '../../../../_models/confirm-model';
import { toastNotify } from '../../../../services/NotifyService';
import { viewTourMessage } from './TourAcceptModel';
import { TRUE } from 'sass';

export default function JourneyCard(props: any) {
  const userId = currentUserId();

  const initialValues = {
    tourId: '',
    statusId: 0,
    notes: '',
    proofReaderId: '',
    reviewerId: '',
    isPass: false,
    senderRole: '',
    receiverRole: '',
    authorId: '',
  }

  const Schema = Yup.object().shape({
    notes: Yup.string(),
    statusId: Yup.number().required('Status is required'),
    proofReaderId: Yup.string(),
    // use check that reviewerId and proofReaderId cannot be same 
    reviewerId: Yup.string().required('Reviewer is required').test('checkReviewer', 'Reviewer and proofreader cannot be same', function (value) {
      if (value === this.parent.proofReaderId) {
        return false;
      }
      return true;
    }),
    authorId: Yup.string(),
    // if pass will be marked true if proofReaderId is empty or null
    // if proofReaderId is not empty or null then pass will be marked false
    isPass: Yup.boolean(),
  });
  const RejectionSchema = Yup.object().shape({
    notes: Yup.string().required('Please enter the reason for rejection'),
  });
  interface IJourney {
    assignerName: string;
    senderName: string;
    createdOn: string,
    statusId: number,
    statusName: string,
    notes: string,
    userId: string,
    createdBy: string,
    senderRole: string,
    receiverRole: string,
    proofReaderName: string,
    reviewerName: string,
    proofReaderId: string,
    reviewerId: string,
    adminId: string,
    adminName: string,
    authorId: string,
    authorName: string,
  }
  const [reload, setReload] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Schema, onSubmit: (values, { setSubmitting }) => {
      setSubmitting(true);
      if (values.proofReaderId === '' || values.proofReaderId === null || values.proofReaderId === undefined ||
        values.proofReaderId === 'null' || values.proofReaderId === 'undefined') {
        values.isPass = true;
      }
      else {
        values.isPass = false;
      }
      console.log(values);
      const toastId = toast.loading('Please wait...');
      saveJourney(values).then((res) => {
        if (res.data.status) {
          toastNotify(toastId, res);
          // render useEffect again
          setReload(!reload);
        }
        else {
          toastNotify(toastId, res);
        }
      }).catch((err) => {
        const res = { data: { message: 'Something went wrong', status: false } };
        toastNotify(toastId, res);
      }).finally(() => {
        toast.dismiss(toastId);
        setSubmitting(false);
      })
    }
  });

  const { id } = useParams();

  const [tourId, setTourId] = useState(id);
  const [journey, setJourney] = useState<IJourney[]>();
  const [loadingStages, setLoadingStages] = useState<any>(LOADINGSTAGES.IDLE);

  // 
  const [isAdmin, setIsAdmin] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isReviewer, setIsReviewer] = useState(false);
  const [isProofReader, setIsProofReader] = useState(false);
  const [viewMessage, setViewMessage] = useState<string>("");
  const [authorId, setAuthorId] = useState<string>("");
  const [statusId, setStatusId] = useState(0);

  // use effect to get the journey by tourId
  useEffect(() => {
    let isAuthor = roles.includes("Author");
    let isAdm = roles.includes("Admin");
    let isReview = roles.includes("Reviewer");
    let isProof = roles.includes("ProofReader");
    setLoadingStages(LOADINGSTAGES.LOADING);
    getJourneyByTourId(id).then((res) => {
      if (res.data.status) {
        setJourney(res.data.data);
        // filter all the userIds from the journey
        let userIds: any = res.data.data.map((item: any) =>
          item.createdBy || item.authorId || item.adminId || item.proofReaderId || item.reviewerId)
        // remove duplicate userIds
        let uniqueIds = userIds.filter((item: any, index: any) => userIds.indexOf(item) === index);
        // set the userIds in the state
        setUserIds(uniqueIds);
        setLoadingStages(LOADINGSTAGES.POSITIVE);
        formik.setFieldValue('tourId', id);
        const statusId = res.data.data[0].statusId;
        let authId = res.data.data[0].authorId;
        let proofId = res.data.data[0].proofReaderId;
        let reviewId = res.data.data[0].reviewerId;
        let adminId = res.data.data[0].adminId;
        let authName = res.data.data[0].authorName;
        let proofName = res.data.data[0].proofReaderName;
        let reviewName = res.data.data[0].reviewerName;
        let adminName = res.data.data[0].adminName;
        setAuthorId(authId);
        formik.setFieldValue('tourId', tourId);
        formik.setFieldValue('authorId', authorId);
        setStatusId(statusId);
        if (isAdm && adminId === userId) {
          if (statusId === 1 || statusId === 9) {
            setIsAdmin(true);
            formik.setFieldValue('senderRole', 'Admin');
          }
          let msg = viewTourMessage(statusId, authName, proofName, reviewName);
          setViewMessage(msg);
        }
        else if (isProof && proofId === userId) {
          if (statusId === 2) {
            setIsProofReader(true);
            setViewMessage(`This tour is assigned to you, ${authName} is the writer of this tour`);
          }
          else {
            setViewMessage('This tour was assigned to you, but it is not in the proofreading stage')
          }

        }
        else if (isReview && reviewId === userId) {
          setIsReviewer(true);
          if (statusId === 9) {
            setViewMessage(`You accepted this tour, ${authName} is the writer of this tour, ${proofName} is the proofreader of this tour`);
          } else {
            let apendMsg = "";
            if (proofName === null) {
              apendMsg = `This tour is assigned to you, ${authName} is the writer of this tour and no proofreader is assigned to this tour`;
            }
            else {
              apendMsg = `This tour is assigned to you, ${authName} is the writer of this tour, ${proofName} is the proofreader of this tour`;
            }
            setViewMessage(apendMsg);
          }
        }
        else if (isAuthor && authId === userId) {
          setIsAuthor(true);
          let apendMsg = "";
          if (statusId === 5) {
            if (proofName === null) {
              apendMsg = `This tour is created by you, no proofreader is assigned to this tour,
                ${reviewName} is rejected your tour, please make the changes.`;
            }
            else {
              apendMsg = `This tour is created by you, ${proofName} is the proofreader of this tour,
                ${reviewName} is rejected your tour, please make the changes.`;
            }
          }
          else {
            if (proofName === null && reviewName === null) {
              apendMsg = `This tour is created by you, no proofreader and reviewer is assigned to this tour`;
            }
            else if (proofName === null) {
              apendMsg = `This tour is created by you, no proofreader is assigned to this tour, ${reviewName} is the reviewer of this tour`;
            }
            else if (reviewName === null) {
              apendMsg = `This tour is created by you, no reviewer is assigned to this tour, ${proofName} is the proofreader of this tour`;
            }
          }
          setViewMessage(apendMsg);
        }
        else {
          setViewMessage(`This tour is creatad by ${authName}, ${proofName ? proofName : "no proofreader"} is the proofreader of this tour,
           ${reviewName} is the reviewer of this tour, ${adminName} is the admin of this tour`);
        }
      }
      else { setLoadingStages(LOADINGSTAGES.NEGATIVE); }
    }).catch((err) => {
      setLoadingStages(LOADINGSTAGES.ERROR);
    })
  }, [tourId, reload]);

  const roles = currentUserRoles();

  const [userIds, setUserIds] = useState<any[]>([]);
  const [rev, setRev] = useState<string>("Reviewer");
  // const [proofReaderList, setProofReaderList] = useState<any[]>([]);
  // const [reviewerList, setReviewerList] = useState<any[]>([]);
  // pass two assignees array and userIds array
  const { loadingStages: assignee2LoadingStages, assignees: reviewerList } = rVlist("Reviewer", userIds);
  const { loadingStages: assigneeLoadingStages, assignees: proofReaderList } = prList("ProofReader", userIds);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [confirmModel, setConfirmModel] = useState<IRejectModel>();
  const [notes, setNotes] = useState<string>("");
  const openDeleteModal = (id: any) => {
    if (id > 0) {
      let senderRole = "";
      let receiverRole = "";
      let statusId = 0;
      if (isAdmin) {
        senderRole = "Admin";
        if (statusId === 9) {
          receiverRole = "ProofReader";
          statusId = 10;

        }
        else {
          statusId = 7;
        }
      }
      else if (isAuthor) {
        senderRole = "Author";

      }
      else if (isReviewer) {
        senderRole = "Reviewer";
      }
      else if (isProofReader) {
        senderRole = "ProofReader";
      }
      const confirmModel: IRejectModel = {
        tourId: tourId as any,
        isAccept: false,
        senderRole: senderRole,
        receiverRole: receiverRole,
        notes: "",
        statusId: statusId,
        authorId: '',
        proofreaderId: '',
        reviewerId: '',
        authorName: '',
        proofreaderName: '',
        reviewerName: '',
        adminId: '',
        adminName: '',
        senderUserId: userId,
      }
      setConfirmModel(confirmModel);
      setTimeout(() => {
        setShowConfirm(true)
      }, 500);
    } else {

    }
  }
  const afterConfirm = (res: any) => {
    setShowConfirm(false);
  }
  const [submitting, setSubmitting] = useState<boolean>(false);
  const acceptRequest = (id: any) => {
    setSubmitting(true);
    interface IAcceptModel {
      tourId: number;
      isAccept: boolean;
      senderRole: string;
      receiverRole: string;
      senderUserId: string;
      notes: string;
      statusId: number;
    }
    if (id > 0) {
      let statusId = 0;
      let senderRole = "";
      let receiverRole = "";
      if (isProofReader) {
        senderRole = "ProofReader";
        receiverRole = "Reviewer";
        statusId = 4;
      }
      else if (isReviewer) {
        statusId = 9
        senderRole = "Reviewer";
        receiverRole = "Admin";
      }
      else if (isAdmin) {
        statusId = 6;
        senderRole = "Admin";
        receiverRole = "Author";
      }
      const confirmModel: IAcceptModel = {
        tourId: parseInt(tourId as string),
        isAccept: true,
        senderRole: senderRole,
        receiverRole: receiverRole,
        senderUserId: userId,
        notes: "",
        statusId: statusId,
      }
      const toastId = toast.loading("Please wait...");
      updateAction(confirmModel).then((res: any) => {
        if (res.data.status) {
          toastNotify(toastId, res);
          setReload(!reload);
        } else { toastNotify(toastId, res); }
      }).catch((err) => {
        console.log(err);
        const res = { data: { message: 'Something went wrong', status: false } };
        toastNotify(toastId, res);
      }).finally(() => {
        toast.dismiss(toastId);
      });
    }
  }
  const rejectRequest = (id: any) => {
    interface IAcceptModel {
      tourId: number;
      isAccept: boolean;
      senderRole: string;
      receiverRole: string;
      senderUserId: string;
      notes: string;
      statusId: number;
    }
    if (id > 0) {
      let statusId = 0;
      let senderRole = "";
      let receiverRole = "";
      if (isProofReader) {
        senderRole = "ProofReader";
        receiverRole = "Reviewer";
        statusId = 4;
      }
      else if (isReviewer) {
        statusId = 9
        senderRole = "Reviewer";
        receiverRole = "Admin";
      }
      else if (isAdmin) {
        statusId = 6;
        senderRole = "Admin";
        receiverRole = "Author";
      }
      const confirmModel: IAcceptModel = {
        tourId: parseInt(tourId as string),
        isAccept: true,
        senderRole: senderRole,
        receiverRole: receiverRole,
        senderUserId: userId,
        notes: "",
        statusId: statusId,
      }
      const toastId = toast.loading("Please wait...");
      updateAction(confirmModel).then((res: any) => {
        if (res.data.status) {
          toastNotify(toastId, res);
          setReload(!reload);
        } else { toastNotify(toastId, res); }
      }).catch((err) => {
        const res = { data: { message: 'Something went wrong', status: false } };
        toastNotify(toastId, res);
      }).finally(() => {
        toast.dismiss(toastId);
        setSubmitting(false);
      });
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={breadCrumb({ title: "Tours", links: "/journey" })} >
        Tour Workflow
      </PageTitle>
      <div className='card'>
        {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
        {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message="Error occured" />}
        {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message="No data found" />}
        {loadingStages === LOADINGSTAGES.POSITIVE &&
          <form className="card card-custom gutter-b" onSubmit={formik.handleSubmit}>
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Journey</h3>
              </div>
              {((isAdmin && statusId === 9) || (isReviewer && statusId === 4) ||
                (isProofReader && statusId === 2)) &&
                <div className="card-toolbar">
                  <div className='btn-group'>
                    <button type='button'
                      className='btn btn-primary mr-2'
                      disabled={submitting}
                      onClick={() => acceptRequest(tourId)}>
                      Accept
                    </button>
                    <button type='button' className='btn btn-secondary'
                      onClick={() => openDeleteModal(1)}>
                      <KTSVG path='/media/icons/duotune/general/gen044.svg'
                        className='svg-icon-2 svg-icon-warning mr-2' />
                      Reject
                    </button>
                  </div>
                </div>}
              {isAdmin && statusId === 1 &&
                <div className='card-toolbar'>
                  <div className='btn-group'>
                    <button type='submit' className='btn btn-primary my-2'
                      disabled={!formik.isValid && formik.isSubmitting}
                    >
                      {formik.isSubmitting && <span className="spinner-border spinner-border-sm align-middle mr-2"></span>}
                      Assign
                    </button>
                    <button type='button' className='btn btn-secondary my-2'
                      onClick={() => openDeleteModal(2)}>
                      Reject
                    </button>
                  </div>
                </div>}
            </div>
            <div>
              <div className='card-body border-top p-9'>
                <div className='text-center'>
                  <h3 className='badge badge-light-primary font-weight-bolder font-size-h4 py-4 px-4 mb-4'>
                    {viewMessage}
                  </h3>
                </div>
                {isAdmin && statusId !== 9 &&
                  <div className='row mb-7'>
                    <FieldSelect className='col-lg-6'
                      label='Proof Reader'
                      name='proofReaderId' formik={formik}
                    >
                      <option value="">Select Proof Reader </option>
                      {proofReaderList.map((item: any) => {
                        return <option key={item.userId} value={item.userId}>{item.name}</option>
                      })}
                      {assigneeLoadingStages === LOADINGSTAGES.LOADING && <option value=''>Loading...</option>}
                    </FieldSelect>
                    <FieldSelect className='col-lg-6' isRequired
                      label='Reviewer'
                      name='reviewerId' formik={formik}
                    >
                      <option value="">Select User</option>
                      {reviewerList.map((item: any) => {
                        return <option key={item.userId} value={item.userId}>{item.name}</option>
                      })}
                      {assigneeLoadingStages === LOADINGSTAGES.LOADING && <option value=''>Loading...</option>}
                    </FieldSelect>
                    <div className='row mb-7 justify-content-center py-2'>
                      <FieldTextArea className='col-lg-8' isRequired
                        label='Notes' name='notes' formik={formik}
                      />
                      {/* <>
                        <label className='col-lg-4 align-self-center form-check form-check-custom form-check-solid align-items-start'>
                          <input
                            className='form-check-input me-3'
                            type='checkbox'
                            name='isPass'
                            defaultChecked={formik.values.proofReaderId === "" ? true : false}
                          />
                          <span className='form-check-label d-flex flex-column align-items-start'>
                            <span className='fs-6 text-gray-400'>If no proof reader is assigned</span>
                            <br />
                            <span className='fs-6 text-gray-400'>then the tour will be directly assigned to the reviewer</span>
                          </span>
                        </label>
                      </> */}
                    </div>
                  </div>}
              </div>
            </div>
            {journey?.map((item, index) => {
              return (
                <div className='card-body p-9' key={index}>
                  <div className='notice d-flex bg-light-info rounded border-warning border border-dashed mb-9 p-6'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen010.svg'
                      className='svg-icon-2tx svg-icon-warning me-4'
                    />
                    <div className='d-flex flex-stack flex-grow-1'>
                      <div className='fw-bold'>
                        <>
                          <SpanComponent item={item} />
                          <div className='container'>
                            <span className='fw-semibold text-info'>Notes:&nbsp;{item.notes}</span>
                          </div>
                        </>
                      </div>
                      <div className='d-flex justify-content-end'>
                        <div className='fs-6 text-gray-600'>
                          <span className='fw-bolder'>Dated On:</span> {moment(item.createdOn).format('DD/MM/YYYY, h:mm a')}
                          <br />
                          {item.statusId === 1 || item.statusId === 2 || item.statusId === 4
                            || item.statusId === 8 || item.statusId === 9 ?
                            <span className='badge badge-primary'>{item.statusName}</span> :
                            item.statusId === 3 || item.statusId === 5 || item.statusId === 10 ?
                              <span className='badge badge-danger'>{item.statusName}</span> :
                              item.statusId === 6 &&
                              <span className='badge badge-success'> {item.statusName}</span>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })}
          </form>}
        {showConfirm && <TourRejectModel confirmModel={confirmModel} afterConfirm={afterConfirm} />}
      </div>
    </>
  )
}

const SpanComponent = (props: any) => {
  const { item } = props
  return (
    <>
      <div className='container py-0'>
        {item.senderRole === 'Admin' &&
          <span className='text text-info'>
            {item.senderRole} {item.adminName}
          </span>}
        {item.senderRole === 'ProofReader' &&
          <span className='text text-info'>
            {item.senderRole} {item.proofReaderName}
          </span>}
        {item.senderRole === 'Reviewer' &&
          <span className='text text-info'>
            {item.senderRole} {item.reviewerName}
          </span>}
        {item.senderRole === 'Author' &&
          <span className='text text-info'>
            {item.senderRole} {item.authorName}
          </span>}
        <br />
        {item.receiverRole === 'Admin' &&
          <span className='text text-info'>
            {item.receiverRole} {item.adminName}
          </span>}
        {item.receiverRole === 'ProofReader' &&
          <span className='text text-info'>
            {item.receiverRole} {item.proofReaderName}
          </span>}
        {item.receiverRole === 'Reviewer' &&
          <span className='text text-info'>
            {item.receiverRole} {item.reviewerName}
          </span>}
        {item.receiverRole === 'Author' &&
          <span className='text text-info'>
            {item.receiverRole} {item.authorName}
          </span>}
      </div>

    </>
  )
}