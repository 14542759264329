import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../../../../_metronic/helpers'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { ICompanyDetails, companyInitValues as initialValues} from '../../../../../../_models/company-model'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../../../services/NotifyService'
import {getStatesByCountry as  getStates } from '../../../../../../services/CommonService'

const profileDetailsSchema = Yup.object().shape({
  companyName: Yup.string().required('First name is required'),
  addressLine1: Yup.string().required('Last name is required'),
  addressLine2: Yup.string().required('Company name is required'),
  city: Yup.string().required('Contact phone is required'),
  postalCode: Yup.string().required('Company site is required'),
  alternateNo: Yup.string().required('Country is required'),
  email: Yup.string().required('Language is required'),
  website: Yup.string().required('Time zone is required'),
  gstin: Yup.string().required('Currency is required'),
  stateId: Yup.string().required('Company site is required'),
  mobile: Yup.string().required('Country is required'),
})

const ProfileDetails: React.FC = () => {
  const [stateArr, setStateArr] = useState<any>([]);
  const [data, setData] = useState<ICompanyDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<ICompanyDetails>): void => {
      const updatedData = Object.assign(data, fieldsToUpdate)
      setData(updatedData)
  }

  const [loading, setLoading] = useState(false)
  const formik = useFormik<ICompanyDetails>({
      enableReinitialize: true,
      initialValues: data,
      validationSchema: profileDetailsSchema,
      onSubmit: (values, {resetForm}) => {
          const id = toast.loading("Please wait...");
          setLoading(true)
          setTimeout(() => {
              const updatedData = Object.assign(data, values)
              setData(updatedData)
          }, 1000)
      },
  })

  const bindStates = (countryId: any) => {
      getStates(countryId).then((res: any) => {
          const array = res.data.data;
          setStateArr(array);
      }).catch((err: any) => {
          console.log(err);
      })
  }

  // const bindDetails = () => {
  //     setLoading(true);
  //     setTimeout(() => {
  //         getCompanyDetails()
  //             .then((res: any) => {
  //                 let _data = res.data.data;
  //                 console.log(_data);
  //                 setData(_data);
  //             })
  //             .catch(() => {

  //             }).finally(() => {
  //                 setLoading(false);
  //             });
  //     }, 500);
  // }

  useEffect(() => {
      bindStates(99);
      // bindDetails();
  }, []);

  return (
    <div className='card mb-5 mb-xl-10'>
      <div
        className='card-header border-0 cursor-pointer'
        role='button'
        data-bs-toggle='collapse'
        data-bs-target='#kt_account_profile_details'
        aria-expanded='true'
        aria-controls='kt_account_profile_details'
      >
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>Profile Details</h3>
        </div>
      </div>

      <div id='kt_account_profile_details' className='collapse show'>
        <form onSubmit={formik.handleSubmit} noValidate className='form'>
          <div className='card-body border-top p-9'>
            {/* 1 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>Logo</label>
              <div className='col-lg-8'>
                <div
                  className='image-input image-input-outline'
                  data-kt-image-input='true'
                  style={{ backgroundImage: `url(${toAbsoluteUrl('/media/avatars/blank.png')})` }}
                >
                  <div
                    className='image-input-wrapper w-125px h-125px'
                    style={{ backgroundImage: `url(${toAbsoluteUrl(data.logo)})` }}
                  ></div>
                </div>
              </div>
            </div>
            {/* 2 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Organization Name</label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Organization Name'
                  {...formik.getFieldProps('companyName')}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.companyName}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 3 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

              <div className='col-lg-8'>
                <div className='row'>
                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                      placeholder='Address line 1'
                      {...formik.getFieldProps('addressLine1')}
                    />
                    {formik.touched.addressLine1 && formik.errors.addressLine1 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.addressLine1}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-lg-6 fv-row'>
                    <input
                      type='text'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Address line 2'
                      {...formik.getFieldProps('addressLine2')}
                    />
                    {formik.touched.addressLine2 && formik.errors.addressLine2 && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.addressLine2}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* 4 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Contact mobile</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='tel'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Phone number'
                  {...formik.getFieldProps('mobile')}
                />
                {formik.touched.mobile && formik.errors.mobile && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.mobile}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 5 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Alternate no</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Alternate no'
                  {...formik.getFieldProps('alternateNo')}
                />
                {formik.touched.alternateNo && formik.errors.alternateNo && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.alternateNo}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 6 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>State</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <select
                  className='form-select form-select-solid form-select-lg fw-bold'
                  {...formik.getFieldProps('stateId')}
                >
                  {
                    stateArr && stateArr.map((stat: any) => (
                      <option value={stat.id} key={stat.id}>{stat.stateName}</option>
                    ))
                  }

                </select>
                {formik.touched.stateId && formik.errors.stateId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.stateId}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 7 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>City</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='City'
                  {...formik.getFieldProps('city')}
                />
                {formik.touched.city && formik.errors.city && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.city}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 8 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Postal Code</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Postal Code'
                  {...formik.getFieldProps('postalCode')}
                />
                {formik.touched.postalCode && formik.errors.postalCode && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.postalCode}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 9 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Email</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  {...formik.getFieldProps('email')}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.email}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 10 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>GST Number</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='GST Number'
                  {...formik.getFieldProps('gstin')}
                />
                {formik.touched.gstin && formik.errors.gstin && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.gstin}</div>
                  </div>
                )}
              </div>
            </div>
            {/* 11 */}
            <div className='row mb-6'>
              <label className='col-lg-4 col-form-label fw-bold fs-6'>
                <span className='required'>Website</span>
              </label>

              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Website'
                  {...formik.getFieldProps('website')}
                />
                {formik.touched.website && formik.errors.website && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.website}</div>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className='card-footer d-flex justify-content-end py-6 px-9'>
            <button type='submit' className='btn btn-primary' disabled={loading}>
              {!loading && 'Save Changes'}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  )
}

export { ProfileDetails }
