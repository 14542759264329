import { toast } from 'react-toastify'
import { toastNotify } from '../../../services/NotifyService'
import { useState } from 'react'
import { postPlaceImage, postTourImage } from '../../../services/UploadService'
import { Modal, ModalBody } from 'react-bootstrap'

type Props = {
  id: number
  name: string
  forName: string
  onSuccessfullUpload: any
  show: boolean
  setShow: any
  coverImageId:number
}
export const UploadTourImage = (props: Props) => {
  const { id, show, setShow,coverImageId } = props
  const uploadImage = (e: any) => {
    console.log(e);
    const formData = new FormData()
    console.log(file)
    formData.append('file', file)
    const tId = toast.loading('Uploading Image...')
    console.log(formData);
    postTourImage(id,coverImageId, formData)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(tId, res)
          props.onSuccessfullUpload(res.data.data)
          // setShow(false)
        } else {
          toastNotify(tId, res)
        }
      })
      .catch((err: any) => {
        const data = { data: { message: 'something went wrong' }, status: false }
        toastNotify(tId, data)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }
  // const passImage = (e: any) => {
  //     const file = e.files[0];
  //     const reader = new FileReader();
  //     reader.onload = (e) => setImage(e?.target?.result as string);
  //     reader.readAsDataURL(file);
  //     setShow(true);
  // }
  const [image, setImage] = useState('')
  const handleClose = () => setShow(false)
  const [file, setFile] = useState<any>();
  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    
    const { files } = event.target;
    const selectedFiles = files as FileList;
    setFile(selectedFiles?.[0]);
  };
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload File</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex justify-content-start align-items-start mx-2 mr-5 mb-5'>
          <div className='d-flex justify-content-start align-items-start'>
            <div className='col-lg-4 align-self-center mx-2'>
              <label htmlFor={props.id.toString()} className='form-label'>
                {props.forName}
              </label>
            </div>
            <input
              type='file'
              id={id.toString()}
              name={id.toString()}
              className='form-control form-control-sm'
              accept='image/png, image/jpeg'
              onChange={(e) => { onFileChange(e) }}
            />
          </div>
          <div className='d-flex justify-content-start align-items-start mx-2'>
            <button
              type='button'
              className='btn btn-primary btn-sm mx-2'
              // onClick={() => { passImage(document.getElementById(pros.id.toString()) as HTMLInputElement) }}
              onClick={(e) => {
                // console.log(document.getElementById(id.toString()))
                uploadImage(e)
              }}
            >
              Upload
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}
