import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTourAudio } from './tourAudioConsts';
import { PageTitle } from '../../../../_metronic/layout/core';
import { ListUiHeader } from '../../components/ListUiHeader';
import Slider from './components/slider/Slider'
import ControlPanel from './components/controls/ControlPanel'
const styles = {
    appContainer: {
        width: "500px",
        padding: "0 10px",
        backgroundColor: "#999999",
        borderRadius: "10px",
        boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.479)",
    },
};
export default function TourAudioList() {
    const { id } = useParams();
    const [tourId, setTourId] = useState<string>(id as string);
    const { tourAudio, loadingStages } = useTourAudio(tourId);
    const [audioList, setAudioList] = useState<any[]>(tourAudio);

    const styles = {
        appContainer: {
            width: "500px",
            padding: "0 10px",
            backgroundColor: "#272727",
            borderRadius: "10px",
            boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.479)",
        },
        appContainer2: {
            width: "500px",
            padding: "0 10px",
            // grey background
            backgroundColor: "#808080",
            borderRadius: "10px",
            boxShadow: "0px 5px 5px rgba(0, 0, 0, 0.479)",
        },
    };
    return (
        <div>
            <PageTitle>
                Audios
            </PageTitle>
            <ListUiHeader title={"Audios"} link={"/tour/audio/add/" + tourId}
                isList />
            <div className='separator-solid'></div>
            <div className='card-body p-1'>
                {tourAudio.map((x: any) =>
                    <div className="row">
                        <div className='col-lg-6 p-1'>
                            <div style={styles.appContainer2} className='p-1' >
                                <div className="row p-2">
                                    <div className='col-lg-6'>
                                        <span className="text-black fw-bold mb-1 fs-6">Audio Name</span>
                                        <br />
                                        <span className="text-black fw-bold mb-1 fs-6">{x.name}</span>
                                    </div>
                                    <div className='col-lg-6'>
                                        <span className="text-black fw-bold mb-1 fs-6">Audio Type</span>
                                        <br />
                                        <span className="text-black fw-bold mb-1 fs-6">{x.audioType}</span>
                                    </div>
                                </div>
                                <div className="row p-2">
                                    <div className='col-lg-6'>
                                        <span className="text-black fw-bold mb-1 fs-6">Audio Language</span>
                                        <br />
                                        <span className="text-black fw-bold mb-1 fs-6">{x.audioLanguage}</span>
                                    </div>
                                    <div className='col-lg-6'>
                                        <span className="text-black fw-bold mb-1 fs-6">Audio Duration</span>
                                        <br />
                                        <span className="text-black fw-bold mb-1 fs-6">{x.audioDuration}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 p-1">
                            <MusicPlayer key={x.id} src={x.audioUrl} />
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

interface Props {
    src: string;
}

interface State {
    currentTime: number;
    duration: number;
    isPlaying: boolean;
}


export const MusicPlayer = (props: any) => {
    const [percentage, setPercentage] = useState(0)
    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)

    const audioRef = useRef() as any

    const onChange = (e: any) => {
        const audio = audioRef.current as any
        audio.currentTime = (audio.duration / 100) * e.target.value
        setPercentage(e.target.value)
    }

    const play = () => {
        const audio = audioRef.current as any
        audio.volume = 0.1

        if (!isPlaying) {
            setIsPlaying(true)
            audio.play()
        }

        if (isPlaying) {
            setIsPlaying(false)
            audio.pause()
        }
    }

    const getCurrDuration = (e: any) => {
        const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(2)
        const time = e.currentTarget.currentTime

        setPercentage(+percent)
        setCurrentTime(time.toFixed(2))
    }

    return (
        <div style={styles.appContainer} className='p-1' >
            <Slider percentage={percentage} onChange={onChange} />
            <audio
                ref={audioRef}
                onTimeUpdate={getCurrDuration}
                onLoadedData={(e: any) => {
                    setDuration(e.currentTarget.duration.toFixed(2))
                }}
                src={props.src}
            ></audio>
            <ControlPanel
                play={play}
                isPlaying={isPlaying}
                duration={duration}
                currentTime={currentTime}
            />
        </div>
    )
}






