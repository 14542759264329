/* eslint-disable react/jsx-no-target-blank */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../helpers'
import {AsideMenuItemWithSub} from './AsideMenuItemWithSub'
import {AsideMenuItem} from './AsideMenuItem'
import {AUTH_LOCAL_STORAGE_KEY} from '../../../../app/modules/auth/core/AuthHelpers'
import {AuthModel} from '../../../../app/modules/auth'

export function AsideMenuMain() {
  const intl = useIntl()
  const [userApps, setUserApps] = useState<any>([])

  const getAuth = (): AuthModel | undefined => {
    if (!localStorage) {
      return
    }

    const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
    if (!lsValue) {
      return
    }

    try {
      const auth: AuthModel = JSON.parse(lsValue) as AuthModel
      if (auth) {
        // You can easily check auth_token expiration also
        if (auth) {
        }
      }
    } catch (error) {
      console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
    }
  }

  useEffect(() => {
    getAuth()
  }, [])

  return (
    <>
      <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/general/gen001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <>
        <div className='menu-item'>
          <div className='menu-content pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Geo</span>
          </div>
        </div>
        <AsideMenuItem
          to='/locations'
          icon='/media/icons/duotune/general/gen018.svg'
          title={intl.formatMessage({id: 'MENU.PLACES'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/places/countries'
          icon='/media/icons/duotune/communication/com009.svg'
          title={intl.formatMessage({id: 'MENU.COUNTRIES'})}
          fontIcon='bi-app-indicator'
        />

        <AsideMenuItem
          to='/places/states'
          icon='/media/icons/duotune/general/gen017.svg'
          title={intl.formatMessage({id: 'MENU.STATES'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/places/cities'
          icon='/media/icons/duotune/general/gen054.svg'
          title={intl.formatMessage({id: 'MENU.CITIES'})}
          fontIcon='bi-app-indicator'
        />
        <div className='menu-item'>
          <div className='menu-content pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Tours</span>
          </div>
        </div>
        <AsideMenuItem
          to='/tours'
          icon='/media/icons/duotune/maps/map003.svg'
          title={intl.formatMessage({id: 'MENU.TOURS'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/featured-tours'
          icon='/media/icons/duotune/maps/map010.svg'
          title={intl.formatMessage({id: 'MENU.FEATURED_TOURS'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/my-tours'
          icon='/media/icons/duotune/general/gen008.svg'
          title={intl.formatMessage({id: 'MENU.MY_TOURS'})}
          fontIcon='bi-app-indicator'
        />
        <div className='menu-item'>
          <div className='menu-content pb-2'>
            <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Users</span>
          </div>
        </div>
        <AsideMenuItem
          to='/web-users'
          icon='/media/icons/duotune/general/gen011.svg'
          title={intl.formatMessage({id: 'MENU.WEB_USERS'})}
          fontIcon='bi-app-indicator'
        />
        <AsideMenuItem
          to='/tourist-users'
          icon='/media/icons/duotune/general/gen012.svg'
          title={intl.formatMessage({id: 'MENU.TOURISTS'})}
          fontIcon='bi-app-indicator'
        />
      </>
      <AsideMenuItemWithSub
        to=''
        title={intl.formatMessage({id: 'MENU.SETTINGS'})}
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/coding/cod001.svg'
      >
        <AsideMenuItem to='/account' title='Account' hasBullet={true} />
        {/* <AsideMenuItem to='/account/settings' title='General' hasBullet={true} /> */}
        <AsideMenuItem to = '/settings' title='Settings' hasBullet/>
        <AsideMenuItem to = '/feature-types' title='Feature Types' hasBullet/>
      </AsideMenuItemWithSub>

      <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={'/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Release notes {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
