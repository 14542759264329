import axiosInstance from '../AxiosInstance';

export const getWebUsers=(values)=>{
    const {cityId,stateId,countryId,pageSize,pageNumber,filter,isApproved}=values;
    return axiosInstance.get(`users/webusers?cityId=${cityId}&
    stateId=${stateId}&countryId=${countryId}&
    pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${filter}&isApproved=${isApproved}`);
}
export const getTouristUsers=(values)=>{
    const {cityId,stateId,countryId,pageSize,pageNumber,filter}=values;
    return axiosInstance.get(`users/tourist-users?cityId=${cityId}&
    stateId=${stateId}&countryId=${countryId}&pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${filter}`);
}
export const getWebUserById = (userId) => {
    return axiosInstance.get(`users/webusers/${userId}`);
}
export const updateRoles = (userId, roles) => {
    return axiosInstance.put(`users/update-User/${userId}`, roles);
}

export const getAssignees = (role,userIds) => {
    return axiosInstance.post(`users/assignees/${role}`,userIds);
}