import axiosInstance from '../AxiosInstance'

export const getFeatureTypes = () => {
    return axiosInstance.get('featureType')
}
export const getFeatureType = (id) => {
    return axiosInstance.get(`featureType/${id}`)
}
export const createFeatureType = (data) => {
    return axiosInstance.post('featureType', data)
}
export const updateFeatureType = (data) => {
    return axiosInstance.put(`featureType/update`, data)
}
export const changeFeatureTypeStatus = (id) => {
    return axiosInstance.put(`featureType/changeStatus/${id}`)
}