import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../services/NotifyService'
import { addTour, getCordinates, getTourById, updateTour } from '../../../services/TourService'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { LOADINGSTAGES, ServerMessage } from '../../../_shared/components/_laodSetups/LoadingSteps'
import {
  FieldSelect,
  FieldTextArea,
  FieldTextAreaDescription,
  TextField,
} from '../components/fields/TextField'
import { ItourDto, tourInitialValues } from './tourspartials/LIstComps'
import * as yup from 'yup'
import { getLocationDetails, getTourLocations } from '../../../services/TourLocationService'
import {
  getAllCountries,
  getAllFeatureTypes,
  getCitiesByState,
  getStatesByCountry,
} from '../../../services/CommonService'
import { Loader } from '../components/components'
import { currentUserId, currentUserRoles } from '../../../services/AuthServics'
import { getAllLanguages } from '../../../services/TourServices/TourLanguageService'
import {
  addTourDescription,
  getDescriptionsByTourIdLangId,
  updateDescription,
} from '../../../services/TourServices/TourAudioService'
import Stepper from './Stepper'
import { TourDescriptionPage } from './tourspartials/description'
import { TourMap } from './tourspartials/tourmap'
import { TourDetail } from './tourspartials/tourdetail'
import { TourHeader } from './TourHeader'

const profileBreadCrumbs: Array<PageLink> = [
  {
    title: 'Edit Tour',
    path: '/tours',
    isSeparator: true,
    isActive: true,
  },
]
const AddTourSchema = yup.object().shape({
  name: yup.string(), // .Required("Name is required"),
  // tourTypeId: yup.number(), // .Required("Tour Type is required"),
  tourLocationId: yup.number(), // .Required("Tour Location is required"),
  // coverImageId: yup.number(), // .Required("Cover Image is required"),
  shortDescription: yup
    .string()
    .min(10, 'Character length should be greater than 10')
    .max(100, 'Character length should be less than 100')
    .required('Short Description is required'),
  longDescription: yup
    .string()
    .min(10, 'Too Short!')
    .required('Long Description is required'),
  mediumDescription: yup
    .string()
    .min(10, 'Character length should be greater than 10')
    .max(100, 'Character length should be less than 100')
    .required('Medium Description is required'),
  tourLanguageId: yup
    .number()
    .test('valid-language', 'Please select a valid language', (value: any) => {
      return value != 0
    }),
  addressLine1: yup.string(),
  addressLine2: yup.string(),
  zipCode: yup.string(), // .Required("Zip Code is required"),
  // landmark: yup.string(), // .Required("Landmark is required"),
  alertRadius: yup.number(), // .Required("Alert Radius is required"),
  lat: yup.number(),
  lng: yup.number(),
  tourFeatures: yup.array(),
  cityId: yup.number().test('valid-city', 'Please select a valid City', (value: any) => {
    return value != 0
  }),
  stateId: yup.number().test('valid-state', 'Please select a valid State', (value: any) => {
    return value != 0
  }),
  countryId: yup.number().test('valid-country', 'Please select a valid Country', (value: any) => {
    return value != 0
  }),
})
interface IDescription {
  tourLanguageId: number
  id: number
}

export default function AddTour(props: any) {
  const roles = currentUserRoles()
  const meUserId = currentUserId()
  const { id } = useParams<{ id: string }>()
  const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE)
  const [isEdit, setIsEdit] = useState(false)
  const [values, setValues] = useState<ItourDto>(tourInitialValues)
  const [tourId, setTourId] = useState(id)
  const [descriptions, setDescriptions] = useState<IDescription[]>([])

  const [tourLanguages, setTourLanguages] = useState([])

  const bindTourLanguages = () => {
    getAllLanguages()
      .then((res) => {
        if (res.data.status) {
          setTourLanguages(res.data.data)
        }
      })
      .catch((err) => {
        setTourLanguages([])
      })
  }
  // bind tour
  useEffect(() => {
    bindLocations()
    bindTourLanguages()
    if (id) {
      setIsEdit(true)
      // bindTour(id);
    } else {
      setIsEdit(false)
      setLoadingStages(LOADINGSTAGES.POSITIVE)
    }
  }, [])

  // bind locations
  const [locations, setLocations] = useState([])
  const bindLocations = () => {
    getTourLocations()
      .then((res) => {
        if (res.data.status) {
          setLocations(res.data.data)
        }
      })
      .catch((err) => {
        toastNotify(err, toast.TYPE.ERROR)
      })
  }

  const[getCordinate ,setCordinate]=useState<any>();
  const bindImages = (id:any) => {
    getCordinates(id)
      .then((res) => {
        if (res.data.status) {
          setCordinate(res.data.data)
      console.log("img",res.data.data);
        }
      })
      .catch((err) => {
        toastNotify(err, toast.TYPE.ERROR)
      })
  }


  // add tour
  const addTourHandler = (values: ItourDto) => {
    const id = toast.loading('Please wait...')
    let data = { ...values }
    let tourFeatures: any = data.tourFeatures.map((item: any) => {
      return {
        feature: item.name,
        featureTypeId: item.id,
        description: item.description,
      }
    })
    // remove sample data
    tourFeatures = tourFeatures.filter((item: any) => item.featureTypeId !== 0)
    data.tourFeatures = tourFeatures
    if (!isEdit) {
      addTour(data)
        .then((res) => {
          if (res.data.status) {
            toastNotify(id, res)
          } else {
            toastNotify(id, res)
          }
        })
        .catch((err) => {
          const res = { data: { status: false, message: 'Something went wrong' } }
          toastNotify(id, res)
        })
        .finally(() => {
          toast.dismiss(id)
        })
    } else {
      updateTour(data, tourId)
        .then((res) => {
          if (res.data.status) {
            toastNotify(id, res)
          } else {
            toastNotify(id, res)
          }
        })
        .catch((err) => {
          const res = { data: { status: false, message: 'Something went wrong' } }
          toastNotify(id, res)
        })
        .finally(() => {
          toast.dismiss(id)
        })
    }
  }
  // formik
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: values,
    validationSchema: AddTourSchema,
    onSubmit: (values) => {
      addTourHandler(values)
    },
  })

  const [audios, setAudios] = useState<any>([])
  const [tourDescriptionId, setTourDescriptionId] = useState(0)
  let audioTypes: any = ['short', 'medium', 'long']
  const [audioData, setAudioData] = useState<{
    [key: string]: { id: number; name: string; description: string }
  }>({
    short: { id: 0, name: '', description: '' },
    medium: { id: 0, name: '', description: '' },
    long: { id: 0, name: '', description: '' },
    // ...add more audio types here as needed
  })
  const [getImage,setImagePath]=useState<any>();
  const [descStages, setDescStages] = useState(LOADINGSTAGES.IDLE);
  const [defaultLanguageId, setDefaultLangaugeId] = useState(3);
  const bindDescriptions = (tourId: number, tourLanguageId: number) => {
    setDescStages(LOADINGSTAGES.LOADING)
    getDescriptionsByTourIdLangId(tourId, tourLanguageId)
      .then((res) => {
        if (res.data.status) {
          console.log("Kartik",res.data.data);
          const {
            shortDescription,
            mediumDescription,
            longDescription,
            id,
            shortAudio,
            mediumAudio,
            longAudio,
            
          } = res.data.data
          setImagePath(res.data.data);
          setTourDescriptionId(id)
          formik.setFieldValue('shortDescription', shortDescription)
          formik.setFieldValue('mediumDescription', mediumDescription)
          formik.setFieldValue('longDescription', longDescription)
          formik.setFieldValue('tourLanguageId', tourLanguageId)
          // set audio data
          // set name for each audioType
          const audioData = [
            { audioType: 'short', audio: shortAudio, description: shortDescription },
            { audioType: 'medium', audio: mediumAudio, description: mediumDescription },
            { audioType: 'long', audio: longAudio, description: longDescription },
          ]

          const array = audioData
            .filter((a) => a.audio)
            .map((a) => ({
              audioType: a.audioType,
              name: a.audio[`${a.audioType}Name`],
              id: a.audio[`${a.audioType}Id`],
              description: a.description,
              audioUrl: a.audio.audioUrl,
              tourDescriptionId: id,
              filePath: a.audio.audioUrl?.split('/').pop() || '',
            }))

          audioTypes.forEach((item: any) => {
            if (!array.find((a) => a.audioType === item)) {
              array.push({
                audioType: item,
                tourDescriptionId: id,
                name: '',
                id: 0,
                description: '',
                audioUrl: null,
                filePath: '',
              })
            }
          })

          array.forEach((item) => {
            setAudioData((prev) => ({
              ...prev,
              [item.audioType]: {
                ...prev[item.audioType],
                id: item.id,
                name: item.name,
                filePath: item.filePath,
                description:
                  item.audioType === 'short'
                    ? shortDescription
                    : item.audioType === 'medium'
                      ? mediumDescription
                      : item.audioType === 'long'
                        ? longDescription
                        : '',
              },
            }))
          })

          setLoadingStages(LOADINGSTAGES.POSITIVE)
          setDescStages(LOADINGSTAGES.POSITIVE)
          setAudios(array)
        } else {
          setDescStages(LOADINGSTAGES.NEGATIVE)
          setAudios([])
        }
      })
      .catch((err) => {
        setLoadingStages(LOADINGSTAGES.ERROR)
        setDescStages(LOADINGSTAGES.ERROR)
        setAudios([])
      })
  }

  useEffect(() => {
    setLoadingStages(LOADINGSTAGES.LOADING);
    bindTourLanguages();
    if (tourId) {
      const languageId = formik.values.tourLanguageId || defaultLanguageId;
      if (languageId === 0 || languageId === undefined) {
        setValues({ ...values });
      } else {
        // setAudios([]);
        bindDescriptions(tourId as unknown as number, languageId);
      }
    }
    setLoadingStages(LOADINGSTAGES.POSITIVE);
  }, [formik.values.tourLanguageId, defaultLanguageId, tourId]);

  useEffect(() => {
    
    if (id) {
    bindImages(id);  
    }
    
      }, [id]);


  const [isPlaying, setIsPlaying] = useState(false)
  const updateDes = (tourId: number, languageId: number, description: string, type: string) => {
    // if(tourDescriptionId === 0) {
    //   addDescription(tourId, languageId, description, type)
    // }
    const tid = toast.loading('Please Wait... ')
    updateDescription(tourDescriptionId, tourId, languageId, description, type)
      .then((res) => {
        if (res.data.status) {
          toastNotify(tid, res)
          uploadSuccess()
        } else {
          toastNotify(tid, res)
        }
      })
      .catch((err) => {
        const res = { data: { status: false, message: 'Something went wrong' } }
        toastNotify(tid, res)
      })
      .finally(() => {
        toast.dismiss(tid)
      })
  }
  const addDescription = (tourId: number, languageId: number, description: string, type: string) => {
    const tid = toast.loading('Please Wait... ')
    addTourDescription(tourId, languageId, description, type)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(tid, res)
          setTourDescriptionId(res.data.data)
          uploadSuccess()
        } else {
          toastNotify(tid, res)
        }
      })
      .catch((err) => {
        const res = { data: { status: false, message: 'Something went wrong' } }
        toastNotify(tid, res)
      })
      .finally(() => {
        toast.dismiss(tid)
      })
  }
  const [currentAudioType, setCurrentAudioType] = useState('')
  const handleClick = (audioType: string) => {
    if (isPlaying && currentAudioType !== audioType) {
      setIsPlaying(false)
    }
    setCurrentAudioType(audioType)
    setIsPlaying(!isPlaying)
  }

  const steps = [
    { label: 'Cordinates', icon: 'fa fa-map' },
    { label: 'Information', icon: 'fa fa-info' },
    { label: 'Description', icon: 'fa fa-audio-description' },
  ]
  const uploadSuccess = () => {
    console.log('upload success')
    const languageId = formik.values.tourLanguageId || defaultLanguageId;
    if (languageId === 0 || languageId === undefined) {
      setValues({ ...values });
    } else {
      setAudios([]);
      bindDescriptions(tourId as unknown as number, languageId);
      renderStep(2)
    }
  }
const [tourName, setTourName] = useState('')
  const [isTourCompleted, setIsTourCompleted] = useState(false)
  const renderStep = (step: any) => {
    switch (step) {
      case 2:
        return (
          <div key={step} >
            <TourDescriptionPage
              audios={audios}
              formik={formik}
              handleClick={handleClick}
              isEdit={isEdit}
              isPlaying={isPlaying}
              setIsPlaying={setIsPlaying}
              tourId={tourId}
              updateDes={updateDes}
              addDescription={addDescription}
              tourLanguages={tourLanguages}
              stage={descStages}
              uploadSuccess={uploadSuccess}
            />
            </div>
        )
      case 1:
        return <TourDetail key={step} tourId={tourId}
          setTourStage={(stage: boolean) => {
            setIsTourCompleted(stage)
          }}
        />
      case 0:
        return (
          <TourMap
            id={tourId}
            key={step}
            setIsTourCompleted={(stage: boolean) => {
              setIsTourCompleted(stage)
            }}
            setTourName={(name: string) => {
              setTourName(name)
            }}
          />
        )
      default:
        return null
    }
  }
  return (
    <>
      <PageTitle breadcrumbs={profileBreadCrumbs}>{isEdit ? 'Edit' : 'Add'}</PageTitle>
      <div className='row p-2'>
     {id!=null?
      (<TourHeader
        imagePath={getCordinate?.imagePath}
        placeName={getCordinate?.name ?? ""}
        id={id}
        coverImageId={getCordinate?.coverImageId}
        onSuccess={() => { bindImages(id) }}
      
      />):(<span style={{visibility:'hidden'}}></span>)
     }  
        
        
        
        
        {isEdit&&
        
        
        (
          <div className='col-md-12'>
            <div className='card'>
              <div className='card-header flex-wrap border-0 pt-6 pb-0 rounded'>
                <div className='card-title mb-5 text-center'>
                  {tourName}
                </div>
                <div className='text-center card-tools'>
                  <div className='btn-group'>
                    <Link to={`/tour/journey/${id}`} className='btn btn-primary btn-sm align-self-center'>
                      <i className='fa fa-info-circle'></i>
                      Tour Journey
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No data found' />}
      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Something went wrong' />}
      {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
      {loadingStages === LOADINGSTAGES.POSITIVE && (

        <div id='kt_kitty_group_details' className='collapse show'>
          <div className='row'>
            <div>
              <Stepper
                steps={steps}
                renderStep={renderStep}
                formik={formik}
                disabled={!isTourCompleted}
                isEdit={isEdit}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}
