import { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../services/NotifyService'
import {
  deleteAudio,
  getTourdescriptionByEnglish,
  getTourdescriptionById,
} from '../../../../services/TourServices/TourAudioService'
import { KTSVG } from '../../../../_metronic/helpers'
import { Loader } from '../components'
import { AudioUploadModal } from '../upload_comp/upload_file'

export const TextField = (props: any) => {
  const { formik } = props
  return (
    <div className={props.className}>
      <label className='form-label'>{props.label}</label>
      {props.isRequired ? <span className='text-danger'>*</span> : null}
      <input
        type={props.type}
        className='form-control  mb-3 mb-lg-0'
        name={props.name}
        value={formik.values[props.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      {formik.touched[props.name] && formik.errors[props.name] ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  )
}
export const FieldSelect = (props: any) => {
  const { formik } = props
  const children = Array.isArray(props.children) ? props.children : [props.children]

  return (
    <div className={props.className}>
      <label className='form-label'>{props.label}</label>
      {props.isRequired ? <span className='text-danger'>*</span> : null}
      <select
        className='form-select mb-3 mb-lg-0'
        name={props.name}
        value={formik.values[props.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
      >
        {children}
      </select>
      {formik.touched[props.name] && formik.errors[props.name] ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  )
}

export const FieldTextArea = (props: any) => {
  const { formik } = props
  return (
    <div className={props.className}>
      <label className='form-label'>{props.label}</label>
      {props.isRequired ? <span className='text-danger'>*</span> : null}
      <textarea
        className='form-control  mb-3 mb-lg-0'
        name={props.name}
        value={formik.values[props.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        rows={props.rows}
      />
      {formik.touched[props.name] && formik.errors[props.name] ? (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>{formik.errors[props.name]}</div>
        </div>
      ) : null}
    </div>
  )
}
interface Props {
  // declare not required props

  isPlaying: boolean | undefined
  setIsPlaying: any | undefined
  onClick: any | undefined
  audio: any | undefined
  formik: any | undefined
  className: string | undefined
  label: string
  isRequired: boolean
  name: string
  rows: number
  audioType: string
  descriptionId?: number | undefined
  audioId?: number | undefined
  tourId: any
  uploadSuccess: any
}
export const FieldTextAreaDescription = (props: Props) => {
  const { formik, audioType, audioId, descriptionId, onClick, tourId, uploadSuccess } = props
  const { tourLanguageId } = formik.values
  var { audio } = props
  // get description based on descriptionId
  const [descriptionData, setDescriptionData] = useState<any>()
  useEffect(() => {
    if (tourId) {
      getTourdescriptionByEnglish(tourId)
        .then((res: any) => {
          if (res.data.status) {
            setDescriptionData(res.data.data)
          } else {
            setDescriptionData([])
          }
        })
        .catch((err: any) => { })
        .finally(() => { })
    }
  }, [tourId])
  const [isUpload, setIsUpload] = useState(false)
  const description = formik.values[props.name]
  const validDescription = (description: string) => {
    if (description.length > 10) {
      return true
    }
    return false
  }
  // display title if button is disabled
  const title = validDescription(description) ? 'Upload Audio' : 'Please enter description first'
  const deleteAudioFile = () => {
    // audioId and audioType are required
    if (audioId && audioType) {
      const tid = toast.loading('Please wait...')
      deleteAudio(audioId)
        .then((res: any) => {
          if (res.data.status) {
            toastNotify(tid, res)
            uploadSuccess(res.data.data)
          } else {
            toastNotify(tid, res)
          }
        })
        .catch((err) => {
          const res = { status: false, data: { message: 'Something went wrong' } }
          toastNotify(tid, res)
        })
        .finally(() => {
          toast.dismiss(tid)
        })
    }
  }
  const [localIsPlaying, setLocalIsPlaying] = useState(false)
  const playMethod = (audio: string) => {
    const playingAudioElements = document.querySelectorAll("audio[data-playing='true']")
    playingAudioElements.forEach((audioElement: any) => {
      audioElement.pause()
      audioElement.dataset.playing = 'false'
    })
    const currentAudioElement = document.querySelector(`audio[src='${audio}']`) as HTMLAudioElement
    currentAudioElement.dataset.playing = 'true'
    setLocalIsPlaying(true)
  }
  const pauseMethod = (audio: string) => {
    const currentAudioElement = document.querySelector(`audio[src='${audio}']`) as HTMLAudioElement
    currentAudioElement.pause()
    setLocalIsPlaying(false)
  }
  const [firstTextareaRows, setFirstTextareaRows] = useState(props.rows)
  const [secondTextareaRows, setSecondTextareaRows] = useState(props.rows)

  useEffect(() => {

  }, [tourLanguageId])
  return (
    <div className={props.className}>
      <>
        <div className='row justify-content-between'>
          <label className='col-lg-2 col-form-label text-start'>
            {props.label}
            {props.isRequired ? <span className='text-danger'> *</span> : null}
          </label>
          <audio
            className='col-lg-6 border-1 border border-gray-300'
            src={audio}
            controls
            // onProgress={()=>setIsBuffering(true)}
            onPlay={() => {
              playMethod(audio)
            }}
            onPause={() => pauseMethod(audio)}
            onEnded={() => setLocalIsPlaying(false)}
            style={{
              width: '50%',
              margin: '10px 0',
              // display: props.isPlaying ? 'block' : 'none',
              borderRadius: '5px',
              border: '1px solid #ebedf2',
              backgroundColor: '#f5f5f5',
              padding: '10px',
            }}
          />
          <div className='col-lg-4 align-self-center align-content-end'>
            <div className='d-flex'>
              <>
                {!isUpload ? (
                  <button
                    className='btn btn-sm btn-light-primary'
                    onClick={() => (validDescription(description) ? setIsUpload(true) : null)}
                    title={title}
                    style={{
                      opacity: validDescription(description) ? 1 : 0.5,
                    }}
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen024.svg'
                      className='svg-icon-3 svg-icon-primary me-1'
                    />
                    Upload Audio
                  </button>
                ) : (
                  <div className='row'>
                    <AudioUploadModal
                      show={isUpload}
                      setShow={setIsUpload}
                      type='audio'
                      audioType={audioType}
                      descriptionId={descriptionId}
                      audioId={audioId}
                      tourId={tourId}
                      languageId={formik.values.tourLanguageId}
                      description={formik.values[props.name]}
                      uploadSuccess={uploadSuccess}
                    />
                  </div>
                )}
                {audio ? (
                  <button
                    className='btn btn-sm btn-light-danger'
                    type='button'
                    onClick={() => {
                      deleteAudioFile()
                    }}
                    title='Delete Audio'
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen020.svg'
                      className='svg-icon-3 svg-icon-danger me-1'
                    />
                    Delete Audio
                  </button>
                ) : null}
              </>
            </div>
          </div>
        </div>
      </>
      {/* <textarea
        className='form-control  mb-3 mb-lg-0'
        name={props.name}
        value={formik.values[props.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        rows={props.rows}
      /> */}
      <div className='row bg-light border rounded'>
        {tourLanguageId == 3 ? null : (
          <div className='col-md-6 align-self-center'>
            <div className='left-description p-4'>
              <textarea
                // onInput={(event) => handleInput(event, setFirstTextareaRows)}
                className='form-control mb-3 mb-lg-0'
                value={
                  audioType === 'short'
                    ? descriptionData?.shortDescription
                    : audioType === 'long'
                      ? descriptionData?.longDescription
                      : audioType === 'medium'
                        ? descriptionData?.mediumDescription
                        : ''
                }
                readOnly
                rows={firstTextareaRows}
              />
            </div>
          </div>
        )}
        <div className={tourLanguageId == 3 ? 'col-md-12' : 'col-md-6'}>
          <div className='right-description p-4'>
            <textarea
              className='form-control mb-3 mb-lg-0'
              name={props.name}
              // value={props.formik.values[props.name]}
              // onChange={props.formik.handleChange}
              // onBlur={props.formik.handleBlur}
              {...props.formik.getFieldProps(props.name)}
              rows={secondTextareaRows}
            // onInput={(event) => handleInput(event, setSecondTextareaRows)}
            />
          </div>
        </div>
        {formik.touched[props.name] && formik.errors[props.name] ? (
          <div className='d-flex justify-content-end'>
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>{formik.errors[props.name]}</div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const PlayButton = (props: any) => {
  const { onClick, isPlaying, setIsPlaying } = props
  const style = {
    width: '30px',
    height: '30px',
    backgroundColor: '#F3F6F9',
    border: '1px solid #E4E6EF',
    cursor: 'pointer',
    // display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#6E7191',
    fontSize: '20px',
  }
  return (
    <>
      {isPlaying ? (
        <i
          className='bi bi-pause-circle-fill'
          style={style as any}
          onClick={() => setIsPlaying(!isPlaying)}
        ></i>
      ) : (
        <i
          className='bi bi-play-circle-fill'
          style={style as any}
          onClick={() => setIsPlaying(!isPlaying)}
        ></i>
      )}
    </>
  )
}
