import axios from "axios";
import { useFormik } from "formik";
import {useState } from "react";
import { Button, Col, Form, Modal, ProgressBar, Row } from "react-bootstrap";
import { baseURL, localBaseURL } from "../../../../services/BaseUrl";
import { AUTH_LOCAL_STORAGE_KEY } from "../../../modules/auth";
import * as Yup from 'yup';
import { toast } from "react-toastify";
import { toastNotify } from "../../../../services/NotifyService";


export const FileInput = (props: any) => {
    const [formData, setFormData] = useState<any>({});
    const [erorMessage, setErrorMessage] = useState<string>('');
    const handleFileUpload = (files: FileList) => {
        const formData = new FormData();
        for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
        }
        // check size of file and should be less than 1MB
        if (files[0].size > 1000000) {
            setErrorMessage('File size should be less than 1MB');
            return;
        }
        // check invalid files 
        setFormData(formData);
    };

    return (
        <div>
            <div className="row">
                <input
                    className="form-control form-control-sm form-control-solid p-1"
                    type="file"
                    // jpg , jpeg  and png only
                    accept="image/jpeg, image/png"
                    // only one file
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        handleFileUpload(event.target.files as FileList);
                    }}
                />
                <span className="text-danger">{erorMessage}</span>
                <div className="col-lg-2">
                    <button
                        className="btn btn-sm btn-light-primary fw-bolder ms-2 fs-8 py-1 px-3 p-1"
                        onClick={() => {
                            props.onUpload(formData);
                        }}>Upload
                    </button>
                </div>
            </div>
        </div>
    );
}
interface IFileUploadModalProps {
    show: boolean;
    setShow: (show: boolean) => void;
    mb?: number;
    audioType: string;
    descriptionId?: number | undefined;
    // use only listed types as mentioned in the FileInput  Form
    type: 'image' | 'video' | 'audio' | 'document'| 'pdf' | 'excel' | 'csv' | 'zip' | 'rar' | 'other' | 'doc' | 'docx';
    tourId: number;
    languageId: number;
    audioId?: number;
    description: string;
    uploadSuccess: (data: any) => void;
}


export function AudioUploadModal(props: IFileUploadModalProps) {
    const { show,setShow,type,audioType,languageId,audioId,tourId,descriptionId,description,uploadSuccess} = props;
    const handleClose = () => setShow(false);
    const [progress, setProgress] = useState(0);
    const stages = [
        'Upload',
        'Processing',
        'Completed',
        'Error'
    ]
    const [uploading, setUploading] = useState(stages[0]);
    const [validationError, setValidationError] = useState<string>('');
    const [uploadMessage, setUploadMessage] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    interface SaveAudioValues {
        file: File;
        name: string;
        description: string;
    }
    const axiosInstance = getAxiosInstance();
    async function saveAudio(values: SaveAudioValues) {
        const toastId = toast.loading('Uploading Audio...');
        try {
            // validate file
            if (!values.file.type.startsWith('audio')) {
                throw new Error('Please upload an audio file');
            }
            // create form data
            const formData = new FormData();
            formData.append("tourId", tourId as any);
            formData.append("tourLanguageId", languageId as any);
            formData.append("audioType", audioType);
            formData.append("file", values.file);
            formData.append("name", values.name);
            formData.append("id", audioId || 0 as any);
            formData.append("description", values.description);
            formData.append("tourDescriptionId", descriptionId || 0 as any);
            // create axios instance
            // update uploading state
            setUploading(stages[1]);
            // show toast
            // make request
            const {data} = await axiosInstance.post(`/TourAudio/file`, formData, {
                onUploadProgress: (progressEvent: ProgressEvent) => {
                    const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(progress);
                },
            });
            // handle response
            if (data.status) {
                setUploadMessage(data.message);
                setUploading(stages[2]);
                const res = {data};
                toastNotify(toastId,res);
                uploadSuccess(data);
                setTimeout(() => {
                    refreshModal();
                }, 2000);
            } else {
                const res = {status :false,data:{ message : "Unable to upload Audio"}}
                toastNotify(toastId,res);
                throw new Error(data.message);
            }
        } catch (err) {
            // handle error
            setUploading(stages[3]);
            const res = {status :false,data:{ message : "Something went wrong"}}
            toastNotify(toastId,res);
        } finally {
            setProgress(0);
            toast.dismiss(toastId);
        }
    }
       // Extract the common logic of creating the axios instance and adding the interceptors into a separate function to avoid duplicating the code.
       function getAxiosInstance() {
        const source = axios.CancelToken.source();
        let token = '';
        const aware_user = JSON.parse(localStorage.getItem(AUTH_LOCAL_STORAGE_KEY) || '{}');
        if (aware_user) {
            token = aware_user?.accessToken;
        }
        return axios.create({
            baseURL: process.env.NODE_ENV === 'development' ? localBaseURL : baseURL,
            headers: {
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'POST, GET, OPTIONS',
                Authorization: 'Bearer ' + token,
            },
            cancelToken: source.token,
        });
    }
    // create interceptor for unauthorized error
    axiosInstance.interceptors.response.use((response) => response, (error) => {
        if (error.response.status === 401) {
            localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
            window.location.reload();
        }
    });
  
    const refreshModal = () => {
        // remove file from input
        const fileInput = document.getElementsByName('file') as NodeListOf<HTMLInputElement>;
        fileInput[0].value = '';
        setUploadMessage('');
        setUploading(stages[0]);
    };
    // file size should be less than 10 MB
    const FILE_SIZE = 10000000;
    const SUPPORTED_FORMATS = [
        // ALL audio formats
        "audio/mp3",
        "audio/wav",
        "audio/ogg",
        "audio/mpeg",
        "audio/aac",
        "audio/flac",
        "audio/wma",
    ]
    const formik = useFormik({
        initialValues: {
            name: '',
            file: '',
            description : description
        },
        validationSchema: Yup.object({
            file: Yup.mixed().required('File is Required')
                .test('fileSize', // pass file size in message
                    'File too large', (value: { size: number; }) => {
                        return value && value.size <= FILE_SIZE;
                    }
                ).test('fileFormat', 'Unsupported Format', (value: { type: any; }) => {
                    return value && SUPPORTED_FORMATS.includes(value.type);
                }
            ),
            
        }),
        enableReinitialize: true,
        onSubmit: (values:any) => {
            saveAudio(values);
        },
    });

    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload File</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formik.handleSubmit}>
                        <Row>
                        <Form.Label>Description</Form.Label>
                        <Col md={10}>
                                <Form.Group>
                                    <Form.Control
                                        type="text"
                                        {...formik.getFieldProps("description")}
                                    />
                                    {formik.touched.file && formik.errors.file ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.file}</div>
                                        </div>) : null}
                                </Form.Group>
                        </Col>
                        </Row>
                        <input name="description" value={description} type="hidden" />
                        <Row>
                            <Form.Label>File</Form.Label>
                            <Col md={10}>
                                <Form.Group>
                                    <Form.Control
                                        type="file"
                                        name="file"
                                        onChange={
                                            (event:any) => {
                                                formik.setFieldValue("file", event.currentTarget.files[0]);
                                            }
                                        }

                                        accept={type === "image" ? "image/jpeg, image/png"
                                            : type === "video" ? "video/mp4,video/x-m4v,video/*"
                                                : type === "audio" ? "audio/*"
                                                    : type === "pdf" ? "application/pdf"
                                                        : type === "doc" ? "application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                            : type === "excel" ? "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                                : type === "zip" ? "application/zip, application/x-compressed-zip"
                                                                    : type === "csv" ? "text/csv" : "image/jpeg, image/png"
                                        }
                                    />
                                    {formik.touched.file && formik.errors.file ? (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.file}</div>
                                        </div>) : null}
                                </Form.Group>
                            </Col>
                            <Col md={2} className='d-flex align-items-center justify-content-center p-2'>
                                 <Button variant="primary" type="submit"
                                    disabled ={!formik.isValid || uploading === stages[1]}
                                    className="d-flex align-items-center justify-content-center p-2"   >
                                    Upload
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    {uploading === stages[1]
                        &&
                        <Row className="mt-2">
                            <ProgressBar
                                striped variant={progress === 100 ? 'success' : 'primary'}
                                animated
                                now={progress} label={`${progress}%`}
                            />
                        </Row>
                    }
                    {validationError && <div className="text-danger">{validationError}</div>
                    }
                    {uploading === 'Error' && <div className="text-danger">Error in uploading file</div>}
                    {uploading === 'Completed' &&
                        <Row className="d-flex align-items-center justify-content-center">
                            <Col md={2} className='d-flex align-items-center justify-content-center p-2'>
                                <i className="fas fa-check-circle text-success"></i>
                            </Col>
                            <Col md={10} className='d-flex align-items-center justify-content-center p-2'>
                                <div className="text-success">File uploaded successfully</div>
                            </Col>
                        </Row>}
                </Modal.Body>
            </Modal>
        </>
    );
}