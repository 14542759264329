import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getToursByUser } from '../../../services/TourService';
import { PageTitle } from '../../../_metronic/layout/core';
import { LOADINGSTAGES, ServerMessage } from '../../../_shared/components/_laodSetups/LoadingSteps';
import { Loader } from '../components/components';
import { ListUiHeader } from '../components/ListUiHeader';
import { IPagedData, ITourList, ITourPage, ListTourByUserColumn, TourStatus } from './tourspartials/LIstComps'
import Pagination from '../../../lib/Pagination/Pagination';
import { PAGE_SIZE } from '../../../_shared';

export default function ListByUserTours(props: any) {
  const navigate = useNavigate();
  let columns = ListTourByUserColumn;
  let initialValues = {
    tourLocationId: 0,
    tourId: 0,
    cityId: 0,
    stateId: 0,
    countryId: 0,
    pageSize: PAGE_SIZE,
    pageNumber: 1,
    filter: "",
    isFeatured: false,
  }
  const featured = window.location.href.includes('featured-tours');
  const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.IDLE);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagenation, setPagenation] = useState<IPagedData<ITourPage>>({
    data: { tours: [], totalPages: 0, },
    page: { pageNumber: 0, size: 0, totalElements: 0, totalPages: 0, },
  });
  const status = TourStatus;
  const bindTour = (initialValues: any) => {
    // if url end with /featured-tours then set featured to true
    if (featured) {
      initialValues.isFeatured = true;
    }
    setLoadingStages(LOADINGSTAGES.LOADING);
    getToursByUser(initialValues)
      .then((res) => {
        if (res.data.status) {
          setPagenation(res.data.data);
          setLoadingStages(LOADINGSTAGES.POSITIVE);
        }
        else {
          setLoadingStages(LOADINGSTAGES.NEGATIVE);
        }
      })
      .catch((err) => {
        setLoadingStages(LOADINGSTAGES.ERROR);
      });
  }
  useEffect(() => {
    initialValues.pageNumber = currentPage;
    bindTour(initialValues);
  }, [featured, currentPage]);

  return (
    <>
      <PageTitle  >
        {featured ? "Featured Tours" : "Tours"}
      </PageTitle>
      <div className='card'>
        <ListUiHeader title={featured ? "Featured Tours" : "Tours"} link="add" isList />
        <div className='separator-solid'></div>
        <div className='card-body'>
          <div className='table-responsive'>
            {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
            {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No Data Found' />}
            {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Error Occured' />}
            {loadingStages === LOADINGSTAGES.POSITIVE &&
              <table className='table table-fixed table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                  <tr className='table-info border-1 rounded-5'>
                    {columns.map((column: any) => (
                      <th className={column.id === 'name' ? 'text-left ps-2 sticky-header text-info font-weight-bolder' :
                        'text-left  ps-2 text-bg-secondary text-info font-weight-bolder'}
                        key={column.id} style={{ minWidth: column.minWidth }}>
                        {column.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pagenation.data.tours.map((row: any) => {
                    return (
                      <tr key={row.id}>
                        <td className='text-left ps-2 sticky-header text-bg-secondary text-info font-weight-bolder'>
                          <Link to={`/tours/edit/${row.id}`}>
                            {row.name.toUpperCase()}
                          </Link>
                        </td>
                        <td className='text-left'>
                          <span style={{ color: '#595959' }}
                            className='font-weight-bolder'>{row.locationName}</span>
                        </td>
                        <td className='text-left' style={{ color: '#595959' }}>
                          {status.map((item) => {
                            if (item.id === row.statusId)
                              return <span key={item.id} className={item.className}>{item.name}
                              </span>
                          })}
                        </td>
                        <td className='text-left'>
                          <span style={{ color: '#595959' }}
                            className='font-weight-bolder'>{row.zipCode}</span>
                        </td>
                        <td className='text-left'>
                          <span style={{ color: '#595959' }}
                            className='font-weight-bolder'>{row.cityName}</span>
                        </td>
                        <td className='text-left'>
                          <span style={{ color: '#595959' }}
                            className='font-weight-bolder'>{row.stateName}</span>
                        </td>
                        <td className='text-left'>
                          <span style={{ color: '#595959' }}
                            className='font-weight-bolder'>{row.countryName}</span>
                        </td>
                        <td className='text-left'>
                          <span style={{ color: '#595959' }}
                            className='font-weight-bolder'>{row.authorName}</span>
                        </td>
                        <td className='text-left'>
                          <div style={{ color: '#595959' }}
                            className='font-weight-bolder'>
                            <span className='label label-lg label-light-primary label-inline'>
                              {row.isAuthor ? 'Author' : row.isReviewer ? 'Reviewer' : row.isProofReader ? 'Proof Reader' : 'None'}
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>}
          </div>
          <div className="d-flex justify-content-end">
            <Pagination
              className="pagination-bar"
              currentPage={pagenation.page.pageNumber}
              totalCount={pagenation.data.totalPages}
              pageSize={pagenation.page.size}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          </div>
        </div>
      </div>
    </>
  )
}
