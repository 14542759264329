import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Pagination from '../../../lib/Pagination/Pagination'
import PaginationBar from '../../../lib/Pagination/PaginationBar'
import { toastNotify } from '../../../services/NotifyService'
import { activeLocation } from '../../../services/TourLocationService'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import './list.css'
import { UploadPlaceImage } from '../components/UploadPlaceImage';
import { IPagedDataList } from '../../../app/pages/places/TourListPaggination'
import { ILocationModel } from '../../../_models/ILoctionModel'

type Props = {
  className: string
  placePage: IPagedDataList<ILocationModel>
  onRefresh: any
}

export function PlaceTabel(props: Props) {
  const { className, placePage } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [pagenation, setPagenation] = useState<IPagedDataList<ILocationModel>>({
    data: [],
    page: { pageNumber: 0, size: 0, totalElements: 0, totalPages: 0, },
  });
  const activeClick = (id: any) => {
    const toastId = toast.loading('Pleaset Wait....')
    activeLocation(id)
      .then((res: any) => {
        if (res.data.status) {
          console.log("si", res.data.data);
          toastNotify(toastId, res)
          props.onRefresh()
        } else {
          toastNotify(toastId, res)
        }
      })
      .catch((err: any) => {
        const res = {
          status: false,
          data: {
            message: 'Something went wrong',
          },
        }
        toastNotify(toastId, res)
      })
      .finally(() => {
        toast.dismiss(toastId)
      })
  }
  console.log("pag", pagenation.page.size);
  const [uploadImage, setUploadImage] = React.useState(false)
  const [id, setId] = React.useState(0)
  const onUploadClick = (id: number) => {
    if (id) {
      setUploadImage(true)
      setId(id)
    }
  }
  
  return (
    <div>
      <div className={`card ${className}`}>
        <div className='card-body py-3'>
          <div className='table-responsive'>
            <table className='table table-fixed table-row-dashed table-row-gray-300 align-middle '>
              <thead>
                <tr className='fixed-column table-info border-1'>
                  <th className='sticky-header table-info text-left ps-4 min-w-325px'>Name</th>
                  <th className='text-left min-w-125px'>City Name</th>
                  <th className='text-left min-w-125px'>State Name</th>
                  <th className='text-left min-w-125px'>Country Name</th>
                  <th className='text-left min-w-200px'>Tag Line</th>
                  <th className='text-left min-w-200px'>Zip Code</th>
                  <th className='text-left min-w-200px'>Actions</th>
                  <th className='text-left min-w-200px'>Status </th>
                </tr>
              </thead>
              {placePage.data?.map((item: any, index: number) => (
                <tbody>
                  <tr key={item.id}>
                    <td className='sticky-header text-bg-secondary p-3'>
                      <div className='d-flex align-items-center'>
                        <div className='symbol symbol-50px me-5'>
                          <img
                            src={
                              item.imagePath === '' ?
                                toAbsoluteUrl('/media/stock/600x400/img-26.jpg')
                                : item.imagePath
                            }
                            className=''
                            alt=''
                          />
                        </div>
                        <div className='d-flex justify-content-start flex-column'>
                          <Link
                            to={`/places/edit/${item.id}`}
                            className='text-dark fw-bold text-hover-primary mb-1 fs-6'
                          >
                            {item.name}
                          </Link>
                        </div>
                      </div>
                    </td>
                    <td className='text-left'>
                      <div>
                        <span className='text-dark fw-semibold text-dark d-block fs-7'>
                          {item.city}
                        </span>
                      </div>
                    </td>
                    <td className='text-left'>
                      <div>
                        <span className='text-dark fw-semibold text-dark d-block fs-7'>
                          {item.state}
                        </span>
                      </div>
                    </td>
                    <td className='text-left'>
                      <div>
                        <span className='text-dark fw-semibold text-dark d-block fs-7'>
                          {item.country}
                        </span>
                      </div>
                    </td>
                    <td className='text-left'>
                      <div>
                        <span className='text-dark fw-semibold text-dark d-block fs-7'>
                          {item.locationTag}
                        </span>
                      </div>
                    </td>
                    <td className='text-left'>
                      <div>
                        <span className='text-dark fw-semibold text-dark d-block fs-7'>
                          {item.zipCode}
                        </span>
                      </div>
                    </td>
                    <td className='text-left'>
                      <Link
                        to={`/places/tour/${item.id}/${item.countryId}/${item.stateId}`}
                        className='badge badge-light-primary me-2'
                      >
                        Assign Tour
                      </Link>
                    </td>
                    <td className='text-left'>
                      {item.isActive ? (
                        <button
                          className='badge badge-light-success'
                          onClick={() => {
                            activeClick(item.id)
                          }}
                        >
                          Active
                        </button>
                      ) : (
                        <button
                          className='badge badge-light-danger'
                          onClick={() => {
                            activeClick(item.id)
                          }}
                        >
                          Inactive
                        </button>
                      )}
                    </td>
                  </tr>



                </tbody>
              ))}

            </table>

          </div>
         
        </div>
       
      </div>
    </div>
  )
}
