import {Modal, Button, Form, Alert} from 'react-bootstrap'
import {IFeatureType} from '../../../../_models/FeatureTypes'
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {getFeatureType} from '../../../../services/TourServices/FeatureTypeService'

interface IAddFeatureTypeProps {
  show: boolean
  handleClose: () => void
  handleSave: (featureType: IFeatureType) => void
  id: number
}

export const FeatureType = (props: IAddFeatureTypeProps) => {
  const {show, handleClose, handleSave, id} = props
  const [featureType, setFeatureType] = useState<IFeatureType>({
    id: 0,
    name: '',
    description: '',
    isActive: true,
  })
  const formik = useFormik({
    initialValues: featureType,
    validationSchema: Yup.object().shape({
      name: Yup.string().required('Name is required'),
      description: Yup.string().required('Description is required'),
    }),
    enableReinitialize: true,
    onSubmit: (values) => {
      handleSave(values)
      handleClose()
    },
  })
  const bindFeatureById = (id: number) => {
    getFeatureType(id)
      .then((res) => {
        setFeatureType(res.data.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }
  useEffect(() => {
    if (id > 0 && show) {
      bindFeatureById(id);
    }
  }, [id, show]);
  
  const handleCloseModal = () => {
    setFeatureType({id: 0, name: '', description: '',isActive: true})
    handleClose()
  }

  return (
    <Modal
      show={show}
      onHide={handleCloseModal}
      style={{backgroundColor: 'transparent', zIndex: '9999 !important'}}
    >
      <Modal.Header closeButton>
        <Modal.Title>
            {id > 0 ? 'Edit Feature Type' : 'Add Feature Type'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={formik.handleSubmit}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control type='text' {...formik.getFieldProps('name')} />
            {formik.errors.name && formik.touched.name && (
              <Form.Control.Feedback type='invalid'>{formik.errors.name}</Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group controlId='description'>
            <Form.Label>Description</Form.Label>
            <Form.Control
              type='text'
              {...formik.getFieldProps('description')}
              //   isInvalid={formik.errors.description && formik.touched.description}
            />
            {formik.errors.description && formik.touched.description && (
              <Form.Control.Feedback type='invalid'>
                {formik.errors.description}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Button
            variant='primary'
            type='submit'
            className='mt-3'
            disabled={!(formik.isValid)}
          >
            Submit
          </Button>
        </Form>
        {id > 0 && (
          <Alert variant='warning' className='mt-3'>
            You are editing feature type with ID: {id} and name: {featureType.name}. Please be aware that you are editing an existing 
            feature type.
          </Alert>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={handleCloseModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
