export interface IUserAuthenticationModel {
    userDetails: UserDetails;
    accessToken: string;
    currentCase: 'Invalid' | 'NotApproved' | 'Positive' | 'Unregistered' | 'Incomplete';
    userId: string;
    }
    
    export interface UserDetails {
    username: string;
    userId: string;
    roleId: string;
    email: string;
    userRoles: string[];
    isApproved: boolean;
    isProfileCompleted: boolean;
    }
export enum LoginStatus {
  Invalid = "Invalid",
  Unregistered = "Unregistered",
  // Incomplete = "Incomplete",
  NotApproved = "NotApproved",
  Positive = "Positive",
  Incomplete = "Incomplete"
}

      