import {Table} from 'react-bootstrap'
import {IFeatureType} from '../../../_models/FeatureTypes'
import {useState, useEffect} from 'react'
import {
  changeFeatureTypeStatus,
  createFeatureType,
  getFeatureTypes,
  updateFeatureType,
} from '../../../services/TourServices/FeatureTypeService'
import {toast} from 'react-toastify'
import {toastNotify} from '../../../services/NotifyService'
import {FeatureType} from './tourspartials/AddFeatureType'

export function FeatureTypeList() {
  const [featureTypes, setFeatureTypes] = useState<IFeatureType[]>([])
  const bindFeatureTypes = () => {
    getFeatureTypes()
      .then((res: any) => {
        setFeatureTypes(res.data.data)
      })
      .catch((error: any) => {
        console.log(error)
      })
  }
  useEffect(() => {
    bindFeatureTypes()
  }, [])
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [featureId, setFeatureId] = useState(0)
  const handleSave = (values: any) => {
    handleRequest(createFeatureType, values)
  }

  const handleUpdate = (values: any) => {
    handleRequest(updateFeatureType, values)
  }
  const handleRequest = (requestFunc: any, values: any) => {
    const tid = toast.loading('Saving...')
    requestFunc(values)
      .then((res: any) => {
        toastNotify(tid, res)
        if (res.data.status) {
          handleClose()
          bindFeatureTypes()
        }
      })
      .catch((error: any) => {
        const res = {data: {status: false, message: 'Error Occurred'}}
        toastNotify(tid, res)
      })
  }
  const activeClick = (id: any) => {
    const toastId = toast.loading('Pleaset Wait....')
    changeFeatureTypeStatus(id)
      .then((res: any) => {
        if (res.data.status) {
          toastNotify(toastId, res)
          bindFeatureTypes()
        } else {
          toastNotify(toastId, res)
        }
      })
      .catch((err: any) => {
        const res = {
          status: false,
          data: {
            message: 'Something went wrong',
          },
        }
        toastNotify(toastId, res)
      })
      .finally(() => {
        toast.dismiss(toastId)
      })
  }
  return (
    <div className='card'>
      <div className='card-header'>
        <h3 className='card-title'>Feature Types</h3>
        <div className='card-toolbar'>
          <button
            className='btn btn-sm btn-primary'
            onClick={() => {
              setFeatureId(0)
              setShow(true)
            }}
          >
            {' '}
            Add Feature Type
          </button>
        </div>
      </div>
      <Table striped bordered hover>
        <thead>
          <tr className='text-center'>
            <th>Name</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {featureTypes?.map((featureType) => (
            <tr key={featureType.id} className='text-center'>
              <td>
                <button
                  className='btn btn-sm btn-hover-brand btn-bg-transparent btn-text-primary btn-icon mr-2'
                  onClick={() => {
                    setFeatureId(featureType.id)
                    setShow(true)
                  }}
                >
                  {' '}
                  <span
                    className='text-primary font-weight-bolder font-size-lg'
                    style={{cursor: 'pointer', fontSize: '1rem'}}
                  >
                    {featureType.name}
                  </span>
                </button>
              </td>
              <td className='w-50'>
                <span >
                  {featureType.description}
                </span>
              </td>
              <td className='text-center'>
                {featureType.isActive ? (
                  <button
                    className='badge badge-light-success'
                    onClick={() => {
                      activeClick(featureType.id)
                    }}
                  >
                    Active
                  </button>
                ) : (
                  <button
                    className='badge badge-light-danger'
                    onClick={() => {
                      activeClick(featureType.id)
                    }}
                  >
                    Inactive
                  </button>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <FeatureType
        handleClose={handleClose}
        show={show}
        id={featureId}
        handleSave={(values: any) => {
          if (featureId > 0) {
            handleUpdate(values)
          } else {
            handleSave(values)
          }
        }}
      />
    </div>
  )
}
