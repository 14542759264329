import {Link, useParams} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {LOADINGSTAGES, ServerMessage} from '../../../_shared/components/_laodSetups/LoadingSteps'
import {Loader} from '../components/components'
import {updateRequestedRoles, useSingleUserHook} from './UsersHooks/ApiUsers'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {updateRoles} from '../../../services/UsersService/UsersService'
import {toast} from 'react-toastify'
import {useState} from 'react'
import {toastNotify} from '../../../services/NotifyService'

export const UserView = (props: any) => {
  const {id} = useParams()
  const {user, isLoading, loadingStages} = useSingleUserHook({id})
  const [isUpdating, setIsUpdating] = useState<boolean>(false)
  const {requestedRoles} = user
  const updateSchema = Yup.object().shape({
    roles: Yup.array().of(Yup.string()),
  })
  const formik = useFormik({
    initialValues: {
      id: user.userId,
      roles: [],
    },
    validationSchema: updateSchema,
    enableReinitialize: true,
    onSubmit: (values, {setSubmitting}) => {
      const {id, roles} = values
      const Id = toast.loading('Please wait...')
      setIsUpdating(true)
      setTimeout(() => {
        updateRoles(id, roles)
          .then((res: any) => {
            if (res.data.status) {
              //   resetForm();
              toastNotify(Id, res)
              // redirect(); // redirect
            } else {
              toastNotify(Id, res)
            }
          })
          .catch((err: any) => {
            const res = {data: {message: 'Something went wrong', success: false}}
            toastNotify(Id, res)
          })
          .finally(() => {
            setIsUpdating(false)
            // resetForm();
          })
      }, 1000)
    },
  })
  // get promise from service
  // check promise is resolved or rejecte
  return (
    <>
      <PageTitle>Users</PageTitle>
      {loadingStages === LOADINGSTAGES.LOADING && <Loader />}
      {loadingStages === LOADINGSTAGES.NEGATIVE && <ServerMessage message='No User Found' />}
      {loadingStages === LOADINGSTAGES.ERROR && <ServerMessage message='Server issue occured' />}
      {loadingStages === LOADINGSTAGES.POSITIVE && (
        <div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
          <form onSubmit={formik.handleSubmit}>
            <div className='card-header cursor-pointer'>
              <div className='card-title m-0'>
                <h3 className='fw-bolder m-0'>User Info</h3>
              </div>
              <div className='card-toolbar'>
                <button
                  type='submit'
                  disabled={user?.isApproved || isUpdating}
                  className='btn btn btn-sm btn-primary btn-xs'
                >
                  Update
                </button>
              </div>
            </div>
            <div className='card-body p-9'>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Name</label>
                <div className='col-lg-8'>
                  <span className='fw-bolder fs-6 text-dark'>
                    {user?.firstName + ' ' + user?.lastName}
                  </span>
                </div>
              </div>
              <div className='row mb-7 align-items-center'>
                <label className='col-lg-4 fw-bold text-muted'>Location</label>
                <div className='col-lg-8 fv-row'>
                    <div className='card-body p-2'>
                      <p className='card-text mb-0'>
                        {user?.addressLine1}
                        <br />
                        {user?.addressLine2 && (
                          <>
                            {user?.addressLine2}
                            <br />
                          </>
                        )}
                        {user?.cityName}{" "} {user?.stateName}{" "}{user?.countryName}
                      </p>
                    </div>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>
                  Contact Phone
                  <i
                    className='fas fa-exclamation-circle ms-1 fs-7'
                    data-bs-toggle='tooltip'
                    title='Phone number must be active'
                  ></i>
                </label>

                <div className='col-lg-8 d-flex align-items-center'>
                  <span className='fw-bolder fs-6 me-2'>{user?.phoneNumber}</span>
                  {user?.isApproved ? (
                    <span className='badge badge-success'>Approved</span>
                  ) : (
                    <span className='badge badge-danger'>Not Approved</span>
                  )}
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Email</label>
                <div className='col-lg-8'>
                  <span className='fw-bold fs-6 text-dark text-hover-primary'>{user?.email}</span>
                </div>
              </div>
              <div className='row mb-7'>
                <label className='col-lg-4 fw-bold text-muted'>Current Role</label>
                <div className='col-lg-8'>
                  <div className='d-flex flex-wrap'>
                    {user?.roleNames.map((role: any) => (
                      <span key={role} className='badge badge-light-success fw-bolder me-2'>
                        {role}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
              <div className='row mb-7'>
                <div className='notice d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
                  <KTSVG
                    path='icons/duotune/general/gen044.svg'
                    className='svg-icon-2tx svg-icon-warning me-4'
                  />
                  <div className='d-flex flex-stack flex-grow-1'>
                    {requestedRoles?.length > 0 && (
                      <div className='fw-bold col-lg-6'>
                        {requestedRoles?.map((role: any) => {
                          return (
                            <>
                              <div className='p-2' tabIndex={role.roleId}>
                                <input
                                  className='form-check-input bg-primary'
                                  type='checkbox'
                                  {...formik.getFieldProps('roles')}
                                  id='roles'
                                  disabled={user.roleNames.includes(role.roleName)}
                                  value={role.roleId}
                                />
                                <label
                                  className='form-check-label fw-bold text-gray-700 fs-6'
                                  htmlFor='role'
                                >
                                  {role.roleName}
                                </label>
                              </div>
                            </>
                          )
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  )
}
