import { FormikProps, useFormik } from 'formik'
import { useCallback, useEffect, useRef, useState } from 'react'
import { FieldSelect, FieldTextArea, TextField } from '../../components/fields/TextField'
import * as yup from 'yup'
import {
  getAllCountries,
  getAllFeatureTypes,
  getCitiesByState,
  getStatesByCountry,
} from '../../../../services/CommonService'
import { getDetail, updateDetail } from '../../../../services/TourService'
import { toastNotify } from '../../../../services/NotifyService'
import { toast } from 'react-toastify'

interface TourProps {
  key: 0
  tourId: any
  setTourStage: any
}
export const TourDetail = (props: TourProps) => {
  const { tourId, setTourStage } = props
  const initialValues = {
    id: 0,
    countryId: 0,
    stateId: 0,
    cityId: 0,
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    duration: 0,
    landmark: '',
    tourFeatures: [],
  }
  const [values, setValues] = useState(initialValues)
  const Schema = yup.object().shape({
    addressLine1: yup.string(),
    addressLine2: yup.string(),
    zipCode: yup.string(), // .Required("Zip Code is required"),
    landmark: yup.string(), // .Required("Landmark is required"),
    tourFeatures: yup.array(),
    cityId: yup.number().test('valid-city', 'Please select a valid City', (value: any) => {
      return value != 0
    }),
    stateId: yup.number().test('valid-state', 'Please select a valid State', (value: any) => {
      return value != 0
    }),
    countryId: yup.number().test('valid-country', 'Please select a valid Country', (value: any) => {
      return value != 0
    }),
  })
  const formik = useFormik({
    initialValues: values,
    enableReinitialize: true,
    validationSchema: Schema,
    onSubmit(values: any, { setSubmitting }) {
      setSubmitting(true)
      let tourFeatures: any = values.tourFeatures.map((item: any) => {
        return {
          feature: item.name,
          featureTypeId: item.id,
          description: item.description,
        }
      })
      values.tourFeatures = tourFeatures
      updateDetails(values)
    },
  })
  const updateDetails = (values: any) => {
    const tId = toast.loading('Please wait...')
    values.id = tourId
    updateDetail(values)
      .then((res) => {
        if (res.data.status) {
          bindDetail(tourId)
          toastNotify(tId, res)
        } else {
          toastNotify(tId, res)
        }
      })
      .catch(() => {
        const res = { status: false, data: { message: 'Something went wrong' } }
        toastNotify(tId, res)
      })
      .finally(() => {
        toast.dismiss(tId)
      })
  }
  const [stage, setStage] = useState(0)
  const bindDetail = (id: any) => {
    getDetail(id)
      .then((res) => {
        if (res.data.status) {
          setTourStage(res.data.data.isDetailCompleted)
          setStage(res.data.data.stage)
          let features = res.data.data.tourFeatures
          if (features) {
            features = features.map((item: any) => ({
              id: item.featureTypeId || 0,
              description: item.description || '',
            }))

            // Add the tourFeatures array to the data object
            const data = { ...res.data.data, tourFeatures: features }
            setValues(data)
          }
        } else {
          setValues(initialValues)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const [featureTypes, setFeatureTypes] = useState<any>([])
  const bindFeatureTypes = () => {
    getAllFeatureTypes()
      .then((res) => {
        if (res.data.status) {
          setFeatureTypes(res.data.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }
  const [countries, setCountries] = useState<any>([])
  const [states, setStates] = useState<any>([])
  const [cities, setCities] = useState<any>([])

  const bindCountries = () => {
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          let data = res.data.data
          setCountries(data)
        } else {
          setCountries([])
        }
      })
      .catch(() => {
        setCountries([])
      })
  }

  useEffect(() => {
    bindCountries()
  }, [])

  const bindStates = useCallback((countryId: number) => {
    getStatesByCountry(countryId)
      .then((res: any) => {
        const array = res.data.data
        setStates(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  const bindCities = useCallback((stateId: number) => {
    getCitiesByState(stateId)
      .then((res: any) => {
        const array = res.data.data
        setCities(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }, [])

  useEffect(() => {
    if (formik.values.stateId !== 0 && formik.values.stateId !== undefined) {
      setCities([])
      bindCities(formik.values.stateId)
    }
  }, [formik.values.stateId, bindCities])

  useEffect(() => {
    if (formik.values.countryId !== 0 && formik.values.countryId !== undefined) {
      bindStates(formik.values.countryId)
    }
    setStates([])
    setCities([])
  }, [formik.values.countryId, bindStates])

  const setFeaturesDescription = (id: any, description: string) => {
    if (id === undefined) {
      return
    }
    const updatedFeatures = formik.values.tourFeatures.map((feature: any) => {
      if (feature.id === id) {
        return {
          ...feature,
          description,
        }
      }
      return feature
    })
    formik.setFieldValue('tourFeatures', updatedFeatures)
  }

  useEffect(() => {
    bindFeatureTypes()
    bindCountries()
    if (tourId) {
      bindDetail(tourId)
    } else {
      setValues(initialValues)
    }
  }, [])

  return (
    <>
      <div className='card card-custom mx-5'>
        <form action='post' onSubmit={formik.handleSubmit}>
          <div className='card-body'>
            <div className='row mb-7'>
              <FieldSelect
                className='col-lg-6'
                isRequired
                label='Country'
                name='countryId'
                formik={formik}
              >
                <option value={0}>Select Country</option>
                {countries.map((item: any) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </FieldSelect>

              <FieldSelect
                className='col-lg-6'
                isRequired
                label='State'
                name='stateId'
                formik={formik}
              >
                <option value={0}>Select State</option>
                {states.map((item: any) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </FieldSelect>
            </div>
            <div className='row mb-7'>
              <FieldSelect className='col-lg-4' isRequired label='City' name='cityId' formik={formik}>
                <option value={0}>Select City</option>
                {cities.map((item: any) => {
                  return (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  )
                })}
              </FieldSelect>
              <TextField className='col-lg-4' label='Zip Code' name='zipCode' formik={formik} />
              <TextField
                className='col-lg-4'
                label='Tour Duration'
                name='tourDuration'
                formik={formik}
              />
            </div>
            <div className='row mb-7'>
              <FieldTextArea
                className='col-lg-6'
                label='Address Line 1'
                name='addressLine1'
                formik={formik}
              />
              <FieldTextArea
                className='col-lg-6'
                label='Address Line 2'
                name='addressLine2'
                formik={formik}
              />
            </div>
            <TourFeatureTypes
              featureTypes={featureTypes}
              formik={formik}
              setFeaturesDescription={setFeaturesDescription}
            />
          </div>
          <div className='d-flex justify-content-end card-footer'>
            <button className='btn btn-sm btn-primary' type='submit'>
              Save
            </button>
          </div>
        </form>
      </div>
    </>
  )
}
// const FeatureType = (props: any) => {
//   const { feature, formik, setFeaturesDescription } = props;
//   const isChecked = formik.values.tourFeatures.some((x: any) => x.id === feature.id);

//   const handleFeatureChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (e.target.checked) {
//       formik.setFieldValue('tourFeatures', [...formik.values.tourFeatures, feature]);
//     } else {
//       formik.setFieldValue(
//         'tourFeatures',
//         formik.values.tourFeatures.filter((item: any) => item.id !== feature.id)
//       );
//     }
//   };

//   const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
//     if (feature.description !== e.target.value) {
//       setFeaturesDescription(feature.id, e.target.value);
//     }
//   };

//   return (
//     <div className='col-lg-6' key={feature.id}>
//       <div
//         className='row form-check form-check-custom form-check-solid p-1'
//         style={{backgroundColor: '#f5f5f5', borderRadius: '5px'}}
//       >
//         <input
//           className='col-lg-4 form-check-input text-bg-info'
//           type='checkbox'
//           id={feature.id}
//           name={feature.id}
//           defaultChecked={isChecked}
//           value={feature.id}
//           onChange={handleFeatureChange}
//         />
//         <label className='col-lg-8 form-check-label' htmlFor={feature.id}>
//           {feature.name}
//         </label>
//       </div>
//       {isChecked && (
//         <input
//           type='text'
//           className='form-control  mb-3 mb-lg-0'
//           placeholder='Enter Description'
//           value={feature.description}
//           onChange={handleDescriptionChange}
//         />
//       )}
//     </div>
//   );
// };

// const TourFeatureTypes = (props: any) => {
//   const { featureTypes, formik, setFeaturesDescription } = props;

//   return (
//     <div className='card-footer border-top pt-6'>
//       <div className='row fv-row mb-10'>
//         <label className='form-label fw-bolder text-dark fs-6'>Choose Features :</label>
//         {featureTypes.map((feature: any) => (
//           <FeatureType
//             key={feature.id}
//             feature={feature}
//             formik={formik}
//             setFeaturesDescription={setFeaturesDescription}
//           />
//         ))}
//         {formik.values.tourFeatures.length > 0 && (
//           <div className='col-lg-12'>
//             <div className='card' style={{backgroundColor: '#f5f5f5', borderRadius: '5px'}}>
//               <div className='card-body'>
//                 <div className='row align-content-between'>
//                   {formik.values.tourFeatures.map((item: any, index: number) => (
//                     <div className='col-md-4' key={index}>
//                       <span className='font-weight-bold'>Feature Name : </span>
//                       <span className='font-weight-bold text-primary'>{item.name}</span>
//                     </div>
//                   ))}
//                 </div>
//               </div>
//             </div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// interface FeatureProps {
//   featureTypes: any
//   formik: any
//   setFeaturesDescription: any
// }
// export const TourFeatureTypes = (props: FeatureProps) => {
//   const { featureTypes, formik, setFeaturesDescription } = props;

//   return (
//     <div className='card-footer border-top pt-6'>
//       <div className='row fv-row mb-10'>
//         <label className='form-label fw-bolder text-dark fs-6'>Choose Features :</label>
//         {featureTypes.map((feature: any, index: number) => {
//           let isChecked = false;
//           if (formik.values.tourFeatures) {
//           for (let i = 0; i < formik.values.tourFeatures.length; i++) {
//             if (formik.values.tourFeatures[i].id === feature.id) {
//               isChecked = true;
//               break;
//             }
//           }
//         }
//           return (
//             <div className='col-lg-6' key={index}>
//               <div
//                 className='row form-check form-check-custom form-check-solid p-1'
//                 style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}
//                 key={index}
//               >
//                 <input
//                   className='col-lg-4 form-check-input text-bg-info'
//                   type='checkbox'
//                   id={feature.id}
//                   name={feature.id}
//                   defaultChecked={isChecked}
//                   value={feature.id}
//                   onChange={(e) => {
//                     if (e.target.checked) {
//                       formik.setFieldValue('tourFeatures', [...formik.values.tourFeatures, feature]);
//                     } else {
//                       formik.setFieldValue(
//                         'tourFeatures',
//                         formik.values.tourFeatures.filter((item: any) => item.id !== feature.id)
//                       );
//                     }
//                   }}
//                 />
//                 <label className='col-lg-8 form-check-label'>
//                   {feature.name}
//                 </label>
//               </div>
//               {isChecked && (
//                 <input
//                   type='text'
//                   className='form-control  mb-3 mb-lg-0'
//                   placeholder='Enter Description'
//                   value={feature.description}
//                   onChange={(e) => {
//                     if (feature.description !== e.target.value) {
//                       setFeaturesDescription(feature.id, e);
//                     }
//                   }}
//                 />
//               )}
//               {formik.touched.tourFeatures && formik.errors.tourFeatures && (
//                 <div className='fv-plugins-message-container'>
//                   <div className='fv-help-block'></div>
//                 </div>
//               )}
//             </div>
//           );
//         })}
//       </div>
//     </div>
//   );
// };
interface FeatureProps {
  featureTypes: any[]
  formik: any
  setFeaturesDescription: (featureId: number, description: string) => void
}

export const TourFeatureTypes = ({ featureTypes, formik, setFeaturesDescription }: FeatureProps) => {
  return (
    <div className='card-footer border-top pt-6'>
      <div className='row fv-row mb-10'>
        <label className='form-label fw-bolder text-dark fs-6'>Choose Features :</label>
        {featureTypes.map((feature: any, index: number) => {
          const isSelected = formik.values.tourFeatures?.some((item: any) => item.id === feature.id)
          const selectedFeature = formik.values.tourFeatures.find(
            (item: any) => item.id === feature.id
          ) || { description: '' }
          return (
            <div className='col-lg-6' key={index}>
              <div
                className='row form-check form-check-custom form-check-solid p-1'
                style={{ backgroundColor: '#f5f5f5', borderRadius: '5px' }}
              >
                <div className='col-lg-4'>
                  <input
                    className='form-check-input text-bg-info'
                    type='checkbox'
                    name={`tourFeatures[${feature.id}]`}
                    checked={isSelected}
                    onChange={(e) => {
                      if (e.target.checked) {
                        formik.setFieldValue('tourFeatures', [
                          ...formik.values.tourFeatures,
                          feature,
                        ])
                      } else {
                        formik.setFieldValue(
                          'tourFeatures',
                          formik.values.tourFeatures.filter((item: any) => item.id !== feature.id)
                        )
                      }
                    }}
                  />
                </div>
                <label className='col-lg-8 form-check-label' htmlFor={feature.id}>
                  {feature.name}
                </label>
              </div>
              {isSelected && (
                <input
                  type='text'
                  className='form-control my-3'
                  placeholder='Enter Description'
                  value={selectedFeature.description}
                  onChange={(e) => {
                    setFeaturesDescription(feature.id, e.target.value)
                  }}
                />
              )}
              {formik.touched.tourFeatures && formik.errors.tourFeatures && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'></div>
                </div>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}
