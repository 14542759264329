import { toAbsoluteUrl } from "../../_metronic/helpers"




type Props = {
    className: string
    chartHeight: string
    backGroundColor: string
    counters: any
    type: string
}

const DashCounterWidget: React.FC<Props> = ({ className, backGroundColor, chartHeight, counters, type }) => {
    // convert to array
    const countersArray = Object.entries(counters);
    var array = [];
    for (var i = 0; i < countersArray.length; i++) {
        // replace Default keyname with the Custom keyname
        if (countersArray[i][0] === 'generalCount') {
            countersArray[i][0] = 'General';
        }
        if (countersArray[i][0] === 'proofReaderCount') {
            countersArray[i][0] = 'Proof Reader';
        }
        if (countersArray[i][0] === 'reviewerCount') {
            countersArray[i][0] = 'Reviewer';
        }
        if (countersArray[i][0] === 'touristCount') {
            countersArray[i][0] = 'Tourist';
        }
        if (countersArray[i][0] === 'authorCount') {
            countersArray[i][0] = 'Author';
        }
        array.push(countersArray[i]);
    }

    // border radius bootstrap
    // pass 5 random bg colors
    const randomColors = [
        "#F7D9E3",
        "#CBF0F4",
        "#CBD4F4",
        "#F7D9E3",
        "#CBF0F4",
    ]
    return (
        <>

            {array.map((counter: any, index: number) => {
                return (<div className={`card ${className} theme-dark-bg-body`}
                    key={index} >
                    <div className='card-body d-flex flex-column rounded' style={{
                        backgroundColor: index === 1 ? randomColors[0] : index === 2 ? randomColors[1] : index === 3 ? 
                        randomColors[2] : index === 4 ? randomColors[3] : randomColors[4], 
                    }}>
                        <div className='d-flex align-items-center mb-7'>
                            {/* <span className='svg-icon svg-icon-3x svg-icon-primary me-5'>
                                <img src={toAbsoluteUrl(`/media/svg/icons/Communication/Group.svg`)} alt='' />
                            </span> */}
                            <div className='d-flex flex-column'>
                                <span className='text-dark fw-bolder fs-3'>{counter[1]}</span>
                                <span className='text-muted fw-bold mt-2'>{counter[0]}</span>
                            </div>
                        </div>

                    </div>
                </div>)
            })}
        </>
    )
}
export { DashCounterWidget };
