import {Fragment} from 'react'
import AddCities from './AddCity'
import AddStates from './AddStates'
import AddCountry from './AddCountry'

export default function Settings() {
  return (
    <Fragment>
      <AddCountry />
      <br />
      <AddStates />
      <br />
      <AddCities />

    </Fragment>
  )
}
