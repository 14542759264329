import { Link } from "react-router-dom";

export const TableHead = (props: any) => {
    const { columns } = props;
    return (
        <thead>
            <tr className='table-info border-1 rounded-5'>
                {columns.map((column: any, index: number) => (
                    <th className={column.accessor === 'name' ? 'text-center sticky-header text-info font-weight-bolder' :
                        'text-center'}
                        key={index}>
                        {column.Header}
                    </th>
                ))}
            </tr>
        </thead>
    )
}
export const TableBody = (props: any) => {
    const { data, columns } = props;
    const randomColor = () => {
        const random = Math.floor(Math.random() * 16777215).toString(16);
        return `#${random}`;
    }
    return (
        <tbody>
            {data.map((row: any, index: number) => {
                return (
                    <tr key={index}>
                        {columns.map((column: any) => {
                            const value = row[column.accessor];
                            return (
                                <td className={column.accessor === 'name' ? "text-center sticky-header text-bg-secondary text-info font-weight-bolder" : "text-center"}
                                    key={column.accessor}>
                                    <div className={column.accessor === "name" ? "text-dark fw-bold text-hover-primary mb-1 fs-6" : "text-center"}>
                                        {column.accessor === "name" ? <Link to={`/users/edit/${row.userId}`}>{value.toUpperCase()}</Link>
                                            : column.accessor === "isApproved" ? value ?
                                                <span className="badge badge-success">Approved</span> :
                                                <span className="badge badge-danger">Not Approved</span> :
                                                column.accessor === "roleNames" ?
                                                    // List of strings  
                                                    value.map((role: string, index: number) => {
                                                        return (
                                                            <span key={index} className="badge badge-light-primary me-1">
                                                                {role}
                                                            </span>
                                                        )
                                                    })
                                                    :
                                                    column.format && typeof value === 'number' ? column.format(value) : value}
                                    </div>
                                </td>
                            );
                        })}
                    </tr>
                );
            })}
        </tbody>
    )
}