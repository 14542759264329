import axiosInstance from '../services/AxiosInstance'

export const getAllTours = (values) => {
  const {
    tourLocationId,
    tourId,
    cityId,
    stateId,
    countryId,
    pageSize,
    pageNumber,
    filter,
    isFeatured,
  } = values
  return axiosInstance.get(
    `/tour/to?tourLocationId=${tourLocationId}&tourId=${tourId}&cityId=${cityId}&stateId=${stateId}&countryId=${countryId}&pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${filter}&isFeatured=${isFeatured}`
  )
}
export const getToursByUser = (values) => {
  const {
    tourLocationId,
    tourId,
    cityId,
    stateId,
    countryId,
    pageSize,
    pageNumber,
    filter,
    isFeatured,
  } = values
  return axiosInstance.get(
    `/tour/user?tourLocationId=${tourLocationId}&tourId=${tourId}&cityId=${cityId}&stateId=${stateId}&countryId=${countryId}&pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${filter}&isFeatured=${isFeatured}`
  )
}

export const getTourById = (tourId) => {
  return axiosInstance.get(`/tour/${tourId}`)
}

export const addTour = (tour) => {
  return axiosInstance.post(`/tour`, tour)
}
export const updateTour = (tour, id) => {
  return axiosInstance.put(`/tour/update/${id}`, tour)
}
export const addCordinates = (data) => {
  return axiosInstance.post('tour/map', data)
}
export const getCordinates = (id) => {
  return axiosInstance.get(`tour/map/${id}`)
}
export const updateDetail = (data) => {
  return axiosInstance.put(`tour/details-update`, data)
}
export const getDetail = (id) => {
  return axiosInstance.get(`tour/details/${id}`)
}
export const getToursForLocation = (values) => {
  const {cityId, stateId, countryId, pageSize, pageNumber, filter, isFeatured, locationId} = values
  return axiosInstance.get(
    `/tour/tour-for-location?cityId=${cityId}&stateId=${stateId}&countryId=${countryId}&pageSize=${pageSize}&pageNumber=${pageNumber}&filter=${filter}&isFeatured=${isFeatured}&locationId=${locationId}`
  )
}
export const assignTourToLocation = (values) => {
  const {tourId, locationId, index} = values
  return axiosInstance.get(
    `/tour/assign-location?locationId=${locationId}&tourId=${tourId}&index=${index}`
  )
}
export const removeTourFromLocation = (values) => {
  const {tourId, locationId} = values
  return axiosInstance.put(
    `/tour/remove-tour?locationId=${locationId}&tourId=${tourId}`
  )
}
