import axios from 'axios';
import { Field, FieldArray } from 'formik';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { currentUserId } from '../../../../services/AuthServics';
import { getAllTourStatus } from '../../../../services/CommonService';
import { getJourneyByTourId } from '../../../../services/TourServices/JourneyService';
import { getAssignees } from '../../../../services/UsersService/UsersService';
import { LOADINGSTAGES } from '../../../../_shared/components/_laodSetups/LoadingSteps';
export interface IPagedData<T> {
    data: T;
    page: {
      size: number;
      totalElements: number;
      totalPages: number;
      pageNumber: number;
    };
  }
export interface ITourPage {
    tours: ITourList[];
    totalPages: number;
}
export interface ITourList {
    id: number;
    name: string;
    locationName: string;
    tourLocationId: number;
    zipCode: string;
    lat: number;
    lng: number;
    locationId: number;
    isActive: boolean;
    audioId: number;
    statusId: number;
    isFeatured: boolean;
    cityId: number;
    stateId: number;
    countryId: number;
    cityName: string;
    stateName: string;
    countryName: string;
    totalCount: number;
    pageNumber: number;
    pageSize: number;
    totalPages: number;
    proofReaderName: string;
    reviewerName: string;
    authorName: string;
    adminName: string;
}
export const TourListColumnName = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'locationName', label: 'Location Name', minWidth: 170 },
    { id: 'statusId', label: 'Status', minWidth: 170 },
    { id: 'zipCode', label: 'Zip Code', minWidth: 170 },
    { id: 'cityName', label: 'City Name', minWidth: 170 },
    { id: 'stateName', label: 'State Name', minWidth: 170 },
    { id: 'countryName', label: 'Country Name', minWidth: 170 },
    { id: 'proofReaderName', label: 'Proof Reader', minWidth: 170 },
    { id: 'reviewerName', label: 'Reviewer', minWidth: 170 },
    { id: 'authorName', label: 'Author', minWidth: 170 },
    { id: 'adminName', label: 'Admin', minWidth: 170 },
]

export const TourStatus = [
    { id: 1, name: 'Open', className: 'badge badge-info font-weight-bolder' },
    { id: 2, name: 'Pending With PR', className: 'badge badge-info font-weight-bolder' },
    { id: 3, name: 'Rejected By PR', className: 'badge badge-danger font-weight-bolder' },
    { id: 4, name: 'Pending With Reviewer', className: 'badge badge-info font-weight-bolder' },
    { id: 5, name: 'Rejected By Reviewer', className: 'badge badge-danger font-weight-bolder' },
    { id: 6, name: 'Approved', className: 'badge badge-success font-weight-bolder' },
    { id: 7, name: 'Closed', className: 'badge badge-dark font-weight-bolder' },
    { id: 8, name: 'Accepted By PR', className: 'badge badge-info font-weight-bolder' },
    { id: 9, name: 'Accepted By Reviewer', className: 'badge badge-info font-weight-bolder' },
    { id: 10, name: 'Rejected By Admin', className: 'badge badge-danger font-weight-bolder' },
]

export interface ItourDto {
    tourLanguageId : number;
    id: number;
    name: string;
    tourTypeId: number;
    cityId : number;
    stateId :number;
    countryId : number;
    tourLocationId: number;
    coverImageId: number;
    shortDescription: string;
    longDescription: string;
    discriptionMeta: string;
    mediumDescription: string;
    addressLine1: string;
    addressLine2: string;
    zipCode: string;
    landmark: string;
    lat: number;
    lng: number;
    locationId: number;
    isActive: boolean;
    isDeleted: boolean;
    audioId: number;
    statusId: number;
    isFeatured: boolean;
    alertRadius: number;
    tourFeatures: [
        {
            id: number;
            name: string;
            description: string;
        }
    ];
}
export const tourInitialValues: ItourDto = {
    id: 0,
    name: "",
    tourTypeId: 0,
    tourLocationId: 0,
    coverImageId: 0,
    shortDescription: "",
    mediumDescription: "",
    longDescription: "",
    discriptionMeta: "",
    addressLine1: "",
    addressLine2: "",
    zipCode: "",
    landmark: "",
    lat: 0,
    lng: 0,
    locationId: 0,
    isActive: false,
    isDeleted: false,
    audioId: 0,
    statusId: 0,
    isFeatured: false,
    alertRadius: 0,
    tourFeatures: [
        {
            id: 0,
            name: "",
            description: "",
        }
    ],
    tourLanguageId: 0,
    cityId: 0,
    stateId: 0,
    countryId: 0
}
export const AddTourFieldArray = (props: any) => {
    const { formik, features } = props;

    return (
        <div>
            {features && features.length > 0 ? (
                features.map((item: any, index: number) => (
                    <div key={index}>
                        <Field name={`features.${index}.name`} />
                        <button type="button" onClick={() => formik.values.features.splice(index, 1)}>
                            -
                        </button>
                        <button type="button" onClick={() => formik.values.features.push({ id: 0, name: "" })}>
                            +
                        </button>
                    </div>
                ))
            ) : (
                <button type="button" onClick={() => formik.values.features.push({ id: 0, name: "" })}>
                    {/* show this when user has removed all friends from the list */}
                    Add a Type
                </button>
            )}
        </div>
    );
};

export const useJourneyInfo = (id: any) => {
    const [journeyInfo, setJourneyInfo] = useState<any>([]);
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.LOADING);
    useEffect(() => {
        getJourneyByTourId(id)
            .then((res: any) => {
                if (res.data.status) {
                    setLoadingStages(LOADINGSTAGES.POSITIVE);
                    setJourneyInfo(res.data.data);
                }
                else {
                    setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    setJourneyInfo([]);
                }
            }).catch((err: any) => {
                setLoadingStages(LOADINGSTAGES.ERROR);
                setJourneyInfo([]);
            })
    }, [id]);
    return { journeyInfo, loadingStages };
};
export const useTourStatus = () => {
    const [tourStatus, setTourStatus] = useState<any>([]);
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.LOADING);
    useEffect(() => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        getAllTourStatus()
            .then((res: any) => {
                if (res.data.status) {
                    setLoadingStages(LOADINGSTAGES.POSITIVE);
                    setTourStatus(res.data.data);
                }
                else {
                    setLoadingStages(LOADINGSTAGES.NEGATIVE);
                    setTourStatus([]);
                }
            }).catch((err: any) => {
                setLoadingStages(LOADINGSTAGES.ERROR);
                setTourStatus([]);
            })
    }, []);
    return { tourStatus, loadingStages };
}
export const useAssignees = (role: string,roleIds:any) => {
    const [assignees, setAssignees] = useState<any>([]);
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.LOADING);
    useEffect(() => {
        setLoadingStages(LOADINGSTAGES.LOADING);
        if (role) {
            getAssignees(role,roleIds)
                .then((res: any) => {
                    if (res.data.status) {
                        setLoadingStages(LOADINGSTAGES.POSITIVE);
                        setAssignees(res.data.data);
                    }
                    else {
                        setLoadingStages(LOADINGSTAGES.NEGATIVE);
                        setAssignees([]);
                    }
                }).catch((err: any) => {
                    setLoadingStages(LOADINGSTAGES.ERROR);
                    setAssignees([]);
                })
        }
    }, [role]);
    return { assignees, loadingStages };
}
export const ListTourByUserColumn = [
    { id: 'name', label: 'Name', minWidth: 170 },
    { id: 'locationName', label: 'Location Name', minWidth: 170 },
    { id: 'statusId', label: 'Status', minWidth: 170 },
    { id: 'zipCode', label: 'Zip Code', minWidth: 170 },
    { id: 'cityName', label: 'City Name', minWidth: 170 },
    { id: 'stateName', label: 'State Name', minWidth: 170 },
    { id: 'countryName', label: 'Country Name', minWidth: 170 },
    { id: 'createdBy', label: 'Created By', minWidth: 170 },
    { id: 'roleName', label: 'Role', minWidth: 170 },
]
export const currentStage = (props:any) => {
    const {roles,statusId,userId,createdBy,tourId} = props;
    // 1. if roles includes admin and statusId is 1 then 
    // 1.1 Admin can Abandon the tour with statusId 7
    // 1.2 Admin can Pass the tour to proofreader with statusId 2
    // 1.3 Admin can Pass the tour to Reviewer with statusId 4 
    // 2 if roles includes proofreader and statusId is 2 then
    // 2.1 Proofreader can Reject the tour with statusId 3
    // 2.2 Proofreader can Accept the tour with statusId 8 and will be passed again to admin with statusId 8
    // 3 if roles includes Admin and statusId is 8 then
    // 3.1 Admin will pass the tour to Reviewer with statusId 4 
    // 4 if roles includes Reviewer and statusId is 4 then
    // 4.1 Reviewer can Reject the tour with statusId 5
    // 4.2 Reviewer can Accept the tour with statusId 9
    // 5 if roles includes Admin and statusId is 9 then
    // 5.1 Admin will Approve the tour with statusId 6.
    // 5.2 Admin will Reject the tour with statusId 10.
    const returnObj:any = {
        tourId : props.tourId,
        statusId : props.statusId,
        createdBy : props.createdBy,
        userId : props.userId,
    };
    const returnAction = {
        accept : false,
        reject : false,
    }
    const myUserId = currentUserId();
    if(roles.includes('Admin') && statusId === 1 && userId === myUserId){
        returnObj.statusId = 7;
        returnObj.action = 'abandon';
        return returnObj;
    }
    
}
 