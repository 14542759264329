import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastNotify } from "../../../../services/NotifyService";
import { uploadAudio } from "../../../../services/TourServices/TourAudioService";
import { getAllLanguages } from "../../../../services/TourServices/TourLanguageService";
import { PageTitle } from "../../../../_metronic/layout/core";
import { LOADINGSTAGES } from "../../../../_shared/components/_laodSetups/LoadingSteps";
import { FieldSelect, TextField } from "../../components/fields/TextField";
import * as Yup from "yup";

export function TourAudioAdd() {
    const { id } = useParams();
    const [tourId, setTourId] = useState<string>(id as string);
    const [languages, setLanguages] = useState<any[]>([]);
    const [loadingStages, setLoadingStages] = useState(LOADINGSTAGES.LOADING);
    const [audioType, setAudioType] = useState<string>("");
    const [audioFileSize, setAudioFileSize] = useState<string>("");
    const audiotypes = [
        "short", "medium", "long"
    ]
    const Schema = Yup.object().shape({
        tourId: Yup.string().required("Tour Id is required"),
        name : Yup.string().required("Name is required"),
        tourLanguageId: Yup.string().required("Tour Language is required"),
        audioType: Yup.string().required("Audio Type is required"),
        file: Yup.mixed().required("Audio File is required").test("fileSize", "File too large", (value) => {
            if (value) {
                if (value.size > 10000000) {
                    setAudioFileSize("File size should be less than 10 MB");
                    return false;
                }
                else {
                    setAudioFileSize("");
                    return true;
                }
            }
            else {
                return false;
            }
        }
        ),
    });
    useEffect(() => {
        setTourId(id as string);
        bindLanguages();
    }, [id]);
    const bindLanguages = () => {
        getAllLanguages().then((res: any) => {
            if (res.data.status) {
                setLoadingStages(LOADINGSTAGES.POSITIVE);
                setLanguages(res.data.data);
            }
            else {
                setLoadingStages(LOADINGSTAGES.NEGATIVE);
                setLanguages([]);
            }
        }).catch((err: any) => {
            setLoadingStages(LOADINGSTAGES.ERROR);
            setLanguages([]);
        });
    }
    // We will add audio to this tour with id = tourId
    const formik = useFormik({
        initialValues: {
            tourId: tourId,
            tourLanguageId: "",
            audioType: "",
            file: null,
            name: "",

        },
        validationSchema: Schema,
        enableReinitialize: true,
        onSubmit: (values) => {
        saveAudio(values);
        },
    });
    const saveAudio = (values: any) => {
        const formData = new FormData();
        formData.append("tourId", values.tourId);
        formData.append("tourLanguageId", values.tourLanguageId);
        formData.append("audioType", values.audioType);
        formData.append("file", values.file);
        formData.append("name", values.name);

        const tId = toast.loading("Uploading Audio...");
        uploadAudio(formData).then((res: any) => {
            if (res.data.status) { toastNotify(tId, res); }
            else {
                const res = { status: false, data: { message: "Audio Upload Failed" } };
                toastNotify(tId, res);
            }
        }).
            catch((err: any) => {
                const res = { status: false, data: { message: "Audio Upload Failed" } };
                toastNotify(tId, res);
            }).finally(() => {
                formik.resetForm();
                toast.dismiss(tId);
            });
    }
    return (
        <div>
            <PageTitle>
                Add Tour Audio
            </PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_kitty_group_details'
                    aria-expanded='true'
                    aria-controls='kt_kitty_group_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Add</h3>
                    </div>
                </div>
                <div id='kt_kitty_group_details' className='collapse show'>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                        <div className="card">
                            <div className='card-body border-top p-9'>
                                <div className='row mb-7'>
                                    <FieldSelect
                                        className="col-lg-6"
                                        isRequired
                                        label="Tour Language"
                                        name="tourLanguageId"
                                        formik={formik}
                                    >
                                        <option value="">Select Language </option>
                                        {languages.map((item: any) => {
                                            return <option key={item.id} value={item.id}>{item.name}</option>
                                        })}
                                    </FieldSelect>
                                    <FieldSelect className='col-lg-6' isRequired
                                        label='Audio Type' name='audioType' formik={formik}
                                    >
                                        <option value=''>Select Audio</option>
                                        {audiotypes.map((item: any) => {
                                            return <option key={item} value={item}>{item}</option>
                                        })}
                                    </FieldSelect>
                                </div>
                            </div>
                            <div className="row mb-7">
                                <div className="col-6">
                                    <input type="file"
                                        className="form-control"
                                        name="file"
                                        onChange={(e: any) => { formik.setFieldValue("file", e.target.files[0]); }}
                                    />
                                </div>
                                <TextField className="col-6" isRequired
                                    label="Name" name="name" formik={formik}
                                />
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary' data-kt-users-modal-action='submit'>
                                <span className='indicator-label'>Submit</span>
                                <span className='indicator-progress'>Please wait...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span></span>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}