/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  FieldSelect,
  FieldTextArea,
  TextField,
} from '../../../../../src/app/pages/components/fields/TextField'
import clsx from 'clsx'
import { register } from '../core/_requests'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { toastNotify } from '../../../../services/NotifyService'
import { getRequestRoles } from '../../../../services/AuthServics'
import { IRoleModel } from '../../../../_models/IUserModel'
import { getLocationDetails, getTourLocations } from '../../../../services/TourLocationService'
import {
  getAllCountries,
  getCitiesByState,
  getStatesByCountry as getStates,
} from '../../../../services/CommonService'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
const initialValues = {
  firstName: '',
  lastName: '',
  email: '',
  stateId: 0,
  cityId: 0,
  countryId: 0,
  password: '',
  phone: '',
  changepassword: '',
  acceptTerms: false,
  roles: [],
}

const registrationSchema = Yup.object().shape({
  cityId: Yup.number().required('City is required').notOneOf([0], 'Select City'),
  stateId: Yup.number().required('State is required').notOneOf([0], 'Select State'),
  countryId: Yup.number().required('Country is required').notOneOf([0], 'Select Country'),

  firstName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  phone: Yup.string()
    .matches(/^[0-9]+$/, 'Must be only digits')
    .min(10, 'Minimum 10 symbols')
    .max(10, 'Maximum 10 symbols')
    .required('Phone is required'),
  lastName: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changepassword: Yup.string()
    .required('Password confirmation is required')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Password and Confirm Password didn't match"),
    }),
  acceptTerms: Yup.bool(),
  roles: Yup.array()
    .required('You must select at least one role')
    .min(1, 'You must select at least one role'),
})

export function Registration() {
  const navigate = useNavigate()
  const { email } = useParams()

  const [loading, setLoading] = useState(false)
  const [initialLoading, setInitialLoading] = useState(true)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [roles, setRoles] = useState<IRoleModel[]>([])
  const bindRolesRequest = () => {
    getRequestRoles()
      .then((res) => {
        setInitialLoading(true)
        setRoles(res.data.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setInitialLoading(false)
      })
  }

  const [countries, setCountries] = useState<any>([])
  const bindCountries = () => {
    getAllCountries()
      .then((res: any) => {
        if (res.data.status) {
          let data = res.data.data
          // set default value
          data.unshift({ id: 0, name: 'Select Country' })
          setCountries(data)
          console.log(data)
        } else {
          setCountries([])
        }
      })
      .catch(() => {
        setCountries([])
      })
  }
  const [states, setStates] = useState<any>([])
  const bindStates = (countryId: any) => {
    getStates(countryId)
      .then((res: any) => {
        const array = res.data.data
        array.unshift({ id: 0, name: 'Select State' })

        setStates(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }
  const [cities, setCities] = useState<any>([])
  const bindCities = (stateId: any) => {
    getCitiesByState(stateId)
      .then((res: any) => {
        const array = res.data.data
        array.unshift({ id: 0, name: 'Select City' })

        setCities(array)
      })
      .catch((err: any) => {
        console.log(err)
      })
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)

      const id = toast.loading('Please wait...')

      try {
        await register(
          values.firstName,
          values.lastName,
          values.password,
          values.email,
          values.roles,
          values.phone,
          values.stateId,
          values.cityId,
          values.countryId
        )
          .then((res: any) => {
            let _data = res.data.data
            toastNotify(id, res)

            if (_data) {
              setFormSubmitted(true)
              //navigate('/auth');
            }
          })
          .catch((err: any) => {
            const res = { data: { message: 'Something went wrong', success: false } }
            toastNotify(id, res)
            console.error(err)
            setLoading(false)
          })
          .finally(() => {
            setLoading(false)
            toast.dismiss(id)
          })
      } catch (error) {
        setStatus('Something went wrong. Please retry or contact admin.')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    setStates([])
    setCities([])
    if (formik.values.countryId != 0) {
      bindStates(formik.values.countryId)
    }
  }, [formik.values.countryId])
  useEffect(() => {
    setCities([])
    // if page is edit page then dont set cityId to 0

    if (formik.values.stateId != 0) {
      bindCities(formik.values.stateId)
    }
  }, [formik.values.stateId])

  useEffect(() => {
    bindCountries()
    bindRolesRequest()
    PasswordMeterComponent.bootstrap()
    if (email) {
      formik.setFieldValue('email', email)
    }
  }, [])

  return (
    <>
      {formSubmitted ? (
        <div className='w-100'>
          {/* begin::Alert*/}
          <div
            className='alert alert-dismissible d-flex flex-center flex-column py-10 px-10 px-lg-20'
            style={{ margin: '-33px' }}
          >
            {/* begin::Close*/}
            {/* <button type="button" className="position-absolute top-0 end-0 m-2 btn btn-icon btn-icon-success" data-bs-dismiss="alert">
              <img src="/media/icons/duotune/abstract/abs012.svg" className="h-20px me-10" />
            </button> */}
            {/* end::Close*/}

            {/* begin::Icon*/}
            <span className='svg-icon svg-icon-5hx svg-icon-success me-4 mb-5'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  opacity='0.3'
                  d='M20.5543 4.37824L12.1798 2.02473C12.0626 1.99176 11.9376 1.99176 11.8203 2.02473L3.44572 4.37824C3.18118 4.45258 3 4.6807 3 4.93945V13.569C3 14.6914 3.48509 15.8404 4.4417 16.984C5.17231 17.8575 6.18314 18.7345 7.446 19.5909C9.56752 21.0295 11.6566 21.912 11.7445 21.9488C11.8258 21.9829 11.9129 22 12.0001 22C12.0872 22 12.1744 21.983 12.2557 21.9488C12.3435 21.912 14.4326 21.0295 16.5541 19.5909C17.8169 18.7345 18.8277 17.8575 19.5584 16.984C20.515 15.8404 21 14.6914 21 13.569V4.93945C21 4.6807 20.8189 4.45258 20.5543 4.37824Z'
                  fill='currentColor'
                ></path>
                <path
                  d='M10.5606 11.3042L9.57283 10.3018C9.28174 10.0065 8.80522 10.0065 8.51412 10.3018C8.22897 10.5912 8.22897 11.0559 8.51412 11.3452L10.4182 13.2773C10.8099 13.6747 11.451 13.6747 11.8427 13.2773L15.4859 9.58051C15.771 9.29117 15.771 8.82648 15.4859 8.53714C15.1948 8.24176 14.7183 8.24176 14.4272 8.53714L11.7002 11.3042C11.3869 11.6221 10.874 11.6221 10.5606 11.3042Z'
                  fill='currentColor'
                ></path>
              </svg>
            </span>
            {/* end::Icon*/}

            {/* begin::Wrapper*/}
            <div className='text-center'>
              {/* begin::Title*/}
              <h1 className='fw-bold mb-5'>Thank you for signing up!</h1>
              {/* end::Title*/}

              {/* begin::Separator*/}
              <div className='separator separator-dashed border-success opacity-25 mb-5'></div>
              {/* end::Separator*/}

              {/* begin::Content*/}
              <div className='mb-9 text-dark fs-5'>
                To complete your sign up process, please check your email for the{' '}
                <strong>confirmational email</strong> that we just sent.
                <br />
                <br />
                If you do not receive your confirmation email in next two hours, please{' '}
                <a href='#' className='fw-bold me-1'>
                  contact us
                </a>
                .
              </div>
              {/* end::Content*/}

              {/* begin::Buttons*/}
              <div className='d-flex flex-center flex-wrap'>
                {/* <a href="#" className="btn btn-outline btn-outline-success btn-active-success m-2">Cancel</a> */}
                <Link to='/auth' className='btn btn-success m-2'>
                  Ok, I got it
                </Link>
              </div>
              {/* end::Buttons*/}
            </div>
            {/* end::Wrapper*/}
          </div>
          {/* end::Alert*/}
        </div>
      ) : (
        <form
          // change size of form
          className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='mb-10 text-center'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Create an Account</h1>
            {/* end::Title */}

            {/* begin::Link */}
            <div className='text-gray-400  fs-4'>
              Already have an account?
              <Link to='/auth/forgot-password' className='link-primary ' style={{ marginLeft: '5px' }}>
                Forgot Password ?
              </Link>
            </div>
            {/* end::Link */}
          </div>
          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Firstname */}
          <div className='row fv-row mb-7'>
            <div className='col-xl-6'>
              <label className='className="form-label fw-bolder text-dark fs-6'>First name</label>
              <input
                placeholder='First name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstName')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.firstName && formik.errors.firstName,
                  },
                  {
                    'is-valid': formik.touched.firstName && !formik.errors.firstName,
                  }
                )}
              />
              {formik.touched.firstName && formik.errors.firstName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstName}</span>
                  </div>
                </div>
              )}
            </div>

            <div className='col-xl-6'>
            <label className='className="form-label fw-bolder text-dark fs-6'>Last name</label>
              <input
                placeholder='Last name'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('lastName')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.lastName && formik.errors.lastName,
                  },
                  {
                    'is-valid': formik.touched.lastName && !formik.errors.lastName,
                  }
                )}
              />
              {formik.touched.lastName && formik.errors.lastName && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.lastName}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}
          <div className='row fv-row mb-7'>
            {/* begin::Form group Email */}
            <div className='col-xl-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Phone Number</label>
              <input
                placeholder='Phone Number'
                type='numeric'
                autoComplete='off'
                {...formik.getFieldProps('phone')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  { 'is-invalid': formik.touched.phone && formik.errors.phone },
                  {
                    'is-valid': formik.touched.phone && !formik.errors.phone,
                  }
                )}
              />
              {formik.touched.phone && formik.errors.phone && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.phone}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}
          </div>

          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Email</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}

          {/* begin::Form group Password */}
          <div className='row fv-row mb-7'>
            <div className='col-lg-6' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Password</label>
                <div className='position-relative mb-3'>
                  <input
                    type='password'
                    placeholder='Password'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* begin::Meter */}
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
              </div>
              <div className='text-muted'>
                Use 8 or more characters with a mix of letters, numbers & symbols.
              </div>
            </div>
            <div className='col-lg-6'>
              <label className='form-label fw-bolder text-dark fs-6'>Confirm Password</label>
              <input
                type='password'
                placeholder='Password confirmation'
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='row mb-7'>
            <FieldSelect
              className='col-lg-12'
              isRequired
              value
              label='Country Name'
              name='countryId'
              formik={formik}
            >
              {countries.map((item: any) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </FieldSelect>
          </div>

          <div className='row mb-7'>
            <FieldSelect
              className='col-lg-12'
              isRequired
              value
              label='State Name'
              name='stateId'
              formik={formik}
            >
              {states.map((item: any) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </FieldSelect>
          </div>
          <div className='row mb-7'>
            <FieldSelect
              className='col-lg-12'
              isRequired
              value
              label='City Name'
              name='cityId'
              formik={formik}
            >
              {cities.map((item: any) => {
                return (
                  <option key={item.id} value={item.id}>
                    {item.name}
                  </option>
                )
              })}
            </FieldSelect>
          </div>
          {!initialLoading && (
            <div className='row fv-row mb-10'>
              <label className='form-label fw-bolder text-dark fs-6'>Choose Role :</label>
              {roles.map((role, index: number) => (
                <div
                  className='col-lg-4 col-md-4 form-check form-check-custom form-check-solid p-1'
                  key={index}
                >
                  <div>
                    <input
                      className='form-check-input bg-primary'
                      type='checkbox'
                      id='roles'
                      {...formik.getFieldProps('roles')}
                      value={role.id}
                    />
                    <label className='form-check-label fw-bold text-gray-700 fs-6' htmlFor='role'>
                      {role.name}
                    </label>
                  </div>
                </div>
              ))}
              {formik.touched.roles && formik.errors.roles && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.roles}</span>
                  </div>
                </div>
              )}
            </div>
          )}
          <div className='text-center'>
            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {!loading && <span className='indicator-label'>Submit</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Cancel
              </button>
            </Link>
          </div>
        </form>
      )}
    </>
  )
}
